<template>
    <v-app-bar
      app
      :class="[ nav_drawer ? 'z-index-10' : '', hasPermission('mini_charm') && hasCharmSubscribedPropertySelected ? 'mini-charm-header' : '' ]"
      clipped-left
      color="secondary"
      height="60px"
      >
        <hb-btn
            class="ml-n2 mdi-custom-main-menu-transform-arrow"
            style="margin-top: -3px;"
            v-if="isAdmin"
            icon
            :disabled="nav_drawer"
            color="#ffffff"
            active-state-off
            hover-background-color="#004247"
            :mdi-icon="drawer ? 'mdi-custom-main-menu-arrow' : 'mdi-custom-main-menu'"
            @click="drawerToggle"
        />
        <FacilitySearch />
        <v-spacer v-if="!$vuetify.breakpoint.xs" />
        <v-spacer v-if="!$vuetify.breakpoint.xs" />
        <MiniCharmHeader
            v-if="hasPermission('mini_charm') && hasCharmSubscribedPropertySelected"
            class="d-flex justify-end"
            :class="{'charm-header': $vuetify.breakpoint.lg}"
            :style="$vuetify.breakpoint.xs ? 'margin-left: 10px;' : 'margin-left: 20px;'"
        />

        <!-- <LiveVideoSupport /> -->
        <img v-if="!$vuetify.breakpoint.xs" class="mt-n1 ml-3 mr-sm-1 ti-logo" src="/img/tenantinc-logo-white.png" />
        <!-- hide quick action for mobile becuase insides element not yet responsive -->
        <hb-btn
            v-if="false"
            icon
            :tooltip="!$vuetify.breakpoint.xs ? 'Quick Actions' :''"
            active-state-off
            color="#ffffff"
            hover-background-color="#004247"
            @click="goQuickLinks"
        >
            mdi-flash-circle
        </hb-btn>
        <audio v-if="isAdmin" ref="audio_receive" src="/audio/chat_alert_2.mp3"></audio>
    </v-app-bar>
</template>
<script>
    import FacilitySearch from '../FacilitySearch/index';
    import LiveVideoSupport from '../LiveVideoSupport.vue';
    import NotificationsList from '../Notifications/List.vue';
    import TodoCreate from '../Todo/Create.vue';
    import MiniCharmHeader from './MiniCharmHeader.vue';

    import api from '../../../assets/api.js';
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AppHeader',
        data() {
            return {
                socket: {},
                socketEvents: {
                'connect': () => this.watch(),
                'error': (err) => { console.log('socket error', err); },
                'document_signed': (payload) => { EventBus.$emit('document_signed', payload); },
                'document_downloaded': (payload) => {
                    console.log("document_downloaded");
                    EventBus.$emit('document_downloaded', payload);
                },
                'todo': (payload) => { this.getTodo(); },
                'notification': () => { this.fetchData('socket'); },
                'message_sent_to_contact': () => { EventBus.$emit('message_sent_to_contact'); },
                'confirmation': (message) => { this.$store.dispatch('confirmationStore/setUpConfirmation', message); },
                'callStatusUpdate': (message) => { this.$store.dispatch('callStore/updateCall', message); },
                'brivoConnection': (message) => { EventBus.$emit('brivoConnection'); },
                'paymentReader': (message) => { EventBus.$emit('paymentReader'); },
                'incomingCall': (message) => { this.$store.dispatch('callStore/addCall', message); },
                'incomingText': (message) => {
                        EventBus.$emit('chat_updated', message.sms_thread_id);
                        if(this.$route.name !== 'Chat'){
                            this.$store.dispatch('callStore/addSMS', message);
                            this.$refs.audio_receive.play();
                        }
                    },
                "taskCreated": (payload) => {
                        EventBus.$emit('HB:Navigation-tasks-indicator', payload);
                    }
                },
            }
        },
        mounted() {
            this.setNotifications();
            if(!this.isHBUser) {
                this.drawerToggle()
            }
        },
        async created() {
            EventBus.$on('turn-off-hamburger-drawer', this.drawerToggle);
            await this.getGlobalSettings();
        },
        destroyed() {
            EventBus.$off('turn-off-hamburger-drawer', this.drawerToggle);
        },
        methods: {
            watch(){
                // if(this.userData.id && this.loaded) {
                //     this.socket.emit('watch', this.userData.id);
                // }

            },
            ...mapActions({
                setNotifications:'notificationsStore/setNotifications',
                getTodos: 'todoStore/getTodos',
                getGlobalSettings: 'authenticationStore/getGlobalSettings'
            }),
            setUpWebsockets(){
                // this is not where we set this up
                console.log("process.env.VUE_APP_SOCKET_PROTOCOL", process.env.VUE_APP_SOCKET_PROTOCOL)
                console.log("process.env.VUE_APP_SOCKET_SUBDOMAIN", process.env.VUE_APP_SOCKET_SUBDOMAIN)
                console.log("process.env.VUE_APP_DOMAIN", process.env.VUE_APP_DOMAIN)
                console.log("process.env.VUE_APP_SOCKET_PORT", process.env.VUE_APP_SOCKET_PORT)

                this.socket = io(process.env.VUE_APP_SOCKET_PROTOCOL + '://' + process.env.VUE_APP_SOCKET_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_SOCKET_PORT + '/v1');

                for(const socketEvent in this.socketEvents) {
                    this.socket.on(socketEvent, this.socketEvents[socketEvent]);
                }    
            },
            fetchData(type){
                this.setNotifications();
                if(type == 'initial'){
                    this.loaded = true;
                } if(type == 'socket' && newNotifications) {
                    this.$refs.audio.play();
                }
            },
            drawerToggle() {
                this.$emit('toggle-drawer');
                EventBus.$emit('main-drawer-is-toggled', this.drawer);
            },
            goQuickLinks(){
                var payload = { foo: 'bar' };
                EventBus.$emit('HB-Navigation:QuickLinks', payload);
                if(this.$vuetify.breakpoint.xs) {
                    EventBus.$emit('hideNavigationDrawer', false);
                }
            }
        },
        computed: {
            ...mapGetters({
                userData: 'authenticationStore/getUserData',
                isAdmin: 'authenticationStore/isAdmin',
                isHBUser: 'authenticationStore/isHBUser',
                hasPermission: 'authenticationStore/rolePermission',
                hasCharmSubscribedPropertySelected: 'charmSubscriptionStore/hasCharmSubscribedPropertySelected'
            }),
        },
        components: {
            FacilitySearch,
            LiveVideoSupport,
            NotificationsList,
            TodoCreate,
            MiniCharmHeader
        },
        props: ['drawer','nav_drawer']
    }
</script>
<style lang="scss">
    .header {
        color: #fff;
        position: fixed;
        right:0;
        left:0;
        .hb-menu-icon {
            display: block;
            padding: 13px 10px;
        }

        .hb-quick-actions {
            color: #101318;
            position: fixed;
            top:0;
            left:0;
            bottom:0;
            width: 320px;
            background: rgba(255,255,255,1);
        }
    }
    .main {
        padding-top: 60px;
    }
    .ti-logo {
        width:120px;
    }
    .z-index-10 {
        z-index: 10 !important;
    }

    .facility-search {
        max-width: 800px;
    }

    .charm-header {
        width: 530px;
    }
    .mdi-custom-main-menu-transform-arrow {
        [name*="-mdi-custom-main-menu-arrow"] svg {
            transform: rotate(180deg);
            top: 2px;
            position: relative;
            left: -2px;
        }
    }
    @media (max-width: 600px) {
        .mini-charm-header {
            div#facility-search {
                width: 60%;
                input#faclility-search {
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }  
        }
    }
</style>
