<template>
  <hb-modal v-model="dialog" size="large" :title="title_text ? title_text : 'Create Task'" show-help-link @close="$emit('close')">
    <template v-slot:subheader v-if="!moveout_prepare_space">
      Add something to your todo list. You'll be notified if you haven't completed it by the due date.
    </template>
    <template v-slot:content>
      <div :key="filterChange" class="create-reminder-todo">
        <div class="pa-4" v-if="moveout_prepare_space && contact">
          <HbHeaderInfo
            type="contact"
            :title="computedContactTitle"
            :is-military="contact.Military && contact.Military.active && contact.Military.active == 1"
            :status="contact.status ? contact.status : undefined"
            :phone="computedContactPhone | formatPhone"
            :phone-is-verified="computedContactPhoneVerified"
            :email="contact.email ? contact.email : undefined"
            :email-is-verified="contact.email_verified"
            :contact-is-business="contact.rent_as_business"
            :address="computedContactAddress"
            @emailClicked="sendMessage = true"
          />
          <send-message
            v-model="sendMessage"
            v-if="sendMessage"
            :contact="selectedTenant.Contact"
          ></send-message>
        </div>
        <v-divider v-if="moveout_prepare_space && contact"></v-divider>
        <div>
          <!--
          <status
            class="pa-3"
            @resetStatus="errorClear($options.name)"
            v-if="errorHas($options.name)"
            :message="errorGet($options.name)"
            status="error"
          ></status>
          <status
            class="pa-3"
            @resetStatus="successClear($options.name)"
            v-if="successHas($options.name)"
            :message="successGet($options.name)"
            status="success"
          ></status>
          -->

          <hb-form label="Task Name">
            <HbTextField
              v-model="todo.name"
              v-validate="'max:45'"
              placeholder="Enter Task Name"
              id="task"
              name="task"
              data-vv-scope="todo"
              data-vv-as="Task Name"
              :error="errors.collect('todo.task').length > 0"
            />
          </hb-form>

          <hb-form v-if="false" label="Action">
            <HbSelect
              v-model="todo.action"
              placeholder="Select Action"
              :items="actions"
              :readonly="disableAction"
              :disabled="disableAction"
            />
          </hb-form>

          <hb-form v-if="false" label="Event Type">
            <HbSelect
              v-model="todo.event_type"
              :items="eventsTypes"
              item-text="name"
              item-value="id"
              placeholder="Select Event Type"
              :readonly="true"
              :disabled="true"
            />
          </hb-form>

          <hb-form label="Due by" full required>
            <v-row no-gutters>
                <v-col sm="5" class="ma-0 pa-0">
                  <div :style="allowAddTime ? 'margin-top:-6px;' : 'margin-top:-23px;'">
                    <hb-date-picker
                      label="MM/DD/YYYY"
                      :clearable="true"
                      clear-icon="mdi-close-circle"
                      :flat="!allowAddTime"
                      :solo="false"
                      :single-line="true"
                      data-vv-as="Due by"
                      v-model="todo.date"
                      name="due_date"
                      v-validate="'required'"
                      data-vv-scope="todo"
                      :min="new Date().toISOString()"
                      :todo="allowAddTime"
                      :error="errors.collect('todo.due_date').length > 0"
                    ></hb-date-picker>
                  </div>
                </v-col>

                <v-col sm="7" class="ma-0 py-0 pl-3 pr-0" v-if="!moveout_prepare_space">
                  <a class="ma-0 pa-0" @click="allowAddTime = true" v-if="!allowAddTime">+ Add Time</a>
                  <v-row no-gutters v-else class="mt-1">
                    <v-col md="3" class="ma-0 pa-0">
                      <v-text-field
                        id="todo_hour"
                        name="todo_hour"
                        label="HH"
                        v-model="todo.hh"
                        data-vv-scope="todo"
                        data-vv-as="Hours"
                        type="number"
                        min="1"
                        max="12"
                        width="80%"
                        v-validate="'required|numeric|max_value:12|min_value:0'"
                        hide-details
                        :error="errors.collect('todo.todo_hour').length > 0"
                        class="mt-0 pt-0 time-w-80"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" class="ma-0 pa-0">
                      <v-text-field
                        id="todo_minutes"
                        name="todo_minutes"
                        label="MM"
                        v-model="todo.mm"
                        data-vv-scope="todo"
                        data-vv-as="Minutes"
                        type="number"
                        min="0"
                        max="59"
                        width="80%"
                        v-validate="'required|numeric|max_value:59|min_value:0'"
                        hide-details
                        :error="errors.collect('todo.todo_minutes').length > 0"
                        class="mt-0 pt-0 time-w-80"
                      ></v-text-field>
                    </v-col>
                    <v-col md="5" class="ma-0 pa-0" :style="{ position: allowAddTime ? 'relative': '' }">
                      <v-select
                        :items="['AM','PM']"
                        id="todo_ampm"
                        name="todo_ampm"
                        v-validate="'required'"
                        v-model="todo.ampm"
                        data-vv-name="todo_ampm"
                        data-vv-as="AM/PM"
                        data-vv-scope="todo"
                        hide-details
                        width="80%"
                        :error="errors.collect('todo.todo_ampm').length > 0"
                        class="mt-0 pt-0 time-w-80"
                      ></v-select>
                      <hb-icon @click="allowAddTime = false" class="hand" style="position: absolute;right: -15px;top: 0;">mdi-close</hb-icon>
                    </v-col>

                    <!--
                    <v-col md="12" v-if="errors.has('todo.todo_minutes') || errors.has('todo.todo_hour') || errors.has('todo.todo_ampm')" class="pa-0 pt-1 pl-2">
                      <span v-show="errors.has('todo.todo_hour')" class="status-block error-block field-error mb-0">{{ errors.first('todo.todo_hour') }}</span>
                      <span v-show="errors.has('todo.todo_minutes')" class="status-block error-block field-error mb-0">{{ errors.first('todo.todo_minutes') }}</span>
                      <span v-show="errors.has('todo.todo_ampm')" class="status-block error-block field-error mb-0">{{ errors.first('todo.todo_ampm') }}</span>
                    </v-col>
                    -->
                  </v-row>
                  <!-- <HbTimePicker
                    @input="setTime"
                    label="00:00"
                    clearable
                    data-vv-as="due time"
                    v-model="todo.time"
                    name="due_time"
                    v-validate="'required'"
                    data-vv-scope="todo"
                    :message="errors.collect('todo.due_time')"
                  ></HbTimePicker> -->
                </v-col>
              </v-row>
          </hb-form>

          <hb-form label="Assign to" required>
            <HbSelect
              v-model="todo.contact_id"
              v-validate="'required'"
              :items="admins"
              item-text="name"
              item-value="id"
              placeholder="Select Assignee"
              id="admin_id"
              name="admin_id"
              data-vv-name="contact_id"
              data-vv-as="Assign to"
              data-vv-scope="todo"
              :error="errors.collect('todo.contact_id').length > 0"
            />
          </hb-form>

          <hb-form
            label="Notes"
            required
            full
            last
          >
            <HbTextarea
              v-model="todo.details"
              v-validate="'required|max:1000'"
              id="notes"
              name="notes"
              placeholder="Enter Notes"
              data-vv-scope="todo"
              data-vv-as="Notes"
              :error="errors.collect('todo.notes').length > 0"
            />
          </hb-form>

        </div>
      </div>
    </template>
    <template v-slot:actions>
      <hb-btn
        color="primary"
        :disabled="isLoading($options.name)"
        :loading="isLoading($options.name)"
        @click="save">{{ btn_text ? btn_text: 'Create' }}
      </hb-btn>
    </template>
  </hb-modal>
</template>



<script type="text/babel">
import Status from "../includes/Messages.vue";
import SendMessage from '../includes/SendMessage.vue'
import moment from "moment";
import api from "../../assets/api.js";
import Dropdown from "../assets/Dropdown.vue";
import Datepicker from "vuejs-datepicker";
import HbDatePicker from "../assets/HummingbirdDatepicker";
import HbTimePicker from "../assets/HummingbirdTimepicker";
import Autocomplete from "../assets/Autocomplete.vue";
import { EventBus } from "../../EventBus.js";
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "AddTodo",
  mixins: [ notificationMixin ],
  data() {
    return {
      loaded: false,
      actions: [
        "Phone Call",
        "Email",
        "Other",
        "Schedule Inspection",
        "Space requires Maintenance",
        "Lead Follow up"
      ],
      todo: {
        name: "",
        action: "",
        date: "",
        time: "",
        hh: "05",
        mm: "00",
        ampm: "PM",
        details: "",
        contact_id: "",
        event_type: ''
      },
      admins: [],
      filterChange: false,
      newEntryCounter: 0,
      inspectionScheduled: false,
      sendMessage: false,
      allowAddTime: false
    };
  },
  props: ["lease", "contact", "moveout_prepare_space", "title_text", "btn_text", 'defaultAction', 'disableAction', "contact_id", "lease_id", "event_type", "value", "due_date", "name"],
  components: {
    Dropdown,
    Status,
    Datepicker,
    HbDatePicker,
    HbTimePicker,
    Autocomplete,
    SendMessage
  },
  created() {
    if(this.defaultAction) {
      this.todo.action = this.defaultAction
    }
    this.fetchAdmins();
    this.getEventTypes();
    this.todo.contact_id = this.getUserData.id;
    if(this.$props.title_text == "Schedule Inspection") {
      this.todo.name = "Schedule Inspection on Space #"+ this.$props.lease.Unit.number;
      this.todo.action = "Schedule Inspection";
    }
    if(this.$props.due_date){
      this.todo.date = this.$props.due_date;
    }
    if(this.$props.name){
      this.todo.name = this.$props.name;
    }
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "create-task-button-clicked"})
    }, 2000);
    this.setEventType();
  },
  computed: {
    ...mapGetters({
      getUserData: "authenticationStore/getUserData",
      eventsTypes: 'taskCenterStore/eventTypes',
    }),
    dialog: {
      get () {
          return this.value
      },
      set (value) {
          this.$emit('input', value)
      }
    },
    disabledDates() {
      return {
        to: moment()
          .startOf("day")
          .toDate()
      };
    },
    selectedTenant() {
      let tenant = this.$props.lease.Tenants.find(
        obj => obj.contact_id == this.$props.contact.id
      );
      return tenant;
    },
    selectedPhone() {
      let phone = this.selectedTenant.Contact.Phones.length
        ? this.selectedTenant.Contact.Phones[0].phone
        : "";
      return phone;
    },
    computedContactTitle() {
      return (this.contact.first ? this.contact.first : '') + (this.contact.middle ? ' ' + this.contact.middle : '') + (this.contact.last ? ' ' + this.contact.last : '');
    },
    computedContactPhone() {
      if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone){
        return this.contact.Phones[0].phone;
      } else {
        return undefined;
      }
    },
    computedContactPhoneVerified() {
      if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone_verified && this.contact.Phones[0].phone_verified == 1){
          return true;
      } else {
          return false;
      }
    },
    computedContactAddress() {
      if(this.contact.Addresses && this.contact.Addresses[0] && this.contact.Addresses[0].Address){
        const address_1 = this.contact.Addresses[0].Address.address ? this.contact.Addresses[0].Address.address : '';
        const address_2 = this.contact.Addresses[0].Address.address2 ? this.contact.Addresses[0].Address.address2 : '';

        let address = address_1;
        if(address_2 != '') address += ' ' + address_2;
        
        address += ', ' + this.$options.filters.formatAddress(this.contact.Addresses[0].Address); 
        
        return address;
      } else {
        return undefined;
      }
    }
  },
  watch: {
    eventsTypes(value){
      if (value && value.length) {
        this.setEventType();
      }
    }
  },
  methods: {
    ...mapActions({
          getEventTypes: 'taskCenterStore/getEventTypes'
      }),
    async fetchAdmins() {
      api.get(this, api.ADMIN).then(r => {
        this.admins = r.admins
          .filter(a => a.contact_id !== this.getUserData.id)
          .map(a => {
            return {
              id: a.contact_id,
              name: a.first + " " + a.last
            };
          });
          //let admins_ids = r.filter(a)
        this.admins.unshift({
          id: api.get(this, api.ADMIN).then(rr => rr.admins.map(aa => aa.id)),
          name: "Everyone"
        });
        this.admins.unshift({
          id: this.getUserData.id,
          name: "Me"
        });
      });
    },
    setTime(time) {
      this.todo.time = time;
    },
    async save() {
      let status = await this.$validator.validateAll("todo");
      
      if(!status){
		this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
	  } 

      var hours =  this.allowAddTime ? this.todo.ampm == 'PM' ? parseInt(this.todo.hh) + 12 : this.todo.hh : 23;
      var minutes = this.allowAddTime ? this.todo.mm : 59;
      var start = moment(
        moment(this.todo.date).format("YYYY-MM-DD") +
          " " +
          hours +
          ":" +
          minutes,
        "YYYY-MM-DD HH:mm"
      );

      var data = {
        name: this.todo.name,
        time: hours + ":" + minutes,
        action: this.todo.action,
        start_date: moment(start).toDate(),
        end_date: moment(start).toDate(),
        details: this.todo.details,
        assign_to: this.todo.contact_id === -1? null : this.todo.contact_id,
        event_type_id: this.todo.event_type
      };
      if (this.$props.contact_id) {
        data.contact_id = this.$props.contact_id;
      }
      if (this.$props.lease_id) {
        data.lease_id = this.$props.lease_id;
      }

      this.validate(this).then(status => {

        if(!status){
        
		  this.showMessageNotification({ type: 'error', description: 'An error has occurred. Please recheck your form entries and try again.' });
          return;

        } 
      });

      try {

        let r = await api.post(this, api.TODOS, data)
        this.inspectionScheduled = true;
        this.resetMessage();

        this.showMessageNotification({ type: 'success', description: 'The task has been successfully created.' });
        this.dialog = false;
        EventBus.$emit('reset_profile_tasks');

      } catch(err) {
          this.showMessageNotification({ description: err });
      }

    },
    resetForm() {
      // this.todo = {
      //   date: null,
      //   name: "",
      //   hh: "",
      //   mm: "",
      //   ampm: "AM",
      //   details: "",
      //   contact_id: ""
      // };
      // this.$emit("close");
    },
    resetMessage() {
      // this.resetForm();
      // this.errorClear(this.$options.name);
      this.filterChange = !this.filterChange;
      if(this.$props.moveout_prepare_space) {
        this.$emit("close", this.inspectionScheduled);
      }
      else {
        this.$emit("close");
      }
      // this.newEntryCounter = 0;
    },
    
    /*
    newEntry() {
      this.newEntryCounter++;

      if ((this.newEntryCounter = 1)) {
        this.errorClear(this.$options.name);
        this.successClear(this.$options.name);
      } else {
        return;
      }
    },
    */

    setEventType(){
        if (this.eventsTypes && this.eventsTypes.length) {
          if (this.$props.event_type) {
            this.todo.event_type = this.eventsTypes.find( e => e.slug === this.$props.event_type).id
          } else {
            this.todo.event_type = this.eventsTypes.find( e => e.slug === 'task').id;
          }
        }
    }
  }
};
</script>

<style scoped>
  .time-w-80{
    width: 80%;
  }

</style>

<style >
.border {
  border-top: 1px solid #dce8ef !important;
}

.padding {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
}

.reminder-button-padding {
  padding-right: 15px;
  padding-bottom: 15px;
}
.button {
  border-radius: 5px;
  background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  display: inline-block;
  padding: 9px 15px;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
.right-align {
  float: right;
}
.custom-height {
  height: 60px !important;
}
.custom-padding {
  padding-top: 20px !important;
  padding-bottom: 5px !important;
}
.property-mailto {
  color: #306fb6;
  text-decoration: none;
}
.v-select__slot,
.v-text-field__slot,
.v-label,
.v-list-item {
  font-size: 14px !important;
}
.todo-body {
    max-height: 56vh;
    max-height: 56svh;
    overflow-y: auto;
}
.time-margin {
  margin-top: 8px !important;
}
</style>
