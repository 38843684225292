<template>
    <div>
        <div>
            <v-container no-gutters class="pr-0 mb-0">
                <v-row no-gutters>
                    <v-col md="7" no-gutters>
                        <div class="hb-font-header-2-medium pt-1">{{ isBuyingBack ? 'Select Buyback' : 'Select' }} Merchandise</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right mr-0 pr-5" md="5">
                        <hb-text-field
                            search
                            placeholder="Search"
                            v-model="product_search"
                        >
                        </hb-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <hb-empty-state v-if="!property?.id" class="mr-5"
                message="Please select a property to continue.">
            </hb-empty-state>
        </div>

        <v-container class="pa-0 pb-2 position-relative pr-2" :class="{'d-flex flex-wrap scrollable-content scroll-products' : filtered_products.length || isLoadingProducts}">
            <async-loader v-if="isLoadingProducts" style="left: 45%;" />
            <div v-for="product in filtered_products" :key="product.id" v-else class="col-4 pa-0">
                <hb-card class="ma-1 pa-3" :boxShadow="false" :border="false">
                    <div class="img-outer text-center" v-if="product.image">
                        <img  :src='"data:image/" + product.image.image_type + ";base64," + product.image.data_url' />
                    </div>
                    <div class="placeholder-img" v-else>
                        <hb-icon color="#A5B4BF">mdi-image-area</hb-icon>
                        <div class="light-color hb-button-text-small pt-2">No Image Available</div>
                    </div>

                    <!-- <img :src="product.images[0]" alt="" style="max-width: 222px;" srcset=""> -->
                    <div class="d-flex justify-space-between mt-2 mb-3">
                        <div class="font-14px font-weight-medium product-title">{{ product.title }}</div>
                        <p class="mb-0" v-if="product.amount_type !== 'variable'">${{ product.price }}</p>
                    </div>
                    <div class="product-description hb-text-light hb-button-text-small">
                        <span v-if="product.description">
                            {{ product.description | truncateText(100) }}
                        </span>
                        <span v-else class="light-color">No description available</span>
                    </div>
                    <div v-if="merchandiseSettings">
                        <div class="mt-3 align-end d-flex pb-1 h-62px" v-if="product.out_of_stock">
                            <hb-icon color="#FB4C4C" class="mr-1" small>mdi-alert</hb-icon><span class="hb-button-text-regular top-2px" style="color: #FB4C4C; font-weight: 500;">Out of Stock</span>
                        </div>
                        <div class="product-actions mt-3" v-else>
                            <div v-if="product.amount_type !== 'variable'" class="stock-available  hb-button-text-small mb-2" :class="[ product.stock < 0 ? 'danger-color' : 'hb-text-light']">
                                {{ product.stock ? product.stock + ' in stock' : 'Out of stock' }}
                            </div>
                            <div style="height: 26px;" v-else></div>
                            <div class="d-flex justify-space-between align-center">
                                <hb-text-field
                                    v-model="product.price"
                                    dense
                                    box
                                    medium
                                    class="merchandise-product-cls"
                                    v-if="product.amount_type === 'variable'"
                                >
                                </hb-text-field>
                                <div v-else class="stock-btns d-flex align-center justify-space-between px-2" style="width: 100px; height:32px; border: 1px solid #DFE3E8; border-radius: 5px;">
                                    <hb-icon color="#000" small @click="() => { if(product.qty > 1 && product.allow_buy) product.qty=product.qty-1; }" class="hand top--2px">mdi-minus</hb-icon>
                                    <span class="top--2px">{{ product.qty }}</span>
                                    <hb-icon color="#000" small @click="() => { if(product.allow_buy && (product.qty < product.stock || product.allow_negative)) product.qty=product.qty+1; }" class="hand top--2px">mdi-plus</hb-icon>
                                </div>
                                <hb-btn color="secondary" :disabled="!product.allow_buy" width="100px" :class="{'not-allowed': !product.allow_buy}" @click="saveService(product)">
                                    {{ services[product.id] && services[product.id].product_id ? 'Update' : 'Add' }}
                                </hb-btn>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="product-actions mt-3">
                            <div class="d-flex justify-space-between align-center">
                                <hb-text-field
                                    v-model="product.price"
                                    dense
                                    box
                                    medium
                                    class="merchandise-product-cls"
                                    v-if="product.amount_type === 'variable'"
                                >
                                    <template v-slot:prepend-inner>
                                        $
                                    </template>
                                </hb-text-field>
                                <div v-else class="stock-btns d-flex align-center justify-space-between px-2" style="width: 100px; height:32px; border: 1px solid #DFE3E8; border-radius: 5px;">
                                    <hb-icon color="#000" small @click="() => { if(product.qty > 0) product.qty=product.qty-1; }" class="hand top--2px">mdi-minus</hb-icon>
                                    <span class="top--2px">{{ product.qty }}</span>
                                    <hb-icon color="#000" small @click="product.qty=product.qty+1" class="hand top--2px">mdi-plus</hb-icon>
                                </div>
                                <hb-btn color="secondary" width="100px" @click="saveService(product)">
                                    {{ services[product.id] && services[product.id].product_id ? 'Update' : 'Add' }}
                                </hb-btn>
                            </div>
                        </div>
                    </div>
                </hb-card>
            </div>
            <hb-empty-state class="mr-5" v-if="!isLoadingProducts && property?.id && !filtered_products.length"
                message="No product available against this property">
            </hb-empty-state>
        </v-container>
    </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import AsyncLoader from '../../includes/Loading.vue';
    import { mapActions, mapGetters } from 'vuex';

    import moment from 'moment';

    export default {
        name: "ConfigureMerchandise",
        mixins: [ notificationMixin ],
        components: {
            AsyncLoader
        },
        data() {
            return {

                product_search: '',
            }
        },
        props: ['merchandise', 'services', 'isLoadingProducts', 'property', 'isBuyingBack', 'isReturning'],
        created (){
            this.getMerchandiseSettingsAction();
        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {
            ...mapGetters({
                merchandiseSettings: 'productStore/merchandiseSettings',
			    activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
            filtered_products: {
                get() { 
                    return this.merchandise.filter(p => !this.product_search || p.title.toLowerCase().search(this.product_search.toLowerCase()) >= 0 || (p.description && p.description.toLowerCase().search(this.product_search.toLowerCase()) >= 0))
                }
            }
        },
        methods: {
            ...mapActions({
                getMerchandiseSettingsAction: 'productStore/getMerchandiseSettingsAction',
			    setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
            }),
            async saveService(params){
                if (this.merchandiseSettings && !this.isBuyingBack && !this.isReturning && (!params.allow_negative && params.qty > params.stock)) {
                    this.showMessageNotification({ description: `Sorry, you've requested more items than we have in stock. Please adjust your order.` })
                    return;
                }
                var data = {
                    product_id: params.id,
                    name: params.title,
                    price: params.price,
                    qty: parseInt(params.qty),
                    recurring: 0,
                    type: params.type ? params.type : 'product',
                    prorate: 0,
                    description: params.description,
                    start_date: moment().format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    taxable:params.taxable,
                    amount_type: params.amount_type,
                    stock: params.stock,
                    allow_negative: params.allow_negative
                };
                this.$set(this.services, params.id, data);
                await this.$emit('calculateTotal');
                this.setActiveDrawerComponent({
                    ...this.activeDrawerComponent,
                    services: this.services
                })
                //this.$emit('setService', data);
            },
            removeService(product){
                product.qty = null;

                this.services[product.id] = {};
                this.$emit('services', this.services);
            },
            isVariableRate(rate_type) {
                return rate_type === 'variable';
            }
        }
    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #DFE3E8;
        background: white;
        border-radius: 4px;
        padding: 12px 36px 0 36px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }
    .merchandise-row{
        border-bottom: 1px solid #DFE3E8;
    }
    .merchandise-row:last-child{
        border-bottom: none;
    }
    .remove-service-btn {
        display: block;
        padding-top: 10px;
    }

    .scroll-products {
        height: calc(100vh - 450px);
        margin-bottom: 65px;
    }

    .ellipsis-multiline {
        position: relative;
        max-height: 4.5em; /* Adjust based on the number of lines (line-height * number of lines) */
        overflow: hidden;
        padding-right: 1em; /* Space for the fade effect */
        line-height: 1.5em; /* Adjust based on your font-size */
    }

    .ellipsis-multiline::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1em;
        height: 1.5em; /* Match the line-height */
        background: linear-gradient(to right, transparent, white 50%);
    }

    .element-aa {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .truncate-a {
        display: table-cell;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .product-description {
        min-height: 55px;
    }

    .product-title {
        min-height: 42px;
    }

    .placeholder-img {
        width: 100%;
        min-height: 222px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #F9F9F9;
    }

    .light-color {
        color: #A5B4BF;
    }

    .not-allowed {
        cursor: not-allowed;
    }

    .top--2px {
        position: relative;
        top: -2px;
    }
    .h-62px {
        height: 62px;
    }
    .top-2px {
        position: relative;
        top: 2px;
    }
    .img-outer {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 222px;
    }
    .img-outer img {
        height: 100%;
        max-width: 100%;
        max-height: 222px;
    }

</style>

<style>
.merchandise-product-cls .hb-text-field-rounded.hb-text-field-medium .v-input__control .v-input__slot {
    min-height: 33px;
    max-width: 99px;
}
</style>
