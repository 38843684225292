<template>

  <div>                
        <div class="omni-search-container">
        <hb-blade-header
        class="omni-search-heading"
        title="Find Tenants, Leads or Spaces"
        half
        back-button-off
        firstColumnOff
        @close="close"
      >
      </hb-blade-header>
          <div  class="my-4 mx-5" @click.stop="$refs.searchfield.focus()">
            <HbTextField
              ref="searchfield"
              full
              search
              @input="search"
              v-model="params.search"
              maxlength="50"
              autofocus
              placeholder="Search"
              class="my-1"
            />
          </div>
        </div>
      <v-row class="ma-0">
        <v-col class="pa-0" cols="12">
            <v-tabs
                class="hb-text-light pl-6"
                color="#101318"
                v-model="right_column_view"
            >
                <v-tabs-slider color="#00848E"></v-tabs-slider>
                <v-tab
                    :key="'topResults'"
                    class="text-capitalize"
                    :ripple="false"
                    @click="searchResults"
                > Top Results
                </v-tab>
                <v-tab
                    :key="'people'"
                    class="text-capitalize"
                    :ripple="false"
                    @click="searchContacts"
                > People
                </v-tab>
                <v-tab
                    :key="'tenant_information'"
                    class="text-capitalize"
                    :ripple="false"
                    @click="searchUnits"
                > Spaces
                </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-row class="ma-0">
              <v-col cols="12" class="pa-0">
                <div class="results-container" @scroll.self="searchNextTabResults">
                  <span v-show="right_column_view === 0">
                    <div class="omni-search-result-item">
                        <v-list-item v-for="(r,index) in results" :key="'contact' + index" class="px-6 result-item">
                            <span class="result-item-border py-4" v-if="r.search_type == 'contact'">
                            <v-list-item-content style="align-self:center;" class="py-0" @click="viewLease(r);onSearchResultSelection()">
                            <mini-profile-view 
                                v-if="r?.id"
                                :contact-id="r.id"
                                :use-contact="true"
                                :contact-data="r"
                                style="max-width:100%"
                            />
                        </v-list-item-content>
                            </span>
                            <span class="result-item-border py-4" v-else-if="r.search_type == 'national_account'">
                                <v-list-item-content style="align-self:center;" class="py-0" @click="viewLease(r)">
                                <hb-mini-profile 
                                    type="account" 
                                    :title="r.Business?.name" 
                                    :status="r.status ? r.status : undefined
                                    ":address="getAddress(r)"
                                    :account-space-count="calculateLength(r)" :account-size="String(r?.statistics?.sqm)" 
                                    :account-total-monthly-spent="String(r?.statistics?.monthly_rent)" 
                                />
                        </v-list-item-content>
                            </span>
                          <span class="result-item-border py-4" v-else>
                            <v-list-item-content style="align-self:center;" class="py-0" @click="r.state.toLowerCase() !== 'leased' && 
                            r.state.toLowerCase() !== 'reserved' && r.state.toLowerCase() !== 'pending' && r.state.toLowerCase() !== 'overlocked' && r.state.toLowerCase() !== 'to overlock' && r.state.toLowerCase() !== 'remove overlock' ? viewUnit(r) : checkAndView(r)">
                                <hb-mini-profile
                                    type="space"
                                    :title="r?.number"
                                    :status="r?.state"
                                    :space-is-occupied="r.Lease ? true : false"
                                    :space-type="getUnitTypeLabel(r)"
                                    :space-category="r.label"
                                    :space-featured-amenity="r?.Category?.name"
                                    :space-property-info="r?.Address | formatAddress('^', true)"
                                    :tenant-name="r.Lease?.Tenants[0]?.Contact?.national_account_id ? r.Lease?.Tenants[0]?.Contact?.national_account_name : (r.Lease ? r.Lease?.Tenants[0]?.Contact?.first + ' ' + r.Lease.Tenants[0]?.Contact?.last : '')"
                                    :phone="r.Lease ? getPhoneNumber(r.Lease) : ''"
                                    :email="r.Lease ? r.Lease?.Tenants[0]?.Contact?.email : ''"
                                    :tenant-is-account="r.Lease?.Tenants[0]?.Contact?.national_account_id"
                                    style="max-width:100%"
                                >
                                </hb-mini-profile>
                            </v-list-item-content>
                            </span>
                        </v-list-item>
                        <v-list-item v-show="!isFinished|| !isTopResultsLoading" >
                            <v-list-item-content >
                                <v-list-item-title class="text-left ml-2 font-weight-medium">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                    Loading...
                                </v-list-item-title>
                                <img src="../../assets/images/global-search-watermark.svg" height="294" class="pt-12" alt="">
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="isFinished && !results.length && isTopResultsLoading" class="px-11 py-10">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="omni-search-underline-text">No Results Found</span>
                                </v-list-item-title>
                                <img src="../../assets/images/global-search-watermark.svg" height="286" class="pt-10" alt="">
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                  </span>
                  <span v-show="right_column_view === 1">
                        <div class="omni-search-result-item">
                        <v-list-item v-for="(r,index) in contactResults" :key="'contact' + index" class="px-6 result-item" @click="viewLease(r)">
                            <span class="result-item-border py-4" v-if="r.search_type == 'contact'">
                            <v-list-item-content style="align-self:center;" class="result-item-border py-4" >
                                <mini-profile-view 
                                    v-if="r?.id"
                                    style="max-width:100%"
                                    :contact-id="r.id"
                                    :use-contact="true"
                                    :contact-data="r"
                                    />
                            </v-list-item-content>
                            </span>
                            <span class="result-item-border py-4" v-else-if="r.search_type == 'national_account'">
                                <v-list-item-content style="align-self:center;" class="py-0" @click="viewLease(r)">
                                <hb-mini-profile 
                                    type="account" 
                                    :title="r.Business?.name" 
                                    :status="r.status ? r.status : undefined
                                    ":address="getAddress(r)"
                                    :account-space-count="calculateLength(r)" :account-size="String(r?.statistics?.sqm)" 
                                    :account-total-monthly-spent="String(r?.statistics?.monthly_rent)" 
                                />
                        </v-list-item-content>
                            </span>
                        </v-list-item>
                        <v-list-item v-show="!isFinished || !isContactLoading" >
                            <v-list-item-content >
                                <v-list-item-title class="text-left ml-2 font-weight-medium">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                    Loading...
                                </v-list-item-title>
                                <img src="../../assets/images/global-search-watermark.svg" height="294" class="pt-12" alt="">
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="isFinished && !contactResults.length && isContactLoading" class="px-11 py-10">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="omni-search-underline-text">No Results Found</span>
                                </v-list-item-title>
                                <img src="../../assets/images/global-search-watermark.svg" height="286" class="pt-10" alt="">
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                  </span>
                  <span v-show="right_column_view === 2">
                      <div class="omni-search-result-item">
                        <v-list-item v-for="(r,index) in unitResults" :key="'unit' + index" class="px-6 result-item" >
                          <span class="result-item-border py-4">
                            <v-list-item-content style="align-self:center;" class="py-0" @click="r.state.toLowerCase() !== 'leased' && 
                            r.state.toLowerCase() !== 'reserved' && r.state.toLowerCase() !== 'pending' && r.state.toLowerCase() !== 'overlocked' && r.state.toLowerCase() !== 'to overlock' && r.state.toLowerCase() !== 'remove overlock' && r.state.toLowerCase() !== 'offline' && r.state.toLowerCase() !== 'closed'? viewUnit(r) : checkAndView(r)">
                                <hb-mini-profile
                                    type="space"
                                    :title="r?.number"
                                    :status="r?.state"
                                    :space-is-occupied="r.Lease ? true : false"
                                    :space-type="getUnitTypeLabel(r)"
                                    :space-category="r.label"
                                    :space-featured-amenity="r?.Category?.name"
                                    :space-property-info="r?.Address | formatAddress('^', true)"
                                    :business-name="r.rent_as_business ? 'a' : ''"
                                    :tenant-name="r.Lease?.Tenants[0]?.Contact?.national_account_id ? r.Lease?.Tenants[0]?.Contact?.national_account_name : (r.Lease ? r.Lease?.Tenants[0]?.Contact?.first + ' ' + r.Lease.Tenants[0]?.Contact?.last : '')"
                                    :phone="r.Lease ? getPhoneNumber(r.Lease) : ''"
                                    :email="r.Lease ? r.Lease?.Tenants[0]?.Contact?.email : ''"
                                    :tenant-is-account="r.Lease?.Tenants[0]?.Contact?.national_account_id"
                                    style="max-width:100%"
                                >
                                </hb-mini-profile>
                            </v-list-item-content>
                          </span>
                        </v-list-item>
                        <v-list-item v-show="!isFinished || !isUnitLoading" >
                            <v-list-item-content >
                                <v-list-item-title class="text-left ml-2 font-weight-medium">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                    Loading...
                                </v-list-item-title>
                                <img src="../../assets/images/global-search-watermark.svg" height="294" class="pt-12" alt="">
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="isFinished && !unitResults.length && isUnitLoading" class="px-11 py-10">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="omni-search-underline-text">No Results Found</span>
                                </v-list-item-title>
                                <img src="../../assets/images/global-search-watermark.svg" height="286" class="pt-10" alt="">
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                  </span>
                </div>
              </v-col>
            </v-row>
        </v-col>
    </v-row>
    <hb-modal
    v-model="showNoPermissionDialogNationalAccountManage"
    size="medium"
    title="You Don’t Have Permission"
    :footerOff="true"
    confirmation
    @close="showNoPermissionDialogNationalAccountManage = false"
    show-help-link
>
    <template v-slot:content>
    <div class="px-6 py-4">
        <div class="pb-2">
            You do not have the required permission to perform this action.
        </div>
        <div>
            Required Permission: 
            <span class="hb-font-body-medium">
                Manage National Account
            </span>
        </div>
    </div>
    </template>
    </hb-modal>
  </div>

</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';

    import SlideOutWindow from '../includes/SlideOutWindow.vue';
    import UnitView from '../units/UnitView.vue';
    
    import { EventBus } from '../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';
    import LEASE from '@/constants/lease.js'; 
    import UNITS from '@/constants/units.js';
    import MiniProfileView from '../includes/LeadIntake/MiniProfileView.vue';

    export default {
        name: "OmniSearch",
        data() {
            return {
                params:{
                    search: '',
                    limit: 10,
                    offset: 0
                },
                category: 'contacts',
                results: [],
                isFinished: true,
                selected: null,
                showWindow: false,
                contactResults: [],
                unitResults:[],
                result_time: '',
                unit_time: '',
                contact_time: '',
                resultParams: {
                    search: '',
                    limit: 20,
                    offset: 0,
                },
                contactParams: {
                    search: '',
                    limit: 20,
                    offset: 0,
                },
                unitParams: {
                    search: '',
                    limit: 20,
                    offset: 0,
                },
                defaultOffset: 20,
                isTopResultsLoading: true,
                isContactLoading: true,
                isUnitLoading: true,
                isSearchResults: true,
                isContactResults: true,
                isUnitResults: true,
                right_column_view: 0,
                showNoPermissionDialogNationalAccountManage:false
            }
        },
        components:{
            Loader,
            Status,
            SlideOutWindow,
            UnitView,
            MiniProfileView
        },
        created(){
            EventBus.$emit('priority_action');
            EventBus.$on('hide_drawer', this.close);
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                showOnlySettingsNavigation: 'navigationStore/showSettingsNavigationOnly',
                activeDrawerComponent: 'navigationStore/activeDrawerComponent',
                hasPermission: 'authenticationStore/rolePermission',
            }),
        },
        mounted(){
            this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if(this.size) this.modalSize = 'modal-' + this.size;
            if(this.blank) this.modalBlank = 'modal-blank';
            window.scrollTo(0, this.scrollTop);
            document.body.style.top =  '-' + this.scrollTop + 'px';
            setTimeout(() => {
                this.$refs.searchfield.focus();
            }, 100);

            if(this.activeDrawerComponent.type === 'show_omni_Search'){
                this.params.search =  this.activeDrawerComponent.search;
                this.right_column_view =  this.activeDrawerComponent.right_column_view;
                this.search();
            }
        },
        destroyed(){
            document.body.classList.remove('noscroll');
            document.body.scrollTop = this.scrollTop;
            this.selected = null;
            this.showWindow = false;
            this.selected = null;
            //document.html.scrollTop = this.scrollTop;
            EventBus.$off('hide_drawer', this.close);
        },
        props:['size', 'blank', 'hideHeader' ],
        methods: {
            ...mapActions({
                setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
                actionFollowUpStatus:'onBoardingStore/actionFollowUpStatus',
            }),
            onSearchResultSelection() {
                EventBus.$emit('hideNavigationDrawer');
            }, 
            calculateLength(r) {
                let leases = r.Leases;
                return leases?.filter((lease) => {
                    return this.filterLeases(lease.Unit.property_id);
                })?.length;
            },
            filterLeases(property_id) {
                return this.properties.some((i) => i.id === property_id);
            },
            searchNextTabResults({ target: { scrollTop, clientHeight, scrollHeight }}) {
                if(this.right_column_view == 0) {
                    this.searchNextResults({ target: { scrollTop, clientHeight, scrollHeight }});
                } else if(this.right_column_view == 1) {
                    this.searchNextContacts({ target: { scrollTop, clientHeight, scrollHeight }});
                } else {
                    this.searchNextUnits({ target: { scrollTop, clientHeight, scrollHeight }});
                }
            },
            onSearchResultSelection() {
                EventBus.$emit('hideNavigationDrawer');
            },           
            getAddress(r){
                try{
                    return this.$options.filters.formatAddress(r?.Addresses[0]?.Address, ',', true)
                }catch(e){
                    return ""
                }
            },
            async searchNextResults({ target: { scrollTop, clientHeight, scrollHeight }}){
                if (scrollTop + clientHeight >= scrollHeight) {
                    if (this.isTopResultsLoading) {
                        this.isTopResultsLoading = false
                        this.isSearchResults = true;
                        await this.searchResults()
                        this.isSearchResults = false;
                        this.isTopResultsLoading = true
                    }

                }
            },
            async searchNextContacts({ target: { scrollTop, clientHeight, scrollHeight }}){
                if (scrollTop + clientHeight >= scrollHeight) {
                    if (this.isContactLoading) {
                        this.isContactLoading = false
                        this.isContactResults = true;
                        await this.searchContacts()
                        this.isContactResults = false;
                        this.isContactLoading = true
                    }

                }
            },
            async searchNextUnits({ target: { scrollTop, clientHeight, scrollHeight }}){
                if (scrollTop + clientHeight >= scrollHeight) {
                    if(this.isUnitLoading) {
                        this.isUnitLoading = false
                        this.isUnitResults = true;
                        await this.searchUnits()
                        this.isUnitResults = false;
                        this.isUnitLoading = true
                    }

                }
            },
            tenantBalance(leases = []){
                if(!leases.length) return false;
            },
            closeWindow(){
                this.showWindow = false;
                this.selected = null;
            },
            display(r){
                if(this.category === 'contacts'){
                    return r.first  + ' ' + r.last + '<br />' + '<span class="subdued">' + r.email + '</span>';
                } else if(this.category === 'units'){
                    return "#" + r.number + '<br />' + '<span class="subdued">' + this.$options.filters.formatAddress(r.Address, ',', true) + '</span>';
                }
            },
            away (){
                this.$store.commit('setOmniSearch', false);
                this.selected = null;
            },
            setCategory(cat){
                this.category = cat;
                this.search();
            },

            search:  _.debounce(async function() {

                // Cache params
                this.isFinished = false;
                this.results = [];
                this.contactResults = [];
                this.unitResults = [];
                this.isSearchResults = true;
                this.isContactResults = true;
                this.isUnitResults = true;
                this.resultParams = {
                    search: this.params.search,
                    limit: 20,
                    offset: 0,
                }
                this.contactParams = {
                    search: this.params.search,
                    limit: 20,
                    offset: 0,
                }
                this.unitParams = {
                    search: this.params.search,
                    limit: 20,
                    offset: 0,
                }
                
                if(this.activeDrawerComponent.type === 'show_omni_Search' && this.activeDrawerComponent.search !== this.params.search){
                    this.setActiveDrawerComponent({type:'show_omni_Search', search: this.params.search, right_column_view: this.right_column_view  });
                }

                if(this.right_column_view == 0 ) {
                    await this.searchResults();
                } else if(this.right_column_view == 1) {
                    await this.searchContacts();
                } else {
                    await this.searchUnits();
                }
            }, 300),

            async searchResults () {
              var d = new Date();
              this.result_time = d.getTime();
              
              if (this.results.length > 0) {
                  
                this.resultParams.offset += this.defaultOffset
              }
              let data = JSON.parse(JSON.stringify(this.resultParams));
              data.t = this.result_time;
              
              if (this.results.length % data.limit === 0 && this.isSearchResults) {
                this.isFinished = false;
                let r = await api.post(this, api.UNITS_OMNI, data)
                this.isSearchResults = false;
                if (this.result_time === r.t){
                    if (this.results.length === 0) {
                        this.results = r.results || [];
                    } else {
                        this.results = this.results.concat(r.results)
                    }
                }
                this.isFinished = true;
              }
            },

            async searchContacts () {
              var d = new Date();
              this.contact_time = d.getTime();
              if (this.contactResults.length > 0) {
                this.contactParams.offset += this.defaultOffset
              }
              let data = JSON.parse(JSON.stringify(this.contactParams));
              data.t = this.contact_time;
              if (this.contactResults.length % data.limit === 0 && this.isContactResults) {
                this.isFinished = false;
                let r = await api.post(this, api.UNITS_OMNI + '?type=contact', data)
                this.isContactResults = false;
                if (this.contact_time === r.t){
                    if (this.contactResults.length === 0) {
                        this.contactResults = r.results || []
                    } else {
                        this.contactResults = this.contactResults.concat(r.results)
                    }
                }
                this.isFinished = true;
              }
            },

            async searchUnits(){
              var d = new Date();
              this.unit_time = d.getTime();
              if (this.unitResults.length > 0) {
                this.unitParams.offset += this.defaultOffset
              }
              let data = JSON.parse(JSON.stringify(this.unitParams));
              data.t = this.unit_time;

              if (this.unitResults.length % data.limit === 0 && this.isUnitResults) {
                this.isFinished = false;
                let r = await api.post(this, api.UNITS_OMNI + '?type=space', data)
                console.log("r", r)
                this.isUnitResults = false;
                if (this.unit_time === r.t){
                    if (this.unitResults.length === 0) {
                        this.unitResults = r.results || []
                    } else {
                        this.unitResults = this.unitResults.concat(r.results)
                    }
                }
                this.isFinished = true;
              }
            },
            go(obj){
                this.selected = obj.id;
                this.showWindow = true;
            },
            close(){
                this.$emit('close');
            },
            viewContact(data){
                if (this.showOnlySettingsNavigation) {
                    EventBus.$emit('HB-Navigation:Hide-Settings-Modal');
                }
                this.close();
                this.$store.dispatch('navigationStore/openSlideOut', {
                    component: 'contact',
                    props: {
                        contact_id: data.id,
                        view: "lead"
                    }
                });
            },

            viewUnit(data){
                if (this.showOnlySettingsNavigation) {
                    EventBus.$emit('HB-Navigation:Hide-Settings-Modal');
                }

                if(data.state && data.state.toLowerCase() === 'leased') {
                    return;
                } else {
                    this.$store.dispatch('navigationStore/openSlideOut', {
                        component: 'unit',
                        props: {
                            unit_id: data.id
                        }
                    });
                }
            },
            async viewLease(result) {
                if (this.showOnlySettingsNavigation) {
                    EventBus.$emit('HB-Navigation:Hide-Settings-Modal');
                }
                if(!(result?.national_account_id || (result?.Lease?.Tenants[0]?.Contact.national_account_id))){
                    this.close();
                }                                                
                if(this.isUnit(result)){
                    let tenantId = this.getPrimaryTenant(result);
                    let data = this.getNationalAccount(result);
                    if (tenantId && !data.national_account_id) {
                        this.goToContacts(tenantId, result?.property_id);
                    } else if(data.national_account_id && result?.search_type != 'unit'){
                        this.goToNationalAccount(data.contact_id,data.national_account_id)
                    }
                    else if(data.national_account_id && result?.search_type == 'unit'){
                        this.goToNationalAccountProperty(data.contact_id,data.national_account_id, result)
                    }
                    else if (!result.Lease) {
                        this.viewUnit(result);
                    }
                }
                else if(result.Pending.length && result.Pending[0].status == 2 && !result.national_account_id) {
                    //TODO add loading here
                    let propertyInfo = this.properties.find(p => p.id === result.Pending[0].Unit.property_id);
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', propertyInfo);
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: result.Pending[0].unit_id });
                    this.actionFollowUpStatus(true) // add follow up when its pending
                    EventBus.$emit('new_lead');    
                } 
                else if (result.status.toLowerCase() == 'active lead' || result.status.toLowerCase() == 'retired lead' || ((result.Reservations.length && result.Reservations[0].status == 0))) {
                    let unit_id = (result.Reservations.length && result.Reservations[0].unit_id) ? result.Reservations[0].unit_id : null;
                    this.fetchReservationData(result.id, unit_id);
                } else if ((result.status == 'current' || result.status == 'pending') &&  result.Leases.length == 0) {
                    this.fetchPendingLead(result.id);
                } else if (result.national_account_id){
                    this.goToNationalAccount(result.id,result.national_account_id)
                } else {
                    this.goToContacts(result.id);
                }
            },
            isUnit(object){
               return object.hasOwnProperty('label');
            },
            getPrimaryTenant(result){
                let tenant;
                if(result.Lease && result.Lease.Tenants)
                    tenant = result.Lease.Tenants.find(tenant => tenant.primary === 1);
                return tenant && tenant.contact_id ? tenant.contact_id : null; 
            },
            getNationalAccount(result){
                let na;
                if(result.Lease && result.Lease.Tenants)
                    na = result.Lease.Tenants.find(tenant => tenant.primary === 1);
                return {national_account_id :na && na.Contact.national_account_id ? na.Contact.national_account_id : null , contact_id: na.contact_id}; 
            },
            goToContacts(id, property_id){
                let path = `/contacts/${id}`
                if(property_id) path+=`?property_id=${property_id}`
                if (this.$route.path !== path) this.$router.push(path);
                this.close();
            },
            goToNationalAccount(id, national_account_id){
                if(!this.hasPermission("manage_national_accounts")){
                   this.showNoPermissionDialogNationalAccountManage = true
                   return
                }                
                let path = `/national-account/${id}?na_id=${national_account_id}&dynamicRun=true`
                if (this.$route.path !== path) this.$router.push(path);
                this.close();
            },
            goToNationalAccountProperty(id, national_account_id, res){
                let path = `/national-account/${id}/account-profile?na_id=${national_account_id}&prop_id=${res?.property_id}`
                this.$router.push(path);
                this.close();
            },
            async fetchPendingLead(contactID) {
                await api.get(this, api.CONTACTS + contactID).then(async r => {
                    if(r.contact.Pending.length && r.contact.Pending[0]) {
                        var pending = r.contact.Pending[0];
                        await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id == pending.Unit.property_id));
                        await this.$store.dispatch('onBoardingStore/getPending', { unit_id: pending.unit_id });
                    } 
                    EventBus.$emit('new_lead');
                });
            },
            viewReservation(r){
                if (this.showOnlySettingsNavigation) {
                    EventBus.$emit('HB-Navigation:Hide-Settings-Modal');
                }
                this.close();
                var data = {};
                
                data.id = r.Lease.Lead.id;
                data.contact_id = r.Lease.Tenants[0].Contact.id;
                data.lease_first = r.Lease.Tenants[0].Contact.first;
                data.lease_last = r.Lease.Tenants[0].Contact.last;
                data.expires = r.Lease.Reservation.expires;
                data.lease_id = r.Lease.Reservation.lease_id;
                data.reservation_id = r.Lease.Reservation.id;
                data.reservation_time = r.Lease.Reservation.created;
                data.unit_id = r.id;
                data.event = null;
                data.start_date = r.Lease.startDate;
                EventBus.$emit('HB-Navigation:LeadFollowUp', data);
            },
            async viewPending(r){
                
                if (this.showOnlySettingsNavigation) {
                    EventBus.$emit('HB-Navigation:Hide-Settings-Modal');
                }
                this.close();
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === r.property_id));
                await this.$store.dispatch('onBoardingStore/getPending', { unit_id: r.id });
                EventBus.$emit('new_lead');
            },

            async fetchReservationData(contactID, unitID, reservation, startDate) {
                
                await api.get(this, api.CONTACTS + contactID).then(r => {
                    
                    var contact = r.contact;
                    var data = {
                        id: contact.Lead?.id, 
                        contact_id: contact.id,
                        lead_first: contact.first,
                        lead_last: contact.last,
                    }
                    if(contact.Lead){
                        data.lead_time = contact.Lead.created;
                    }
                    if(unitID && contact.Reservations){
                        contact.Reservations.forEach( reservation => {
                            if (reservation.Lease && reservation.Lease.Unit && reservation.Lease.Unit.id == unitID) {
                                data.expires = reservation.expires;
                                data.lease_id = reservation.Lease.id;
                                data.reservation_id = reservation.id;
                                data.reservation_time = reservation.created;
                                data.unit_id = reservation.Lease.Unit.id;
                            }
                        });
                    } else if(contact.Reservations.length){
                        data.expires = contact.Reservations[0].expires;
                        data.lease_id = contact.Reservations[0].Lease.id;
                        data.reservation_id = contact.Reservations[0].id;
                        data.reservation_time = contact.Reservations[0].created;
                        data.unit_id = contact.Reservations[0].Lease.Unit.id;
                    }
                    EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                });
            },
            checkAndView(r){
                const { STATUSES } = LEASE;
                const { DOOR_STATUSES } = UNITS;
                const state = r.state.toLowerCase()
                switch (state) {
                    case DOOR_STATUSES.REMOVE_OVERLOCK:
                        this.viewLease(r)
                        break;
                    case DOOR_STATUSES.TO_OVERLOCK:
                        this.viewLease(r)
                        break;
                    case DOOR_STATUSES.OVERLOCKED:
                        this.viewLease(r)
                        break;
                    case STATUSES.LEASED:
                        this.viewLease(r)
                        break;
                    case STATUSES.RESERVED:
                        this.viewReservation(r)
                        break;
                    case STATUSES.PENDING:
                        this.viewPending(r)
                        break;
                    case STATUSES.CLOSED:
                        this.viewUnit(r)
                        break;
                   case STATUSES.OFFLINE:
                        this.viewUnit(r)
                        break;
                    default:
                        break;
                }
            },
            getPhoneNumber(lease){
                if(lease && lease.Tenants && lease.Tenants.length > 0 && lease.Tenants[0].Contact && lease.Tenants[0].Contact.Phones && lease.Tenants[0].Contact.Phones.length > 0){
                    return this.$options.filters.formatPhone(lease.Tenants[0].Contact.Phones && lease.Tenants[0].Contact.Phones[0].phone)
                }else{ return ''} 
            }
        },
        watch:{
            '$route' (to, from) {
                this.away();
            },
            right_column_view(tab){
                if(this.activeDrawerComponent.type === 'show_omni_Search'){
                    this.setActiveDrawerComponent({type:'show_omni_Search', search: this.params.search, right_column_view: tab  });
                }
            }
        }

    }


</script>

<style lang="scss" >
    @media(max-width: 600px) {
        .hb-blade-header-wrapper-title {
            font-size: 17px !important
        }
        .hb-icon-large-close-margin-fix i.v-icon{
            font-size: 30px !important;
        }
        .omni-search-heading  {
            .hb-blade-header-card-wrapper div:nth-child(1) {
                justify-content: flex-start !important;
            }
        }

    }
    .results-container{
      max-height: 67vh;
      max-height: 67svh;
      overflow: auto;
    }
    .omni-search-result-item{
        flex: 1;
        overflow-y: auto;
    }
    .omni-search-result-container{
        display: flex;
        flex-direction: column;
        height: 80vh;
        height: 80svh;
        overflow-y: hidden;
    }

    .contact-space-pointer {
        cursor: pointer;
    }
    .result-item-border {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
      min-width: -webkit-fill-available;
    }
    .result-item:hover {
        background: #fbfbfb;
        cursor: pointer;
    }
    ul.omni-search-category {
        list-style:none;
        padding: 0;
        margin:0;
        background: #f5f7f8;
        border-top: 1px solid #dce8ef;
        border-bottom: 1px solid #dce8ef;
    }
    ul.omni-search-category li:hover,
    ul.omni-search-category li.selected {
        background: #e6eaec;
    }

    ul.omni-search-category li {
        background: #f0f0f5;
        float: left;
        padding: 10px 20px;
        color: #00b2ce;
        border-right: 2px solid #dce8ef;
        font-weight: 300;
        cursor: pointer;
    }

    ul.omni-search-results{
        list-style:none;
        padding: 0;
        margin:0;
        background: white;
    }

    ul.omni-search-results li{
        padding: 13px 20px;
        font-weight: 300;
        cursor: pointer;
        background: white;
        border-bottom: 1px solid #dce8ef;
    }

    ul.omni-search-results li:hover{
        background: #f2f9fd;
    }

    .omni-search-field{
        margin-bottom: 20px !important;
    }

    .omni-search-field .icon{
        position: absolute;
        font-size: 14px;
        /* color: #e2e2e2; */
        color: #6f6767;
        top: 16px;
        left: 20px;
    }

    .omni-search-field{
        border: 0;
        margin: 0;
        height: 50px;
    }
    .searchbox{
        outline: none;
        border: 0;
        padding: 0px;
        margin: 0;
        height: 40px;
        color: #101318;
    }
    .noscroll{
        position: fixed;
        overflow: hidden;
    }


    .omni-search-container {
        padding: 0;
        background-color: #fff;
        border-radius: 2px;
        transition: all .3s ease;
        position: relative;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        outline: 0;
        display: flex;
        flex-direction: column;

    }

    .omni-search-header h3 {
        margin-top: 0;
        color: #00b2ce;
    }

    .omni-search-enter .omni-search-container,
    .omni-search-leave-active .omni-search-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .omni-search-container.modal-blank{
        padding:0;
        box-shadow: none;
    }

    .omni-search-sheet {
        color: #928d8d;
        margin-left: 6px;
        font-size: 14px;
        padding-top: 2px;
    }
    .omni-search-underline-text {
        color: #928d8d;
    }
    .border-radius-zero{
        border-radius: 0px;
    }
    .highlighted-text {
        color: #306FB6;
    }

    /*.status {*/
    /*    border: 1px solid #e2e2e2;*/
    /*    -webkit-border-radius: 20px;*/
    /*    -moz-border-radius: 20px;*/
    /*    border-radius: 20px;*/
    /*    padding: 10px 10px 10px 25px;*/
    /*    line-height: 7px;*/
    /*    font-size: 14px;*/
    /*    display: inline-block;*/
    /*    position: relative;*/
    /*    text-transform: capitalize;*/
    /*}*/
    /*.status:before {*/
    /*    content: "\f111";*/
    /*    font-family: 'Fa solid 900';*/
    /*    left: 2px;*/
    /*    position: absolute;*/
    /*    top: 5px;*/
    /*    font-size: 11px;*/
    /*}*/
    /*.status.status_lead {*/
    /*    color: #306FB6;*/
    /*    border-color: #306FB6;*/
    /*}*/
    /*.status.status_leased {*/
    /*    color: #15B323;*/
    /*    border-color: #15B323;*/
    /*}*/
    /*.status.status_vacated {*/
    /*    color: #555;*/
    /*    border-color: #555;*/
    /*}*/
    /*.status.status_available {*/
    /*    color: #D91E18;*/
    /*    border-color: #D91E18;*/
    /*}*/


    /* @media (max-width: 600px) {

        .omni-search-container {
            max-width: 500px;
            margin: auto;
            border-radius: 2px;

        }
    }
    @media (min-width: 600px) {
        .omni-search-container {
            max-width: 500px;
            margin: auto 44px;
        }
    }
    @media (min-width: 992px) {
        .omni-search-lg {
            width: 800px;
        }
        .omni-search-padding {
            padding: 15px;
        }
    } */

    .clearfix:before,
    .clearfix:after,
    .omni-search-header:before,
    .omni-search-header:after,
    .omni-search-footer:before,
    .omni-search-footer:after {
        content: " ";
        display: table;
    }
    .clearfix:after,
    .omni-search-header:after,
    .omni-search-footer:after {
        clear: both;
    }
    .omni-search-title{
        font-size: 16px;
        font-weight: 600
    }


    .omni-search-title{
        font-size: 16px;
        font-weight: 600
    }

</style>
