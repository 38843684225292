export const Tokens = [
  
    // {
    //   label: "Tenant First",
    //   token: "{d.Tenant.FirstName}",
    //   type: 'token',
    //   category: 'Tenant',
    // },
    // {
    //   label: "Tenant Last",
    //   token: "{d.Tenant.LastName}",
    //   type: 'token',
    //   category: 'Tenant'
    // },
    // {
    //   label: "Signature",
    //   token: "{d.Signature}",
    //   type: 'signature',
    //   category: 'Signing'
    // },
    // {
    //   label: "Initials",
    //   token: "{d.Initials}",
    //   type: 'initials', 
    //   category: 'Signing'
    // },
        {
          "category": "Agreement",
          "token": "{d.Agreement.Name}", 
          "label": "Agreement Name",
          "type": "token"
        },
        {
          "category": "Agreement",
          "token": "{d.Tenant.LeaseSignDate}",
          "label": "Lease Signed Date",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.Auction.CleaningDeposit}",
          "label": "Auction Cleaning Deposit",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.Auction.EndDate}",
          "label": "Auction End Date",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.Auction.FinalBidAmt}",
          "label": "Auction Final Bid Amount",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.Auction.SaleDate}",
          "label": "Auction Sale Date",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.Auction.SaleDatePlus3}",
          "label": "Auction Sale Date +3",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.PostAuction.FinalBalDue}",
          "label": "Balance Due After Auction",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.Tenant.AuctionDate}",
          "label": "Auction Date",
          "type": "token"
        },
        {
          "category": "Auction",
          "token": "{d.Tenant.AuctionTime}",
          "label": "Auction Time",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Lease.DelinquencyDaysLate}",
          "label": "Delinquency Days Late",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Lease.DelinquencyStartDate}",
          "label": "Delinquency Start Date",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Lease.PaymentCycle}",
          "label": "Payment Cycle",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Lease.PaymentCycleRent}",
          "label": "Rent Payment Cycle",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Tenant.TotalDue}",
          "label": "Total Rent Due",
          "type": "token"
        },
        {
          "category": "Billing Info",
          "token": "{d.Tenant.CardBusinessName}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Billing Info",
          "token": "{d.Tenant.CardExpDate}",
          "label": "Credit Card Expiration Date",
          "type": "token"
        },
        {
          "category": "Billing Info",
          "token": "{d.Tenant.CardName}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Billing Info",
          "token": "{d.Tenant.CardNumber}",
          "label": "Credit Card Number",
          "type": "token"
        },
        {
          "category": "Billing Info",
          "token": "{d.Tenant.CCExpDate}",
          "label": "Credit Card Expiration Date",
          "type": "token"
        },
        {
          "category": "Billing Info",
          "token": "{d.Tenant.CCLast4}",
          "label": "Last 4 Digits of Credit Card",
          "type": "token"
        },
        {
          "category": "Billing Info",
          "token": "{d.Tenant.CCName}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.CellPhone}",
          "label": "Tenant Cell Phone",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.City}",
          "label": "Tenant City",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Tenant.LatestInvoice}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Tenant.LatestReceipt}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.LeaseChangeDate}",
          "label": "Lease Change Date",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Tenant.MonthlyCharge}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Billing and Delinquency",
          "token": "{d.Tenant.MonthlyCost}",
          "label": "Monthly Cost",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessName}",
          "label": "Tenant's Business Name",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativeAddress1}",
          "label": "Business Representative Address Line 1",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativeAddress2}",
          "label": "Business Representative Address Line 2",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativeCity}",
          "label": "Business Representative City",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativeCountry}",
          "label": "Business Representative Country",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativeEmail}",
          "label": "Business Representative Email",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativeName}",
          "label": "Business Representative Name",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativePhone}",
          "label": "Business Representative Phone Number",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativePostalCode}",
          "label": "Business Representative Postal Code",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.BusinessRepresentativeState}",
          "label": "Business Representative State",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.Company}",
          "label": "Tenant's Company",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyCity}",
          "label": "Tenant's Company City",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyEmail}",
          "label": "Tenant's Company Email",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyAddress1}",
          "label": "Tenant's Company Address Line 1",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyAddress2}",
          "label": "Tenant's Company Address Line 2",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyName}",
          "label": "Tenant's Company Name",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyCountry}",
          "label": "Tenant's Company Country",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyPostalCode}",
          "label": "Tenant's Company Postal Code",
          "type": "token"
        },
        {
          "category": "Business Tenant",
          "token": "{d.Tenant.CompanyState}",
          "label": "Tenant's Company State",
          "type": "token"
        },
        {
          "category": "Coverage and Insurance",
          "token": "{d.Tenant.InsuranceCompanyName}",
          "label": "Tenant Insurance Name",
          "type": "token"
        },
        {
          "category": "Coverage and Insurance",
          "token": "{d.Tenant.InsuranceExpDate}",
          "label": "Tenant Insurance Expiration Date",
          "type": "token"
        },
        {
          "category": "Coverage and Insurance",
          "token": "{d.Tenant.InsurancePolicyNo}",
          "label": "Tenant Insurance Policy Number",
          "type": "token"
        },
        {
          "category": "Coverage and Insurance",
          "token": "{d.Tenant.InsurancePremium}",
          "label": "Tenant Insurance Premium",
          "type": "token"
        },
        {
          "category": "Document Info",
          "token": "{d.Document.CreatedDate}",
          "label": "Document Created Date",
          "type": "token"
        },
        {
          "category": "Document Info",
          "token": "{d.Document.Date}",
          "label": "Document Date",
          "type": "token"
        },
        {
          "category": "Document Info",
          "token": "{d.Document.Notice1Date}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Document Info",
          "token": "{d.Document.RefNumber}",
          "label": "Document Reference Number",
          "type": "token"
        },
        {
          "category": "Document Info",
          "token": "{d.Document.Time}",
          "label": "",
          "type": "token"
        },
        // {
        //   "category": "Facility",
        //   "token": "{d.// Tenant.UnitID, Facility.UnitID}",
        //   "label": "",
        //   "type": "token"
        // },
        {
          "category": "Facility",
          "token": "{d.Facility.Address1}",
          "label": "Property Address Line 1",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Address2}",
          "label": "Property Address Line 2",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.AdvertisingFee}",
          "label": "Property Advertising Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.AfterHoursPhone}",
          "label": "Property After-Hours Phone Number",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.City}",
          "label": "Property City",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.CleaningFee}",
          "label": "Property Cleaning Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Country}",
          "label": "Property Country",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Email}",
          "label": "Property Email Address",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Fax}",
          "label": "Property Fax Number",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.KeyDepositFee}",
          "label": "Property Deposit Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.LateFee}",
          "label": "Property Late Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.LegalName}",
          "label": "Property Legal Name",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.LienNoticeFee}",
          "label": "Property Lien Notice Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.LienSaleChargeFee}",
          "label": "Property Lien Sale Charge Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.LienSaleFee}",
          "label": "Property Lien Sale Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.LockCutFee}",
          "label": "Property Lock Cut Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Name}",
          "label": "Property Name",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.NoticeOfLienSaleFee}",
          "label": "Property Notice of Lien Sale Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Phone}",
          "label": "Property Phone Number",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.PostalCode}",
          "label": "Property Postal Code",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.PublicAuctionFee}",
          "label": "Property Public Auction Fee",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.SalesTax}",
          "label": "Property Sales Tax",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.SecurityDeposit}",
          "label": "Property Security Deposit",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.State}",
          "label": "Property State",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.TotalPastDue}",
          "label": "Total Past Due Amount",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.UnitSize}",
          "label": "Property Unit Size",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.UnitType}",
          "label": "Property Unit Type",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Website}",
          "label": "Property Website",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.WebURL}",
          "label": "Property Website URL",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Facility.Zipcode}",
          "label": "Property Zip Code",
          "type": "token"
        },
        {
          "category": "Facility",
          "token": "{d.Storsmart.FacilityNumber}",
          "label": "Storsmart Facility Number",
          "type": "token"
        },
        {
          "category": "Coverage and Insurance",
          "token": "{d.Insurance.PremiumCoverage}",
          "label": "Tenant Insurange Premium Coverage",
          "type": "token"
        },
        {
          "category": "Coverage and Insurance",
          "token": "{d.Insurance.ServiceStartDate}",
          "label": "Tenant Insurance Start Date",
          "type": "token"
        },
        // {
        //   "category": "Lease",
        //   "token": "{d.// Facility.RentDueDate, Facility.RentDueDateWithSuffix, Tenant.RentDueDate}",
        //   "label": "",
        //   "type": "token"
        // },
        // {
        //   "category": "Lease",
        //   "token": "{d.// Tenant.Rate, Tenant.Rent, Facility.RentalRate , Facility.Rate, Space.Rent}",
        //   "label": "",
        //   "type": "token"
        // },
        {
          "category": "Lease",
          "token": "{d.Tenant.MoveInDate}",
          "label": "Move-in Date",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.MoveInDiscount}",
          "label": "Move-in Discount",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.MoveInRetailCost}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.MoveOutDate}",
          "label": "Move-out Date",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.NewLeaseTerms.LT1}",
          "label": "New Lease Terms",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.NewMonthlyCharge}",
          "label": "New Monthly Charge",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.NewRate}",
          "label": "New Rate",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.NewRateDate}",
          "label": "New Rate Date",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.NewRentWithTax}",
          "label": "New Rent with Tax",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.NextRentDueDate}",
          "label": "Next Rent Due Date",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.PastDue.PT1}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.PayLink}",
          "label": "Payment Link",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.Promo}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.ProratedInsurancePremium}",
          "label": "Prorated Insurance Premium",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.ProratedRent}",
          "label": "Prorated Rent",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.PTDPlus1}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.RentPaidThruDate}",
          "label": "Rent Paid Through Date",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.RentPastDueDays}",
          "label": "Rent Past Due Days",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.RentWithTax}",
          "label": "Rent with Tax Included",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.ScheduledCharges.PT2}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.StoredContents}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.TotalMoveInCost}",
          "label": "Total Move-in Cost",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.Lien.No}",
          "label": "No Lien",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.Lien.Yes}",
          "label": "Has Lien",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienAmount}",
          "label": "Lien Amount",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderAddress1}",
          "label": "Lienholder Address Line 1",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderAddress2}",
          "label": "Lienholder Address Line 2",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderCity}",
          "label": "Lienholder City",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderCountry}",
          "label": "Lienholder Country",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderDetails}",
          "label": "Lienholder Details",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderEmail}",
          "label": "Lienholder Email",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderFirstName}",
          "label": "Lienholder First Name",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderLastName}",
          "label": "Lienholder Last Name",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderName}",
          "label": "Lienholder Name",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderPhone}",
          "label": "Lienholder Phone Number",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderPostalCode}",
          "label": "Lienholder Postal Code",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienHolderState}",
          "label": "Lienholder State",
          "type": "token"
        },
        {
          "category": "Lienholder",
          "token": "{d.Tenant.LienProperty}",
          "label": "Lien Property",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryAddress1}",
          "label": "Military Address Line 1",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryAddress2}",
          "label": "Military Address Line 2",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryBranchName}",
          "label": "Military Branch Name",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryCity}",
          "label": "Military City",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryCommandingOfficer}",
          "label": "Commanding Officer",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryContactEmail}",
          "label": "Military Contact Email",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryContactPhone}",
          "label": "Military Contact Phone Number",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryCountry}",
          "label": "Military Country ",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryDeploymentBase}",
          "label": "Military Deployment Base",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryEmail}",
          "label": "Military Email",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryETSDate}",
          "label": "Military ETS Date",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryID}",
          "label": "Military ID",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryPhone}",
          "label": "Military Phone Number",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryPostalCode}",
          "label": "Military Postal Code",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryRank}",
          "label": "Military Rank",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryState}",
          "label": "Military State",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.MilitaryUnitName}",
          "label": "Military Unit Name",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.ServicememberName}",
          "label": "Servicemember Name",
          "type": "token"
        },
        {
          "category": "Recipient",
          "token": "{d.Recipient.address}",
          "label": "Recipient Address",
          "type": "token"
        },
        {
          "category": "Recipient",
          "token": "{d.Recipient.address2}",
          "label": "Recipient Address Line 2",
          "type": "token"
        },
        {
          "category": "Recipient",
          "token": "{d.Recipient.country}",
          "label": "Recipient Country",
          "type": "token"
        },
        {
          "category": "Recipient",
          "token": "{d.Recipient.name}",
          "label": "Recipient Name",
          "type": "token"
        },
        {
          "category": "Recipient",
          "token": "{d.Recipient.PostalCode}",
          "label": "Recipient Postal Code",
          "type": "token"
        },
        {
          "category": "Recipient",
          "token": "{d.Recipient.state}",
          "label": "Recipient State",
          "type": "token"
        },
        {
          "category": "Space",
          "token": "{d.ReversalSpace.ID}",
          "label": "Reversal Space ID",
          "type": "token"
        },
        {
          "category": "Space",
          "token": "{d.Space.ID}",
          "label": "Space ID",
          "type": "token"
        },
        {
          "category": "Space",
          "token": "{d.Space.Rate}",
          "label": "Space Rate",
          "type": "token"
        },
        {
          "category": "Space",
          "token": "{d.Space.Size}",
          "label": "Space Size",
          "type": "token"
        },
        {
          "category": "Space",
          "token": "{d.Space.Type}",
          "label": "Space Type",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Storing.SensitiveData}",
          "label": "Storing Sensitive Data",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.Access1Name}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.Access1Phone}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.AccountType}",
          "label": "Tenant Account Type",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.ActiveMilitary}",
          "label": "Military Status",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.ActiveMilitary.No}",
          "label": "Not Active Military",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.ActiveMilitary.Yes}",
          "label": "Active Military",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.ActiveMilitaryNo}",
          "label": "Not Active Military",
          "type": "token"
        },
        {
          "category": "Military Info",
          "token": "{d.Tenant.ActiveMilitaryYes}",
          "label": "Active Military",
          "type": "token"
        },
        {
          "category": "Alternate Contact",
          "token": "{d.Tenant.AuthorizedAccessPersons}",
          "label": "Authorized Access Persons",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.Discount}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.DLExpDate}",
          "label": "Driver License Expiration Date",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.DLNumber}",
          "label": "Driver License Number",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.DLProvince}",
          "label": "Driver License Province",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.DLState}",
          "label": "Driver License State",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.FirstName}",
          "label": "Tenant First Name",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.FutureMoveInCost}",
          "label": "Future Move-in Cost",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.GateAccessCode}",
          "label": "Gate Access Code",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.GateCode}",
          "label": "Gate Code",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.LastName}",
          "label": "Tenant Last Name",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.LeaseNo}",
          "label": "Lease Number",
          "type": "token"
        },
        {
          "category": "Lease",
          "token": "{d.Tenant.MerchandiseCost}",
          "label": "Merchandise Cost",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.MoveInCost}",
          "label": "Move-in Cost",
          "type": "token"
        },
        {
          "category": "Tenant",
          "token": "{d.Tenant.Title}",
          "label": "Tenant Title",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankAccountNo}",
          "label": "Bank Account Number",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankAccountNum}",
          "label": "Bank Account Number",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankBranch}",
          "label": "Bank Branch",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankCity}",
          "label": "Bank City",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankName}",
          "label": "Bank Name",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankPostalCode}",
          "label": "Bank Postal Code",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankRoutingNo}",
          "label": "Routing Number",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankRoutingNum}",
          "label": "Routing Number",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BankState}",
          "label": "Bank State",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BillingAddress1}",
          "label": "Billing Address Line 1",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BillingAddress2}",
          "label": "Billing Address Line 2",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BillingCity}",
          "label": "Billing City",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BillingCountry}",
          "label": "Billing Country",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BillingPostalCode}",
          "label": "Billing Postal Code",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.BillingState}",
          "label": "Billing State",
          "type": "token"
        },
        {
          "category": "Tenant Billing Info",
          "token": "{d.Tenant.TaxpayerID}",
          "label": "Taxpayer ID",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.Address1}",
          "label": "Tenant Address Line 1",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.Address2}",
          "label": "Tenant Address Line 2",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AdminFee}",
          "label": "Tenant Admin Fee",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltAddress1}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltAddress2}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltCellPhone}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltCity}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltCountry}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltEmail}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltHomePhone}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltName}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltPhone}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltPostalCode}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltState}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.AltWorkPhone}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.Country}",
          "label": "Tenant Country",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.HomePhone}",
          "label": "Tenant Home Phone",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.Name}",
          "label": "Tenant Name",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.Phone}",
          "label": "Tenant Phone Number",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.PostalCode}",
          "label": "Tenant Postal Code",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.SSN}",
          "label": "Tenant Social Security Number",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.State}",
          "label": "Tenant State",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.StreetAddress}",
          "label": "Tenant Street Address",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.Email}",
          "label": "Tenant Email",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.EmployerAddress1}",
          "label": "Employer Address Line 1",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.EmployerAddress2}",
          "label": "Employer Address Line 2",
          "type": "token"
        },
        {
          "category": "Tenant Contact Info",
          "token": "{d.Tenant.EmployerCellPhone}",
          "label": "Employer Cell Phone",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.EmployerCity}",
          "label": "Tenant Employer City",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.EmployerCountry}",
          "label": "Tenant Employer Country",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.EmployerEmail}",
          "label": "Tenant Employer Email",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.EmployerName}",
          "label": "Tenant Employer Name",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.EmployerPostalCode}",
          "label": "Tenant Employer Postal Code",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.EmployerState}",
          "label": "Tenant Employer State",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.EmployerWorkPhone}",
          "label": "Tenant Employer Work Phone",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.WorkEmail}",
          "label": "Tenant Work Email",
          "type": "token"
        },
        {
          "category": "Tenant Employer",
          "token": "{d.Tenant.WorkPhone}",
          "label": "Tenant Work Phone",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.BoatHomePort}",
          "label": "Boat Home Port",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.BoatHorsePower}",
          "label": "Boat Horse Power",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.BoatLength}",
          "label": "Boat Length",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.BoatName}",
          "label": "Boat Name",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.BoatRegistrationNo}",
          "label": "Boat Registration Number",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.BoatSerialNo}",
          "label": "Booat Serial Number",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.IfBoatAddressDifferent}",
          "label": "Boat Address (if different)",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.IfTrailerAddressDifferent}",
          "label": "Trailer Address (if different)",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.IfVehicleAddressDifferent}",
          "label": "Vehicle Address (if different)",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.MotorDocumentNo}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.MotorInboardorOutboard}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.MotorLienHolderDetails}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.MotorManufacturer}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.MotorVINNo}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.MotorYear}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.StoreVehicleNo}",
          "label": "Tenant Is Not Storing A Vehicle",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.StoreVehicleYes}",
          "label": "Tenant Is Storing A Vehicle",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerApproxValue}",
          "label": "Trailer Approximate Value",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerExpiration}",
          "label": "Trailer Registration Expiration Date",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerInsPolicyNumber}",
          "label": "Trailer Insurance Policy Number",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerInsuranceProvider}",
          "label": "Trailer Insurance Provider",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerLicensePlateNo}",
          "label": "Trailer License Plate Number",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerLienHolderDetails}",
          "label": "Trailer Lien Holder Details",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerRegistrationCountry}",
          "label": "Trailer Registration Country",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerRegistrationState}",
          "label": "Trailer Registration State",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.TrailerVIN}",
          "label": "Trailer VIN",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleApproxValue}",
          "label": "Vehicle Approximate Value",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleAutomobileYes}",
          "label": "Tenant Is Storing A Car",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleBoatYes}",
          "label": "Tenant Is Storing A Boat",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleCarYes}",
          "label": "Tenant Is Storing A Car",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleExpiration}",
          "label": "Vehicle Registration Expiration Date",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleID}",
          "label": "Vehicle ID",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleIfOtherDesc}",
          "label": "Vehicle Description (if other)",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleInsProvider}",
          "label": "Vehicle Insurance Provider",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleLicense}",
          "label": "Vehicle License",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleLicPlateNo}",
          "label": "Vehicle License Plate Number",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleLienHolderDetails}",
          "label": "Vehicle Lien Holder Details",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleMake}",
          "label": "Vehicle Make",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleMakeModel}",
          "label": "Vehicle Model",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleManufacturer}",
          "label": "Vehicle Manufacturer",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleModel}",
          "label": "Tenant Vehicle Model",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleMotorcycleYes}",
          "label": "Tenant Is Storing A Motorcycle",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleMotorYes}",
          "label": "",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleOtherYes}",
          "label": "Tenant Storing Another Type of Vehicle",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehiclePolicyNumber}",
          "label": "Vehicle Insurance Policy Number",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleRecreationalYes}",
          "label": "Tenant Storing A Recreational Vehicle",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleRegCountry}",
          "label": "Vehicle Registration Country",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleRegState}",
          "label": "Vehicle Registration State",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleTrailerLicense}",
          "label": "Vehicle Trailer License",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleTrailerYes}",
          "label": "Tenant Is Storing A Trailer",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleVIN}",
          "label": "Vehicle VIN",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Tenant.VehicleYear}",
          "label": "Vehicle Year",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.ApproxValue}",
          "label": "Vehicle Approximate Value",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.Color}",
          "label": "Vehicle Color",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.InsuranceProvider}",
          "label": "Vehicle Insurance Provider",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LegalOwnerAddress1}",
          "label": "Vehicle Legal Owner Address Line 1",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LegalOwnerAddress2}",
          "label": "Vehicle Legal Owner Address Line 2",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LegalOwnerCity}",
          "label": "Vehicle Legal Owner City",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LegalOwnerCountry}",
          "label": "Vehicle Legal Owner Country",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LegalOwnerName}",
          "label": "Vehicle Legal Owner Name",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LegalOwnerPostalCode}",
          "label": "Vehicle Legal Owner Postal Code",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LegalOwnerState}",
          "label": "Vehicle Legal Owner State",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.LicensePlateNo}",
          "label": "License Plate Number",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.Make}",
          "label": "Vehicle Make",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.Manufacturer}",
          "label": "Vehicle Manufacturer",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.Model}",
          "label": "Vehicle Model",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegOwnerAddress1}",
          "label": "Vehicle Registered Owner Address Line 1",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegOwnerAddress2}",
          "label": "Vehicle Registered Owner Address Line 2",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegOwnerCity}",
          "label": "Vehicle Registered Owner City",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegOwnerCountry}",
          "label": "Vehicle Registered Owner Country",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegOwnerName}",
          "label": "Vehicle Registered Owner Name",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegOwnerPostalCode}",
          "label": "Vehicle Registered Owner Postal Code",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegOwnerState}",
          "label": "Vehicle Registered Owner State",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.RegState}",
          "label": "Vehicle Registration State",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.VIN}",
          "label": "Vehicle VIN",
          "type": "token"
        },
        {
          "category": "Vehicle",
          "token": "{d.Vehicle.Year}",
          "label": "Vehicle Year",
          "type": "token"
        },
        {
          label: "Signature",
          token: "{d.d.Signature}",
          type: 'signature',
          category: 'Signing'
        },
        {
          label: "Initials",
          token: "{d.d.Initials}",
          type: 'initials', 
          category: 'Signing'
        },
]

  