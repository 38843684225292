<template>
    <div style="display: flex; height: 100%; flex-direction: column; align-items: stretch; border-right: 1px solid rgba(0, 0, 0, 0.12); ">
        <div style="flex: 1; " :ref="`editor_${identifier}`">
            <editor  :value="value" :api-key="apiKey" :init="editorConfig" @input="updateValue" />
        </div>

        <hb-modal   
            title="Insert Image"    
            v-model="show_add_image" 
            size="medium"
            content-padding
        >
            <template v-slot:content>
                <hb-form label="Upload Image" required>
                    <HbFileInput
                        v-model="image.src"
                        v-validate="'required'"
                        data-vv-scope="default"
                        data-vv-name="file_input"
                        data-vv-as="Image File"
                        :error="errors.has('default.file_input')"
                    />
                </hb-form>
                <hb-form label="Alt Text" required>
                     <HbTextField
                        v-model="image.alt"
                        v-validate="'required|max:45|email'"
                        data-vv-scope="default"
                        data-vv-name="alt_text"
                        data-vv-as="Alt Text"
                        :error="errors.has('default.alt_text')"
                    />
                </hb-form>
            </template>
            <template v-slot:right-actions>
                <hb-btn 
                    color="primary" 
                    @click="setImage"
                >
                    Insert Image
                </hb-btn>       
            </template>
        </hb-modal>
    </div>
    <!-- <editor :value="value" :api-key="apiKey" :init="editorConfig" @input="updateValue" /> -->

</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { v4 as uuidv4 } from 'uuid';
import { MergeFields } from './MergeFields';
// const DraggableSection = () => import('../settings/CommunicationTemplates/TemplateManager/DraggableSection.vue');
// import { MergeFields } from '../settings/CommunicationTemplates/TemplateManager/MergeFields';
import { Tokens } from './Tokens';

// wordcount plugin
export default {
    name: "MceEditor",
    data() {
        return {
            show_add_image: false,
            image: {
                src: '', 
                alt_text: ''
            }, 
            value: "", 
            identifier:null, 
            showTags:false,
            apiKey: process.env.VUE_APP_TINYMCE_FREE_API_KEY,
            // toolbars:{
            //     basic: [],
            //     communication: ['image code undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'], 
            //     document: ['formatselect bold  italic underline strikethrough fontselect fontsizeselect fontsizeinput align numlist bullist lineheight table pagebreak mergefields']
            // },
            // plugins: { 
            //     basic: [],
            //     communication: ['autolink fullpage lists link image anchor preview','code','insertdatetime media paste code wordcount noneditable importcss'],
            //     document: ['lists', 'advlist',  'link', 'image', 'pagebreak', 'table', 'paste'],
            // },
            editorProps: {
                communication: {
                    height: 700,
                    plugins: [
                        'autolink fullpage lists link image anchor preview',
                        'code',
                        'insertdatetime media paste code wordcount noneditable importcss',
                    ],
                    toolbar: 'image code undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent insertImage preview mergefields',
                    menubar: false,
                    resize: true,
                    removed_menuitems: 'table',
                    toolbar_mode: 'floating'
                },
                document: {
                    height: '100%',
                    plugins: [ 'lists', 'advlist',  'link', 'image', 'pagebreak', 'table', 'paste'],
                    toolbar: 'formatselect bold  italic underline strikethrough fontselect fontsizeselect fontsizeinput align numlist bullist lineheight table pagebreak insertImage mergefields',
                    menubar: false,
                    resize: false,
                    removed_menuitems: 'table',
                    toolbar_mode: 'floating'
                }
            }
        }
    },
    components: { 
        Editor
        // HbModal, 
        // DraggableSection 
    },
    props: {
        template: String,
        height: {
            type: String,
            default: "100%",
        },
        // plugins: {
        //     type: Array,
        //     default: () => [
        //         'autolink fullpage lists link image anchor preview, pagebreak',
        //         'code',
        //         'insertdatetime media table paste code noneditable importcss',
        //     ],
        // },
        // Possible values: true, false, or string of toolbar
        // toolbar: {
        //     type: [Boolean, String],
        //     default: true
        // },
        // Possible values: true, false, or string of menus
        menubar: {
            type: [Boolean, String],
            default: false,
        },
        show_word_count: {
            type: Boolean,
            default: false
        }, 
        // Possible values: true, false, 'both'
        resize: {
            type: [Boolean, String],
            default: true
        },
        // Type: String 
        removed_menuitems: {
            type: String,
            default: 'inserttable,cell,row,column,tableprops,deletetable'
        },
        document_type: {
            type: String,
            default: 'communication'
        }
    },
    created(){
        this.identifier = uuidv4();
        if(this.$props.template){
            this.loadTemplate(this.$props.template);
        }
    },
    // destroyed(){
    //     let container = this.refs[`editor_${this.identifier}`];
    //     container.removeEventListener( "click", this.onMouseMove ); 
    // }, 
    computed: {
        editorConfig() {
            return {
                
                image_title: true,
                block_unsupported_drop: true,
                file_picker_types: 'image',
                file_picker_callback: this.handleFilePicker,
                height: this.editorProps[this.$props.document_type].height,
                branding: false,
                elementpath: false,
                element_format: 'html',
                editable_class: 'mceEditable',
                custom_elements: 'style, head , meta, html , title',
                object_resizing: true,
                table_sizing_mode: 'responsive',
                invalid_elements: 'script',
                content_css: ['default', this.$props.document_type == 'document' ? '/css/editor_styles.css' : '' ], 
                resize: false, 
                statusbar: false, 
                contextmenu:false, 
                noneditable_class: 'mceNonEditable',
                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt', 
                line_height_formats: '1 1.2 1.4 1.6 2', 
                font_size_input_default_unit: 'pt',
                // removed_menuitems : this.removed_menuitems,
                // menu: { file: { title: 'File', items: 'preview' }},
                menubar: this.editorProps[this.$props.document_type].menubar,
                plugins:  this.editorProps[this.$props.document_type].plugins,
                toolbar_mode: this.editorProps[this.$props.document_type].toolbar_mode, 
                toolbar: this.editorProps[this.$props.document_type].toolbar,
                setup: this.editorSetup,
                // content_style: '.mceNonEditable { border: 3px solid red; padding: 8px; }',
            };
        }
    },
    methods: {

        
        onClose() { this.showTags = false; },
        onMouseMove(){
 
        
        }, 
        loadTemplate(){

            this.value = this.$props.template.replace(/\{d\.[^}]+\}/g, (match, key) => {
                console.log("match", match)
                if(match === '{d.Signature}') return `/img/tppx.png`;
                if(match === '{d.Initials}') return `/img/tppx.png`;
                console.log("this.$props.document_type", this.$props.document_type)
                if(this.$props.document_type == 'document'){
                    let token = Tokens.find(mf => mf.token === match); 
                    if(token){
                        return `<span contenteditable="false" data-token-type="${token.type}" data-id="${ uuidv4() }" data-value="${token.token}" data-friendly="${token.label}" class="replaced-text ${token.type}">${token.label}</span>`
                    } 
                } 
                return match;
            });

            this.value = this.value.replace('<br style="page-break-before: always;" />', '<!-- pagebreak -->'); 

        }, 
        
        getContent(){
            let is_signature = false;
            let tmp_content = tinymce.activeEditor.getContent();
            let content = JSON.parse(JSON.stringify(tmp_content))
            var element = document.createElement('div');
            element.insertAdjacentHTML('beforeend', content);
            element.querySelectorAll('.replaced-text')
                .forEach(function(tag) {
                    const token_type   = tag.getAttribute('data-token-type');
                    let value = "";
                    switch(token_type){
                        case 'initials':
                            is_signature = true
                            value = tag.getAttribute('data-value')                            
                            tag.src = '{d.Initials}'
                            break;
                        case 'signature':
                            is_signature = true
                            value = tag.getAttribute('data-value')                            
                            tag.src = '{d.Signature}'
                            break;
                        case 'pricing_table':
                            break;
                        default: 
                            value = tag.getAttribute('data-value');
                            tag.replaceWith(value)
                    }
                }); 


            element.querySelectorAll('table').forEach(function(table) {
                table.setAttribute('width', table.style.width)
                
            })

            let html = element.innerHTML.replace('<!-- pagebreak -->', '<br style="page-break-before: always;" />')
            return html
        },  
        updateValue(newValue) {
            this.$emit('input', newValue);
        },
        handleFilePicker(cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.addEventListener('change', (e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    const id = 'blobid' + new Date().getTime();
                    const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                    const base64 = reader.result.split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    cb(blobInfo.blobUri(), { title: file.name });
                });
                reader.readAsDataURL(file);
            });
            input.click();
        },
        editorSetup(editor) {
           
            editor.ui.registry.addButton('mergeFields', {
                text: 'Merge Fields',
                onAction: (api) => {
                    this.$emit('showMergeFields', true)
                }
            }); 

            editor.ui.registry.addButton('insertImage', {
                icon: "image", 
                onAction: (api) => {
                    this.show_add_image = true;
                    
                }
            });

            editor.ui.registry.addButton('preview', {
                icon: "preview", 
                onAction: (api) => {
                    let content = this.getContent();
                    this.$emit('showPreview', content)
                }
            });


            return (api) => {}
        },
        setImage() {
            
            if (this.image.src.length === 0) {
                alert("Please select an image.");
                return;
            }

            const file = this.image.src[0];
            const reader = new FileReader();
            
            reader.onload = function(event) {

                const imgElement = document.createElement("img"); // Create new <img> element
                imgElement.src = event.target.result; // Set the Base64 data URL
                imgElement.classList.add('inserted-image')
                imgElement.classList.add('mceResizable')
                imgElement.setAttribute('data-mce-resize', 1);
                console.log("imgElement.outerHTML", imgElement.outerHTML)
                tinymce.activeEditor.insertContent(imgElement.outerHTML, {select: true});
            };

            reader.readAsDataURL(file); // Convert to Base64
            
            this.image = {
                src: [],
                alt: ''
            }
            this.show_add_image = false; 
            return;
            tinymce.activeEditor.insertContent(`<img  data-mce-resize="1"  class="mceResizable" src="${this.image.src}" />`, {select: true});
            
        }, 
        insertToken(token){
            
            let id = uuidv4();
            if(token.type == 'signature'){ 
                tinymce.activeEditor.insertContent(`<img style="display: inline-block; width: 180px; height: 100px;"  data-mce-resize="1" data-token-type="${token.type}" data-id="${ id }" data-value="${token.token}" data-friendly="${token.label}" data-placeholder="/img/tppx.png" class="mceResizable mceNonEditable replaced-text ${token.type}" src="/img/tppx.png" />`, {select: true});
                
                setTimeout(() => {
                    const editor = tinymce.activeEditor;
                    let img = editor.getBody().querySelector(`#${id}`);
                    img.getNode().setAttribute("data-mce-resize","1");         
                    editor.selection.controlSelection.showResizeRect(img);
                        editor.selection.select();
                
            }, 100);


                // tinymce.activeEditor.execCommand('mceInsertContent', false, `<span contenteditable="false" data-token-type="${token.type}" data-id="${ uuidv4() }" data-value="${token.token}" data-friendly="${token.label}" class="replaced-text ${token.type}"><img src="${token.token}" style="width: 180px; height: 100px;" />Sign Here</span>`);
            } else if(token.type == 'initials'){
                tinymce.activeEditor.insertContent(`<img style="display: inline-block; width: 90px; height: 100px;"  data-mce-resize="1" data-token-type="${token.type}" data-id="${ id }" data-value="${token.token}" data-friendly="${token.label}" data-placeholder="/img/tppx.png" class="mceResizable mceNonEditable replaced-text ${token.type}" src="/img/tppx.png" />`, {select: true});
                // tinymce.activeEditor.execCommand('mceInsertContent', false, `<span contenteditable="false" data-token-type="${token.type}" data-id="${ uuidv4() }" data-value="${token.token}" data-friendly="${token.label}" class="resizable replaced-text ${token.type}"><img src="${token.token}" style="width: 90px; height: 100px;" /></span>`);
            } else if(token.type == 'pricing_table'){
                tinymce.activeEditor.execCommand('mceInsertContent', false, `<div contenteditable="false" data-token-type="${token.type}" data-id="${ uuidv4() }" data-value="${token.token}" data-friendly="${token.label}" class="replaced-text ${token.type}">
                    <table width="75%" style="font-family: helvetica, sans-serif; page-break-inside: avoid; width: 100%; border-collapse: collapse;" cellpadding="0" cellspacing="0"> 
                    
                        <col width="40*"/>
                        <col width="20*"/>
                        <col width="20*"/>
                        <col width="20*"/>
                            
                        <tr>
                            <th valign="middle" style="background: #e3f0f1; border-bottom: 1px solid #ccc; color: #000000; font-size: 10pt;" align="left"><p style="margin: 0.06in">&nbsp;&nbsp; Item Name &nbsp;&nbsp;</p></th>
                            <th valign="middle" style="background: #e3f0f1; border-bottom: 1px solid #ccc; color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; Price &nbsp;&nbsp;</p></th>
                            <th valign="middle" style="background: #e3f0f1; border-bottom: 1px solid #ccc; color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; Qty &nbsp;&nbsp;</p></th>
                            <th valign="middle" style="background: #e3f0f1; border-bottom: 1px solid #ccc; color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; Line Total &nbsp;&nbsp;</p></th>
                        </tr>
                        <tr>
                            <td style="color: #000000; font-size: 10pt;" align="left"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i].name} &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i].price} &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i].qty} &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i].total} &nbsp;&nbsp;</p></td>
                        </tr>
                        <tr>
                            <td style="color: #000000; font-size: 10pt;" align="left"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i+1].name} &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i+1].price} &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i+1].qty} &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.lines[i+1].total} &nbsp;&nbsp;</p></td>
                        </tr>
                        
                        <tr>
                            <td style="border-top: 1px solid #ccc; color: #000000; font-size: 10pt;" colspan="3" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; Subtotal &nbsp;&nbsp;</p></td>
                            <td style="border-top: 1px solid #ccc; color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; {d.PricingTable.subtotal} &nbsp;&nbsp;</p></td>
                        </tr>
                        <tr>
                            <td style="color: #000000; font-size: 10pt;" align="right" colspan="3"><p style="margin: 0.06in">&nbsp;&nbsp; Tax &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in;">&nbsp;&nbsp; {d.PricingTable.tax} &nbsp;&nbsp;</p></td>
                        </tr>
                        <tr>
                            <td style="color: #000000; font-size: 10pt;" align="right" colspan="3"><p style="margin: 0.06in">&nbsp;&nbsp; <strong>Total</strong> &nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp; <strong>{d.PricingTable.total}</strong> &nbsp;&nbsp;</p></td>
                        </tr>
                        <tr>
                            <td style="color: #000000; font-size: 10pt;" align="right" colspan="3"><p style="margin: 0.06in">&nbsp;&nbsp;&nbsp;&nbsp;</p></td>
                            <td style="color: #000000; font-size: 10pt;" align="right"><p style="margin: 0.06in">&nbsp;&nbsp;&nbsp;&nbsp;</p></td>
                        </tr>
                    
                    </table>
                </div>`);
            } else {
                tinymce.activeEditor.execCommand('mceInsertContent', false, `<span contenteditable="false" data-token-type="${token.type}" data-id="${ uuidv4() }" data-value="${token.token}" data-friendly="${token.label}" class="replaced-text ${token.type}">${token.label}</span>`);
            }
    }

    
    },
};
</script>

<style>
.tox .tox-toolbar {
    background: none !important;
    padding: 5px  !important;
    border-bottom: 1px solid #EEEEEE !important;
}

.tox .tox-toolbar:last-child{
    border-bottom: none !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px !important;
}

.tox-tinymce {    
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px !important;;
}

.editor-bottom-actions{
    padding: 5px; 
    flex: 0; 
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white; 

}

.tox .tox-tbtn.tox-tbtn--select:active,
.tox .tox-tbtn.tox-tbtn--select:hover,
.tox .tox-tbtn--enabled:active, 
.tox .tox-tbtn--enabled, 
.tox .tox-tbtn--enabled:hover,
.tox .tox-tbtn:active,
.tox .tox-tbtn:hover {
    background: #E0F5F5 !important;
    color: #00848E !important;
}

.tox .tox-tbtn--enabled svg, 
.tox .tox-tbtn--enabled:hover svg,
.tox .tox-tbtn:active svg,
.tox .tox-tbtn:hover svg {
    fill: #00848E !important;
}


</style>
