<template>
    <div style="background-color: white; flex: 1 0; display: flex; flex-direction: column; ">
  
        <div class="pa-5" style="flex: 0 1" >
            <HbSelect 
                box 
                full
                v-model="selectCategory"
                :items="categories"
                :clearable="false" 
                v-validate="'required|max:45'" 
                data-vv-scope="default"
                data-vv-name="category" 
                data-vv-as="Category" 
                :error="errors.has('default.category')" 
            />
            <HbTextField 

                class="mt-3"  
                search 
                v-model="mergeSearch" 
                v-validate="'required|max:45'"
                data-vv-scope="default" 
                data-vv-name="text-field-search" 
                data-vv-as="Text Field Search"
                @click:clear="clearSearch" 
                :error="errors.has('default.text-field-search')"
                placeholder="Search Merge Fields" />
        </div>
        <v-divider class="mt-2 mb-2"></v-divider>

        <div class="px-3" >            
            <v-list class="list-view pa-0" density="compact" style="overflow: auto;" :style="{height: panelSize + 'px'}">
                <!-- Iterate over the groups -->
     
                <v-list-item style="height: 40px;" class="px-2 py-0" v-for="(field, category) in filteredFields" :key="category" >
                    <v-list-item-title style="color: #637381 " @click="addMergeField(field)" >{{field.label}}</v-list-item-title>
                    
                    <v-list-item-icon>
                        <div class="tooltip-label">
                            <hb-tooltip>
                                <template v-slot:item>
                                    <div class="plus-icon">
                                        <HbIcon mdi-code="mdi-information" small />
                                    </div>
                                </template>
                                <template v-slot:body>
                                    {{ field.description }}
                                </template>
                            </hb-tooltip>
                        </div>                    
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </div>
    </div>


</template>

<script>
    import { Tokens } from './Tokens';
    export default {
        data() {
            return {
                selectCategory: 'All Merge Fields',
                mergeSearch: [],
                mergeFields: Tokens.mergeFields,
                panelSize: 500,
            };
        },
        created() {
            window.addEventListener("resize", this.setPanelSize);
            this.setPanelSize();
        },
        destroyed() {
            window.removeEventListener("resize", this.setPanelSize);
        },
        computed:{
            filteredFields() {
                let tokens = Tokens.map(t => {
                    t.label = t.label || t.token
                    return t
                });
                
                if(this.selectCategory !== 'All Merge Fields'){
                    tokens = tokens.filter(t => t.category == this.selectCategory);
                }
                

                if(this.mergeSearch.length){
                    tokens = tokens.filter(t => t.label.toLowerCase().search(this.mergeSearch.toLowerCase()) >= 0 )
                }
                
                return tokens;
            },
            categories(){
                
                return ['All Merge Fields', ...new Set(Tokens.map(m => m.category))];
            }
        },
        methods: {
            setPanelSize(e){
                console.log("window.innerHeight", window.innerHeight)
                this.panelSize = window.innerHeight - 333;
            
            }, 
            clearSearch() {
                this.mergeSearch = '';
            },
            addMergeField(token) {
                this.$emit('addMergeField', token, this.context) // emit to parent 
                
                // if (this.context === 'email') {
                // }
                // else if (this.context === 'sms') {
                //     this.$emit('addMergeField', token, this.context) // emit to parent 
                // } else if (this.context === 'document') {
                //     this.$emit('addMergeField', token, this.context) // emit to parent 
                // }
            },
            onCloseClick() {
                // Handle close icon click
                this.$emit('onCloseClick');
            },


            // native functions
            onActivated() {
                //console.log('Activated');
            },
         },



    }
</script>

// Cursor pointer
// Teal background
// if merge fields are selected, keep background lit

