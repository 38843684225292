<template>
  <div class="intent-section px-7">
    <div class="hb-font-header-2-medium mt-n1 mb-4">Move-Out Statement</div>

    <hb-card
      v-if="(moveOutCalc.lease || (currentRefund && currentRefund.credit)) && !contact?.national_account_id"
      border
      no-title
      :box-shadow="(leaseInfo && leaseInfo.promotion) || (currentRefund && currentRefund.credit && currentRefund.credit > 0) ? true : false"
    >
      <div style="padding: 10px 16px">
        <div class="pb-1">
          <span class="hb-font-body-medium"> Move-In Date:</span> <span class="hb-font-body"> {{ moveOutCalc.lease?.start_date | formatDateServices  }} </span>
        </div>
        <div class="pb-1" v-if="moveOutCalc.lease?.promotion || moveOutCalc.lease?.Promotions.length">
          <span class="hb-font-body-medium"> Move-In Promotion:</span> <span class="hb-font-body"> {{moveOutCalc.lease?.promotion || 'No Move In Promotion'}} </span>
        </div>
        <div class="pb-1">
          <span class="hb-font-body-medium"> Move-Out Date: </span> <span class="hb-font-body"> {{ this.move_out_date |  formatDateServices }} </span>
        </div>
        <div class="pb-1">
          <span class="hb-font-body-medium"> Enrolled in AutoPay: </span> <span class="hb-font-body"> {{ leaseInfo?.has_autopay ? 'Yes' : 'No' }} </span>
        </div>
      </div>
    </hb-card>

    <!-- <buttons/> -->
    <v-col class="px-0 pt-0">
          <v-expansion-panels v-model="panels" v-if="!contact?.national_account_id">
            <hb-expansion-panel key="charges">
              <template v-slot:title>
                Charges
              </template>
              <template v-slot:actions>
                <span class="hb-font-body-medium">
                  {{ moveOutCalc?.totalDue | formatMoney }}
                </span>
              </template>
              <template v-slot:content>
                <invoices-view 
                :property_id="property_id"
                :lease_id="lease_id"
                :moveOutCalc="moveOutCalc"
                />
              </template>
        </hb-expansion-panel>
        </v-expansion-panels>
        <div class="mt-2 pl-1 mb-n3">
          <hb-radio-group row v-model="paymentOption" v-if="!contact?.national_account_id && moveOutCalc.message?.amount_due > 0">
                <hb-radio value="takePayment" label="Take Payment" class="mr-2"/>
                <hb-radio value="skipPayment" label="Skip Payment" class="mr-2"/>
                <hb-radio value="writeOff" label="Write Off"/>
          </hb-radio-group>
        </div>
        <hb-modal v-model="writeOffModal" v-if="paymentOption==='writeOff'" size="medium" title="Write Off as Loss" show-help-link>
            <template v-slot:content>
                <div class="pa-6">
                    Are you sure you want to write off the current balance of {{moveOutCalc.message.amount_due | formatMoney}} as a loss?
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn
                    color="primary"
                    @click="writeOff"
                >
                    Write Off Balance
                </hb-btn>
            </template>
        </hb-modal>
        <v-expansion-panels multiple v-model="panels">
        
          <hb-expansion-panel error v-if="moveOutCalc?.total_to_refund > 0 && !contact?.national_account_id" key="refund-view" right-cols="6" left-cols="6" no-top-margin>
          <template v-slot:title>
            <span class="mt-1">
              Refunds
            </span>
          </template>
          <template v-slot:actions>
              <span class="hb-font-body-medium">
                {{ moveOutCalc.total_to_refund | formatMoney }}
              </span>
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <refund-view
                :account_balance="account_balance"
                :amt_to_refund="moveOutCalc.total_to_refund"
                :overpayments="overpayments"
                :moveOutCalc="moveOutCalc"
                :move_out_date="move_out_date"
                :lease_id="lease_id"
                :current_refund="moveOutCalc.message.refund_amount"
                :contact_id="contact_id"
              />
            </div>
            <div class="pb-3 px-6">
              <hb-radio-group v-model="refundType" v-if="(isAUS || isNZ) && moveOutCalc.message.refund_amount > 0">
                <hb-radio value="now" label="Refund Now"/>
                <hb-radio value="later" label="Refund Later"/>
              </hb-radio-group>
            </div>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel v-if="moveOutCalc.message?.amount_due > 0 && !contact?.national_account_id && paymentOption==='takePayment'" key="payment" :value="true">
              <template v-slot:title>
                Total Payment
              </template>          
              <template v-slot:actions>
                <div class="hb-font-body-medium align-right">
                  {{ moveOutCalc.message.amount_due | formatMoney }}
                </div>
              </template>
              <template v-slot:content>
                <div class="py-1 px-0">
                  <payment-view 
                  :key="componentKey"
                  :property_id="property_id"
                  :payment_amount="moveOutCalc.message.amount_due"
                  :lease_id="lease_id"
                  :contactId="contact_id"
                  :moveOutCalc="moveOutCalc"
                  ref="paymentView"
                  />
                </div>
              </template>
        </hb-expansion-panel>
        <hb-expansion-panel success key="auction" v-if="lease.auction_status == 'move_out'" title-icon="mdi-gavel">
          <template v-slot:title>
            Sold at Auction
          </template>
          <template v-slot:content>
            <auction-sold :auctionLease="lease"></auction-sold>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel error key="incomplete-prep" v-if="!readyToMove && products && products.length">
          <template v-slot:title>
            Incomplete Prep For Rental
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <incomplete-prep
                :lease_id="lease_id"
                :property_id="property_id"
                :lease="lease"
                :contact="contact"
                @openBalance="setOpenBalance"
              />
            </div>
          </template>
        </hb-expansion-panel>

        <!-- <hb-expansion-panel error key="open-balance" v-if="(lease.auction_status !== 'move_out') && totalDue && totalDue > 0" v-show="!contact?.national_account_id">
          <template v-slot:title>
            <span class="mt-1">
              Open Balance:
            </span>
            <span class="ml-1 hb-font-body font-weight-regular">{{totalDue && totalDue > 0 ? '$' + totalDue : '' }}</span>
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <Payments
                  :lease_id="lease_id"
                  :unit_id="unit_id"
                  :property_id="property_id"
                  :contactId="contact_id"
                  :payment_amount="totalDue"
                  :move_out_date="move_out_date"
                  @openBalance="setOpenBalance"
              />
            </div>
          </template>
        </hb-expansion-panel> -->

        <!-- <hb-expansion-panel caution key="security-deposit-refund" v-if="securityDeposit && securityDeposit > 0">
          <template v-slot:title>
            <span class="mt-1">
              Security Deposit:
            </span>
            <span class="ml-1 hb-font-body font-weight-regular">{{ securityDeposit | formatMoney }}</span>
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <security-deposit-refund
                @fetchSecurityDeposit="fetchSecurityDeposit"
                :lease_id="lease_id"
                :security_deposit="securityDeposit"
                @securityRefunded="securityRefunded"
              />
            </div>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel error key="refund-request" :readonly="!isSecurityRefunded && securityDeposit && securityDeposit > 0 ? true : false" :expand-icon-off="!isSecurityRefunded && securityDeposit && securityDeposit > 0 ? true : false" v-if="amt_to_refund > 0 && !contact?.national_account_id">
          <template v-slot:title>
            <span class="mt-1">
              Refund Request:
            </span>
            <span class="ml-1 hb-font-body font-weight-regular">{{ amt_to_refund | formatMoney }}</span>
          </template>
          <template v-slot:actions>
            <v-tooltip bottom v-if="!isSecurityRefunded && securityDeposit && securityDeposit > 0">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"><hb-icon small color="#00000087" mdi-code="mdi-alert-circle"></hb-icon></span>
              </template>
              <span>Please process the refund of the security deposit first.</span>
            </v-tooltip>
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <refund-request
                :account_balance="account_balance"
                :amt_to_refund="amt_to_refund"
                :overpayments="overpayments"
                :move_out_date="move_out_date"
                @fetchPayments="fetchOverPayments"
                :lease_id="lease_id"
                :current_refund="amt_to_refund"
                :contact_id="contact_id"
              />
            </div>
          </template>
        </hb-expansion-panel> -->
        <hb-expansion-panel v-if="refundType == 'later'" key="refund-later" hide-actions-when-closed>
          <template v-slot:title>
            Direct Refund Details
          </template>
          <template v-slot:content>
            <v-col cols="6" class="pb-3 px-6">
              <v-text-field
                cols="6" 
                class="my-n1 pr-2"
                v-model="reversal.directrefund_account_name"
                v-validate.disable="'required|max:32'"
                data-vv-as="Account Name"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_account_name')"
                label="Account Name*"
                id="directrefund_account_name"
                name="directrefund_account_name"
                >
              </v-text-field>
              <v-text-field
                cols="6" 
                class="my-n1 pr-2"
                v-if="isAUS"
                v-model="reversal.directrefund_bsb_number"
                v-validate.disable="'required|numeric|length:6'"
                maxlength="6"
                data-vv-as="BSB Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_bsb_number')"
                label="BSB Number*"
                id="directrefund_bsb_number"
                name="directrefund_bsb_number"
                >
              </v-text-field>
            
              <v-text-field
                cols="6" 
                class="my-n1 pr-2"
                v-model="reversal.directrefund_account_number"
                v-validate="{ required: true, numeric: true, customAccountNumberValidation: true }"
                data-vv-as="Account Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_account_number')"
                label="Account Number*"
                id="directrefund_account_number"
                name="directrefund_account_number"
              >
              </v-text-field>
            </v-col>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel key="move-out-notes" hide-actions-when-closed>
          <template v-slot:title>
            Move-Out Notes
          </template>
          <template v-slot:actions>
            <hb-btn small color="secondary" @click.native.stop="showNoteModal =! showNoteModal" class="pr-2">Add Note</hb-btn>
          </template>
          <template v-slot:content>
            <notes :show-note-modal="showNoteModal" :contact_id="contact_id" :unit_number="spaceNumber" :lease_id="lease_id" @close="closeNotesModal" />
          </template>
        </hb-expansion-panel>


      </v-expansion-panels>
    </v-col>

    <div class="new-tenant-walkthrough-footer">
      <v-row>
        <v-col class="pt-4">
          <help-juice />
        </v-col>
        <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
          <hb-link class="mr-2" @click="$emit('close')">Cancel</hb-link>
          <hb-btn class="mr-2"  color="primary" @click="contact?.national_account_id ? closeLease() : processMoveOut()" :loading="closeLeaseInProgress" :disabled="moveOutButtonDisabled">
            Move Out
          </hb-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script type="text/babel">
  import MaintenanceScheduled from "./MaintenanceScheduled.vue";
  import AuctionSold from "./AuctionSold.vue";
  import IncompletePrep from "./IncompletePrep.vue";
  import RefundRequest from "./RefundRequest.vue";
  import RefundView from "./RefundView.vue";
  import SecurityDepositRefund from "./SecurityDepositRefund.vue";
  import Buttons from "./Buttons.vue";
  import Notes from "./Notes.vue";
  import ActivityLogs from "./ActivityLogs.vue";
  import Payments from "./Payments.vue";
  import InvoicesView from "./InvoicesView.vue";
  import PaymentView from "./PaymentView.vue";
  import api from '../../../../assets/api.js';
  import moment from 'moment';
  import { EventBus } from '../../../../EventBus.js';
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import { notificationMixin } from  '../../../../mixins/notificationMixin.js';

export default {
  name: "MoveOutStatementIndex",
  components: {
    MaintenanceScheduled,
    AuctionSold,
    IncompletePrep,
    RefundRequest,
    RefundView,
    SecurityDepositRefund,
    Buttons,
    InvoicesView,
    Notes,
    ActivityLogs,
    Payments,
    PaymentView
  },
  mixins: [ notificationMixin ],
  data: () => ({
    amt_to_refund: 0,
    amount: 0,
    totalActivities: 0,
    account_balance: 0,
    overpayments: 0,
    securityDeposit: 0,
    refundInvoices:[],
    showNoteModal: false,
    leaseInfo: null,
    leaseClosed: false,
    move_in_ready: 1,
    products: [],
    leases: [],
    panels: [ 2 ],
    isLoading: false,
    finalizeInProgress: false,
    closeLeaseInProgress: false,
    isSecurityRefunded: false,
    refundType: 'now',
    reversal: {},
    isAUS: false,
    isNZ: false,
    properties: null,
    paymentOption: 'takePayment',
    writeOffModal: false,
    componentKey: 0
  }),
  props: ['close', 'contact_id', 'lease', 'contact', 'property_id','lease_id', 'unit_id', "move_out_date", "ready_to_move_in"],
  async created() {
    this.isLoading = true
    await this.setupValidation();
    this.$emit('showLeaseSummary');
    this.fetchOverPayments();
    await this.fetchSecurityDeposit();
    if(!this.contact?.national_account_id){
    await this.calculateMoveOutDate();
    }
    this.getLeaseInfo();
    this.getFees();
    await this.setContactInvoices();
    if(this.lease.end_date && this.lease.end_date >= moment().startOf('day').format('YYYY-MM-DD')){
      this.leaseClosed = true;
    }
    this.isLoading = false
  },
  // filters: {
  //   formatDate(value) {
  //     if (!value) return null;
  //     return moment(value).format("MMMM DD, YYYY");
  //   }
  // },
  computed: {
      ...mapGetters({
        notification: "charmCallStore/notification",
        charmNotification: "charmNotificationStore/charmNotification",
        payment: 'paymentsStore/getPayment',
        payment_information: 'paymentsStore/getPaymentInformation',
        payment_method: 'paymentsStore/getPaymentMethod',
        moveOutCalc: 'paymentsStore/getMoveOutCalc',
        additionalCharges: 'paymentsStore/getAdditionalCharges',
      }),
      isDirectRefundValid() {
        if (this.refundType !== 'later') {
          return true;
        }
        
        if (this.isAUS) {
          return !this.errors.has('reversal.directrefund_account_name') &&
                !this.errors.has('reversal.directrefund_bsb_number') &&
                !this.errors.has('reversal.directrefund_account_number') &&
                this.reversal.directrefund_account_name &&
                this.reversal.directrefund_bsb_number &&
                this.reversal.directrefund_account_number;
        }
        
        return !this.errors.has('reversal.directrefund_account_name') &&
              !this.errors.has('reversal.directrefund_account_number') &&
              this.reversal.directrefund_account_name &&
              this.reversal.directrefund_account_number;
      },

      moveOutButtonDisabled() {
        return this.closeLeaseDisabled || 
              this.leaseClosed || 
              this.isLoading || 
              this.closeLeaseInProgress || 
              this.finalizeInProgress ||
              !this.isDirectRefundValid;
      },
    currentRefund(){
        return this.refundInvoices.find(x => x.credit > 0);
    },
    spaceNumber(){
      return this.leases[0]?.Unit?.number
    },
    readyToMove() {
      return this.$props.ready_to_move_in;
    },
    moveSpaceToInventory() {
      let current_date = moment().startOf('day').format('YYYY-MM-DD')
      if(!this.$props.lease.end_date || this.$props.lease.end_date > current_date || this.$props.lease?.Unit?.status == 1) {
        return true
      }
      else {
        return false;
      }
    },
    closeLeaseDisabled() {
      return this.$props.lease.move_out ? true : false;
    },
    totalDue(){
      // lease_total is Open Balance : Sum of balances of all unpaid invoices of a lease having due date <= move_out_date
      let lease_total = this.leases.reduce((total, l) => total + (l.OpenInvoices.filter(oi => oi.due <= this.move_out_date).reduce((total_balance, oi) => total_balance + oi.balance, 0)), 0);
      let new_charges = this.leases.reduce((a,b) => a + (b.Charges && b.Charges.balance ? b.Charges.balance : 0), 0);
      return Math.round((lease_total + new_charges) * 1e2) / 1e2;
    },
  },
  watch: {
    moveOutCalc: function (oldVal, newVal){
      if(oldVal !== newVal){
        this.componentKey += 1;
        if (this.moveOutCalc.message?.amount_due > 0 && !this.contact?.national_account_id && this.paymentOption === 'takePayment') {
          this.panels = [2];
        }
      }
    },
    async refundType(newVal){
      if(newVal == 'later'){
        await this.isAUSOrNZ();
        console.log("Aus: ",this.isAUS)
        console.log("NZ: ",this.isNZ)
      }
    },
    async paymentOption(newVal){
      if(newVal == 'takePayment'){
        this.payment_amount = this.totalDue;
        if (this.moveOutCalc.message?.amount_due > 0 && !this.contact?.national_account_id) {
          this.panels = [2];
        }
      } else if(newVal == 'writeOff'){
        this.writeOffModal = true;
      }
    }
  },
  methods: {
    ...mapMutations({
      resetContact: 'onBoardingStore/resetContact',
      setPaymentObject: 'paymentsStore/setPaymentObject',
      setPaymentInformationObject: 'paymentsStore/setPaymentInformationObject',
      setMoveOutCalc: 'paymentsStore/setMoveOutCalc',
    }),
    ...mapActions({
      resetPropertiesInfo: 'propertiesStore/resetPropertiesInfo',
      fetchPropertyGroups: 'propertiesStore/fetchPropertyGroups'
    }),
    writeOff(){
        var invoice_ids = [];

        for(var i = 0; i < this.leases.length; i++) {
            let lease = this.leases[i]
            let open_invoices = lease.OpenInvoices.filter(oi => oi.due <= this.move_out_date);
            for(var j = 0; j < open_invoices.length; j++) {
                var obj = {};
                obj['invoice_id'] = open_invoices[j].id;
                invoice_ids.push(obj);
            }
        }

        var data = {
            amount: this.payment_amount,
            notes: '',
            invoices: invoice_ids,
            contact_id: this.contact_id,
            source: 'move_out'	
        }
        
        return api.post(this, api.LEASES + this.lease_id + '/invoices/write-off', data).then(r => {
            this.$emit('openBalance');
            this.writeOffModal = false
            this.componentKey += 1;
        }).catch(err => {
            this.showMessageNotification({ description: err});
        });
    },
    async setupValidation() {
        await this.isAUSOrNZ();
        this.$validator.extend('customAccountNumberValidation', {
          validate: value => {
            console.log('Validating with:', { isAUS: this.isAUS, isNZ: this.isNZ, value });
            if (this.isAUS) {
              const regex = /^[0-9]{4,9}$/;
              return regex.test(value);
            } else if (this.isNZ) {
              const regex = /^[0-9]{14,16}$/;
              return regex.test(value);
            }
            return false;
          },
          getMessage: field => {
            if (this.isAUS) {
              return `The ${field} must be a valid Australian account number (4-9 digits).`;
            } else if (this.isNZ) {
              return `The ${field} must be a valid New Zealand account number (14-16 digits).`;
            }
            return 'Please contact the onboarding team to get the address updated.';
          }
        });
    },
    async isAUSOrNZ() {
          let r = await api.get(this, api.PROPERTIES + this.property_id);
          this.properties = r.property;
          if(this.properties.Address.country  && /^(AUS|AU|Australia)$/i.test(this.properties.Address.country)) {
            this.isAUS = true;
            this.isNZ = false;
          } else if(this.properties.Address.country && /^(NZ|NZL|New Zealand)$/i.test(this.properties.Address.country)) {
            this.isNZ = true;
            this.isAUS = false;
          } else {
            this.isAUS = false;
            this.isNZ = false;
          }
      },
    async setContactInvoices() {
      let r = await api.get(this, api.CONTACTS +  this.contact_id + '/leases', { property_id: this.property_id, active_date: moment.utc().format('YYYY-MM-DD') });
      this.leases = r.leases.filter(x=> x.id === this.lease_id);
      this.$emit('fetchLeaseData');
    },
    setOpenBalance() {
      this.setContactInvoices();
    },
    setActivitesCounter(value) {
      this.totalActivities = value;
    },
    async fetchOverPayments() {
      var date = moment(this.move_out_date).startOf("day").format("YYYY-MM-DD");
      let r = await api.get(this, api.LEASES + this.lease_id + '/over-payments', {moved_out: date})
      this.account_balance = r.account_balance;
      this.amt_to_refund = r.amt_to_refund;
      this.overpayments = r.overpayments;

      // this.refundInvoices = r.invoices.map(inv => {

      //   inv.credit =  inv.InvoiceLines.reduce((val, line ) => val + line.Credit.total,0) -
      //                 (inv.balance + inv.Payments.filter(x => x.Payment.credit_type !== 'payment').reduce((a,p) =>  a + p.amount, 0));

      //   inv.credit = Math.round(inv.credit * 1e2) / 1e2;

      //   inv.processed = 0;
      //   inv.handle_credit = '';
      //   inv.credit_memo = inv.credit > 0 ? 1: 0;

      //   inv.Payments = inv.Payments.map(p => {
      //       p.credit = 0;
      //       return p;
      //   })
      //   return inv;
      // });
    },
    async calculateMoveOutDate(){
        let date = moment(this.move_out_date).startOf("day").format("YYYY-MM-DD");
        let response = await api.post(this, api.LEASES + this.lease_id + '/calculate-move-out-balance',{moved_out: date});
        // this.moveOutCalc = response;
        this.setMoveOutCalc({moveOutCalc: response})
        this.componentKey += 1;
    },
    async fetchSecurityDeposit() {
      await api.get(this, api.LEASES + this.lease_id + '/security-deposit').then(r => {
        this.securityDeposit = r.lines.reduce((a,b) => a += b.amount, 0);
      });
    },
    securityRefunded(flag){
      if(flag && this.securityDeposit == 0){
        this.isSecurityRefunded = true;
      }
    },
    getLeaseInfo(){
      var date = moment(this.move_out_date).startOf("day").format("YYYY-MM-DD");
      api.get(this, api.LEASES + this.lease_id + '/metrics', {moved_out: date}).then(r => {
        this.leaseInfo = r.metrics
      })
    },
    refreshContactUnits(){
      if(this.lease.Tenants && this.lease.Tenants.length > 0 && this.lease.Tenants[0].contact_id == this.charmNotification.contact_id){
          this.$store.dispatch('charmNotificationStore/refreshContactUnits',this.charmNotification.contact_id);
      }
      if(this.lease.Tenants && this.lease.Tenants.length > 0 && this.lease.Tenants[0].contact_id == this.notification.contact_id){
          this.$store.dispatch('charmCallStore/refreshContactUnits',this.notification.contact_id);
      }
    },
    async closeLease() {
      try {
        this.closeLeaseInProgress = true;
        var moved_out = moment(this.move_out_date).startOf('day').format('YYYY-MM-DD');
        let readyToMove = this.readyToMove;
        var data = {
          moved_out: moved_out,
          availability_date: this.move_in_ready ? moved_out : moment(this.data.availability_date).startOf('day').format('YYYY-MM-DD'),
          readyToMove: readyToMove
        };

        this.resetContact();

        const response = await api.put(this, api.LEASES + this.lease_id + '/close', data);
        if (readyToMove) {
          await this.moveSpaceBackToInventory();
        }
        this.refreshContactUnits();

        if (response.lease_id) {
          this.leaseClosed = true;
          this.closeLeaseConfirmation = false;
          EventBus.$emit('lease_edited');
          this.$emit('fetchLeaseData');
          EventBus.$emit('contact_updated', this.contact_id);
          EventBus.$emit('contact_updated_transfer');
          EventBus.$emit('reservation_made');
        }
        this.$emit('close');

        await this.resetPropertiesInfo();
        this.fetchPropertyGroups({hardRefresh: true});
      } catch (e) {
        this.showMessageNotification({ type: 'error', description: e });
      } finally {
        this.closeLeaseInProgress = false;
      }
    },

    async processMoveOut(){
      console.log('processMoveOut');
      console.log('this.message:', this.moveOutCalc.message);
      this.closeLeaseInProgress = true;
      let moved_out = moment(this.move_out_date).startOf('day').format('YYYY-MM-DD');
      let readyToMove = this.readyToMove;
      let payload = {};
      try {
        this.resetContact();
        if(this.moveOutCalc.message?.amount_due > 0 && this.paymentOption === 'takePayment'){
          await this.setPaymentInformationObject({ paymentInformation: { ...this.payment_information, ...{ amountTendered: this.moveOutCalc.message.amount_due, totalNewPaymentPaying: this.moveOutCalc.message.amount_due } } });
          await this.setPaymentObject({ payment: { ...this.payment, ...{ amount: this.moveOutCalc.message.amount_due, contact_id: this.contactId, property_id: this.property_id, amount_tendered: this.payment_information.amountTendered } } })
          let payment_method = await this.$refs.paymentView.validatePayment();
          let payment_info = {
            payment: this.payment,
            paymentMethod: payment_method,
            contact_id: this.contact_id,
            property_id: this.property_id
          }
          payload.payment_info = payment_info;
        } else if (this.moveOutCalc.message?.refund_amount > 0 && this.refundType == 'later') {
          payload.future_refund = true;
          let directRefund = {
            type: 'directrefund',
            directrefund_bsb_number: this.reversal?.directrefund_bsb_number || '',
            directrefund_account_number: this.reversal?.directrefund_account_number,
            directrefund_account_name: this.reversal?.directrefund_account_name,
          }
          payload.directRefund = directRefund;
        }

        if (this.paymentOption === 'skipPayment') {
          payload.skip_payment = true;
        }
        payload.moved_out = moved_out;
        payload.confirm = true;
        payload.source = 'move_out'
        payload.dueInvoices = this.moveOutCalc.dueInvoices
        payload.additional_charges = this.additionalCharges
        payload.availability_date = this.move_in_ready ? moved_out : moment(this.data.availability_date).startOf('day').format('YYYY-MM-DD');
        payload.readyToMove = readyToMove;

        console.log('payload:', payload);

        let response = await api.post(this, api.LEASES + this.lease_id + '/calculate-move-out-balance', payload);
        console.log('response:', response);

        if (readyToMove) {
          await this.moveSpaceBackToInventory();
          if(response){
            this.leaseClosed = true;
            this.closeLeaseConfirmation = false;
            EventBus.$emit('lease_edited');
            this.$emit('fetchLeaseData');
            EventBus.$emit('contact_updated', this.contact_id);
            EventBus.$emit('contact_updated_transfer');
            EventBus.$emit('reservation_made');
          }
        }
        this.refreshContactUnits();
        this.$emit('close');

        await this.resetPropertiesInfo();
        this.fetchPropertyGroups({hardRefresh: true});
      } catch (e) {
        this.showMessageNotification({ type: 'error', description: e });
      } finally {
        this.closeLeaseInProgress = false;
      }
    },
    async moveSpaceBackToInventory() {
      this.finalizeInProgress = true;
      try {
        await api.put(this, api.UNITS + this.unit_id + '/enable');
        this.$emit('fetchLeaseData');
      } catch (err) {
        this.showMessageNotification({ type: 'error', description: err });
      } finally {
        this.finalizeInProgress = false;
      }
    },
    getFees(){
        api.get(this, api.PROPERTIES  + this.property_id + '/products?type=late&category_type=moveout' ).then(r => {
            this.products = r.products;
        });
    },
    closeNotesModal(payload){
      this.showNoteModal = payload;
    }
  },
  beforeDestroy(){
    this.$store.commit("paymentsStore/resetMoveOutCalc");
    this.$store.commit("paymentsStore/resetAdditionalCharges");
  }
};
</script>

<style scoped lang="scss">
.intent-section {
  padding: 20px 50px 75px 50px;
}

.intent-section .new-tenant-walkthrough-footer {
  padding: 3px 0 3px 30px;
  background: #ffffff;
  border-top: 1px solid #e2e2e2;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  left: 0;
  right: 0;
}
.light-text {
  color: rgba(0, 0, 0, 0.6);
}
.panel-header-custom-setting {
  background-color: rgb(251, 228, 224);
  color: rgba(0, 0, 0, 1);
}
.move-out-btn {
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 132, 142, 0.05) 100%
  );
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  p {
    color: rgb(0, 132, 142);
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.relative-box {
  position: relative;

  span.counter-absolute {
    position: absolute;
    right: -20px;
    background: #f26500;
    color: #fff;
    font-weight: 500;
    padding: 5px 11px;
    border-radius: 50%;
    border: 5px solid #fff;
    top: -10px;
    z-index: 1;
  }
}
.move-up-text {
  position: relative;
  top: -4px;
}
.font-16px {
  font-size: 16px;
  font-weight: 500;
}
.font-15px {
  font-size: 15px;
  font-weight: 500;
}
.no-focus-border-btn {
  border: 0;
}
</style>

<style>
.move-out-expan-panels.theme--light.v-expansion-panels
  .v-expansion-panel:not(:first-child)::after {
  border-color: rgba(0, 0, 0, 0) !important;
}
.move-out-expan-panels.theme--light.v-expansion-panels
  .v-expansion-panel--active
  + .v-expansion-panel {
  margin-top: 5px;
}
.no-padding-panel > .v-expansion-panel-content__wrap {
  padding: unset !important;
}
</style>
