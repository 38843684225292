<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="8" class="pa-0">
          <h2 class="headline mb-0">Finalize Transfer</h2>
          <span class="subtitle-1 font-weight-medium">Transfer Date: <span v-if="transfer != null">{{transfer.date | formatDateTimeCustom('MMM DD, yy')}}</span><span v-else>{{ new Date() | formatDateTimeCustom('MMM DD, yy')}}</span> by <span v-if="transfer != null">{{transfer.TransferBy.first + ' ' + transfer.TransferBy.last}}</span><span v-else>{{getUserData.first + ' ' + getUserData.last}}</span></span>
        </v-col>
      </v-row>
    </v-container>
    <DocumentsSigner :is-multi-space-lease="is_multi_space_lease" @documentsSigned="checkStatus"></DocumentsSigner>
    <div class="new-tenant-walkthrough-footer">
        <v-row class="ml-0">
            <v-col class="pt-4">
              <help-juice />
            </v-col>
            <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
                <hb-link class="mr-2" @click="$emit('close')"
                    >Cancel</hb-link
                >
                <hb-btn
                    color="primary"
                    :disabled="isLoading($options.name) || !documentSigned || fetchingAccess"
                    :loading="fetchingAccess || finalizeInProgress"
                    @click="confirmFinalize"
                    >Finalize Transfer</hb-btn
                >
            </v-col>
        </v-row>
    </div>

    <ConfirmAccessHours
      v-model="confirmHoursVisible"
      @confirm-change="finalize"
      :area-name="configuration_unit.area_name"
      :hours-name="selectedHours.name"
      :units-in-area="formattedUnitsInArea"
    />

  </div>
</template>

<script type="text/babel" >


import api from '../../../assets/api.js'; 
import { mapGetters } from 'vuex';
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import Status from '../../includes/Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import DocumentsSigner from '../DocumentsSigner.vue';
import ConfirmAccessHours from '../../access_hour_selection/ConfirmAccessHours.vue';
import { settingsMixin } from "@/mixins/settingsMixin.js";
import SETTINGS from "@/constants/settings.js";

export default {
  name: 'SignLease',
  mixins: [ notificationMixin, settingsMixin ],
  data() {
    return {
        fetchingAccess: false,
        confirmHoursVisible: false,
        standard_item_checkboxes: [],
        initial: false,
        upload_document : false,
        send_docs : false,
        email_doc_copy : false,
        file: {},
        all_documents: 'all',
        to_send_sms: false,
        documents_loaded: false,
        identity_verification: 'manual',
        docs_to_sign: [],
        sign_method: '',
        to_send: {},
        printLoading: false,
        documents_to_sign: [],
        sign_process: false,
        uploads_to_sign:[],
        signer_id: null,
        active_item: {},
        link:'',
        show_link:false,
        saved: false,
        panels: [0,1,2],
        selected_upload: {},
        statuses: [
            'error',
            'not_processed',
            'loading',
            'ready',
            'loaded',
            'initiated',
            'complete',
            'finished',
        ],
        sendDocument: false,
        send_doc_data: {
            message: '',
            method: '',
            contact: {},
            docs: []
        },
        finalizeInProgress: false,
        documentSigned: false
    }
  },
  props: ['transfer', 'selectedHours', "accessAreasEnabled"],
  components:{
    Status,
    Loader,
    ConfirmAccessHours,
    DocumentsSigner
  },
  computed: {
    ...mapGetters({
      properties: 'propertiesStore/filtered',
      property: 'onBoardingStore/property',
      lease: 'onBoardingStore/lease',
      template: 'onBoardingStore/template',
      paymentMethod: 'onBoardingStore/paymentMethod',
      billed_months: 'onBoardingStore/billed_months',
      contact: 'onBoardingStore/contact',
      getUserData: 'authenticationStore/getUserData',
      configuration_unit: 'onBoardingStore/configuration_unit',
      unitsInArea: 'onBoardingStore/units_in_area',
      singleSpaceRentalExists: "onBoardingStore/getSingleSpaceRental"
    }),
    formattedUnitsInArea(){
      return this.unitsInArea.join(", ");
    },
    is_multi_space_lease(){
      if(!this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL)) return false;
      if(!this.currentTemplate?.multi_space_rental || this.singleSpaceRentalExists?.flag) return false;  
      this.documentSigned = true;
      return true; 
    },
    currentTemplate(){
      return this.template[this.lease?.unit_id] || {};
    },
    
  },
  async created (){
    this.SETTINGS = SETTINGS;
    this.$emit('update:loading', false);
    if(this.contact?.id && this.property?.id && this.configuration_unit?.unit_type_id){
        const params = {
          contact_id: this.contact?.id,
          property_id: this.property?.id,
          unit_type_id: this.configuration_unit?.unit_type_id,
        }
        await this.$store.dispatch("onBoardingStore/updateSingleSpaceRental",params);
    }
  },
  methods: {  
    checkStatus(status){
      this.documentSigned = status;
    },

    async confirmFinalize(){
      if (this.accessAreasEnabled) {
        this.fetchingAccess = true;
        try {
          await api.get(this, api.PROPERTIES + this.property.id + '/access')
          await this.$store.dispatch("onBoardingStore/getUnitsInArea", {area_id: this.configuration_unit.area_id});
          if (this.unitsInArea.length > 1 && this.selectedHours?.vendor_access_level_id) {
            this.confirmHoursVisible = true;
            return;
          }
          if (!this.selectedHours?.vendor_access_level_id) {
            this.showMessageNotification({ type: 'error', description: 'The tenant has been transferred and needs access. Please configure and assign their access hours.' });                                                                                                                
          }
        } catch(err) {
          this.showMessageNotification({ type: 'error', description: 'The tenant requires access to the facility. Please configure Gate Access in the settings.' });
        } finally {
          this.fetchingAccess = false;
        }
      }
      this.finalize();
    },
    async finalize(){
        this.finalizeInProgress = true;
        let data = {
            billed_months: this.billed_months
        };
        data.payment = {};
        data.is_transfer = true;
        data.is_msr = this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) && this.currentTemplate?.multi_space_rental;
        data.single_space_exists = this.singleSpaceRentalExists?.flag;
        data.vendor_access_level_id = this.selectedHours?.vendor_access_level_id;
        try {
          await api.post(this, api.LEASES + this.lease.id + '/finalize', data)
          EventBus.$emit('contact_updated_transfer');
          EventBus.$emit('reservation_made');
          EventBus.$emit('unit_available_changed');
          this.$store.commit('onBoardingStore/resetContact');
          this.$emit('saved');
          this.$emit("next");
          this.finalizeInProgress = false;
        }catch(err){
          this.finalizeInProgress = false;
          this.showMessageNotification({ type: 'error', description: error });
        }
    },

    goToLease(){
      this.$router.push('/contacts/' + this.contact.id);
      this.$emit('close', true);
    },
  }
}
</script>

<style scoped>

</style>