<template>
<hb-modal 
    @close="$emit('close')"
    title="Save Document"
    v-model="showModal">
    <template v-slot:content>
        <hb-form label="Template Name"
        description="How this template will be listed in template list" full required>
        <HbTextField 
            v-model="template.name" 
            v-validate="'required|max:25'" 
            data-vv-name="name"
            data-vv-scope="general" 
            data-vv-as="Template Name"
            :error="errors.has('general.name')" 
            placeholder="Template Name" 
            class="mb-2" 
        />
    </hb-form>    

    <hb-form 
        label="Template Category" 
        description="Select the category of the template" 
        full
        required>
            <HbSelect 
                v-model="template.type" 
                :items="template_types" 
                v-validate="{ required: true }"
                data-vv-scope="general" 
                data-vv-name="type" 
                item-text="name"
                item-value="value"
                data-vv-as="Template Type"
                :error="errors.has('general.category')"
                placeholder="Select Category"
                class="mb-3" />
    </hb-form>
    
    <hb-form 
        label="Enable Properties" full>
            <hb-combobox
                v-if="filterPropertyList.length"
                style="max-width: 720px !important;" 
                v-model="template.Properties" 
                v-validate="{ required: true }" 
                editable 
                :items="filterPropertyList" 
                data-vv-name="property" 
                data-vv-as="properties" 
                item-text="name"
                    data-vv-scope="general" 
                label="Select properties"
                :error="errors.has('general.property')" 
            />
        </hb-form> 
    </template>
    <template v-slot:actions>
        <hb-btn color="primary" class="ml-2" @click="saveTemplate">Save</hb-btn>
        <!-- <span v-show="isLoading($options.name)">
            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
        </span> -->
    </template>
</hb-modal>
</template>


<script type="text/babel">

        
    import api from '../../../assets/api.js';
    import { mapGetters } from "vuex";
    
    export default {
        name: "DocumentTemplateDetails",
        data() {
            return {
                showModal: true,
                template: {
                    id: "", 
                    carboneTemplateId: null, 
                    name: '',
                    type: '',
                    Properties: [],
                    template: '',
                },
                template_types: []
            }
        },
        props: ['selected'],
        async created(){
            await this.getDocumentTypes();
            if(this.$props.selected?.id){

                let template = JSON.parse(JSON.stringify(this.$props.selected))
                this.template.name = template.name; 
                this.template.type = template.type; 
                this.template.Properties = template.Properties; 
                this.template.id = template.id; 
            
            }
        },
        computed:{
            ...mapGetters({
                facilityList: 'propertiesStore/filtered',
            }),
            filterPropertyList() {
                return this.facilityList.map(obj => ({ id: obj.id, name: obj.name }));
            }
        },
        methods:{
            async getDocumentTypes(){
                try {
                    let response = await this.$http.get(api.DOCUMENT + '/types');
                    this.template_types = response.body.data.types;
                } catch(err){
                    console.log(err)
                }
            }, 
            async saveTemplate(){   
                const status = await this.$validator.validateAll('general');
                if(!status) return;
                this.$emit('save', this.template)
                
            }
        }
    }
</script>