<template>
  <div  style="overflow: hidden;" class="new-tenant-walkthrough">

    <div class="new-tenant-walkthrough-container">
      <hb-blade-header
        :title="bladeTitle"
        title-icon="mdi-tag"
        @back="goBack"
        :back-button-off="!canNavigate"
        @close="close"
      >
          <template v-slot:right> 
              <!-- <hb-btn class="mr-1" icon tooltip="Property Info" :active="summary_panel === 'summary'" @click="summary_panel = summary_panel === 'summary' ? null : 'summary'" v-show="step < 7" hover-background-color="#CAEAEA">mdi-information-outline</hb-btn> -->

              <hb-btn class="mr-1" icon tooltip="Similar" :active="summary_panel === 'found'" v-show="found.length && step < 7" @click="summary_panel = summary_panel === 'found' ? null : 'found'" hover-background-color="#CAEAEA">mdi-account-tie-voice</hb-btn>
          </template>
      </hb-blade-header>

      <div class="new-tenant-walkthrough-content-section">
        <div class="onboarding-container" :class="{'mr-3 elevation-9': step !== 4}">
          <hb-stepper :value="step">
            <hb-stepper-header :force-resize="resizeStepper">
              <hb-stepper-step
                step="1"
                :complete="step > 1"
                :cursor="false"
              >
                {{(activeTab === 'fees' ? 'Services' : 'Merchandise')}}
              </hb-stepper-step>
              
              <v-divider></v-divider>

              <hb-stepper-step
                step="2"
                :complete="step > 2"
                :cursor="false"
              >
                {{ isReturning ? 'Return' : isBuyingBack ? 'Buyback' : 'Checkout' }} 
              </hb-stepper-step>
            </hb-stepper-header>
          </hb-stepper>

          <div class="walkthrough-body pl-4 mt-3" :class="{'pr-5' : (isReturning || isBuyingBack) && step === 2}">
            <HbPropertySelector
              class="mt-1 mb-1" 
              v-model="property.id"
              id="property" 
              :items="properties" 
              item-text="name" 
              item-value="id"
              v-validate="'required'"
              data-vv-scope="gate" 
              data-vv-name="property"
              data-vv-as="Property" 
              :error="errors.has('gate.property')"
              @change="handlePropertyChangeEvent"
              v-if="step === 1"
            />

            <services
              :lease_id="lease_id"
              :services.sync="services"
              @setServices="setServices"
              @calculateTotal="calculateTotal"
              :property="property"
              :contact="contact"
              :quick_action=" activeDrawerComponent.quick_action"
              @close="close"
              :activeTab="activeTab"
              :isCalculating="isCalculating"
              :isBuyingBack="isBuyingBack"
              :isReturning="isReturning"
              ref="services"
              v-if="step === 1"></services>

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

            <div v-if="step === 2 && (isBuyingBack || isReturning)">
              <div class="hb-font-header-2-medium pb-5 pt-2">Payment Methods</div>
              <ReturnPayment :total-payment="invoice ? invoice.total_due : 0" ref="ReturnPayment"  />
              <div class="new-tenant-walkthrough-footer">
                <hb-bottom-action-bar
                  @close="$emit('close')"
                  :top-border="false"
                >
                  <template v-slot:left-actions>
                    <help-juice />
                  </template>
                  <template v-slot:right-actions>
                    <hb-btn color="primary" :disabled="isReturnLoading" :loading="isReturnLoading" @click="returnPaymentEvent">Return</hb-btn>
                  </template>
                </hb-bottom-action-bar>
              </div>
            </div>

            <payment
              @processPayment="processPayment"
              @close="close"
              @isGuestUserEvent="isGuestUserEvent"
              @saveInvoice="saveInvoice"
              :services.sync="services"
              v-else-if="step === 2"
              :property="property"
              :contact="contact"
              :invoice.sync="invoice"
              :disable_payment="disable_payment"
              :quick_action=" activeDrawerComponent.quick_action"
              :canProgress="canProgress"
              ref="processPayment"
              @selected="selectContact"
              :isCalculating="isCalculating"
              @resetContact="resetContact"
            ></payment>

            <receipt :payment_id="payment.id" @close="close" :is-guest-user="isGuestContact" :merchandise="true" v-if="step === 4"></receipt>
          </div>
        </div>

        <div class="new-tenant-info-panel align-stretch" v-if="step !== 4">
          <transition name="slide-fade">
            <found v-if="summary_panel === 'found'" @selected="selectContact" :contacts.sync="found" @hide="summary_panel = null"></found>
          </transition>
          <transition name="slide-fade" v-if="showSummaryPanel">
            <div>
              <summary-panel @calculateTotal="calculateTotal" :step="step" :is-guest-user="isGuestUser" :contact="contact" :property="property" :services="services" @removeServices="removeService" :invoice="invoice"></summary-panel>
            </div>
        </transition>
        </div>
      </div>
    </div>


  </div>

</template>
<script type="text/babel">

    import api from '../../../assets/api.js';
    import Contact from './Contact.vue';
    import Services from './Services.vue';
    import Payment from './Payment.vue';
    import SummaryPanel from './Summary.vue';
    import Receipt from '../LeadIntake/Receipt.vue';
    import Found from '../LeadIntake/Found.vue';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    import Status from '../../includes/Messages.vue';
    import ReturnPayment from '../../includes/ReturnPayment/Index.vue';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import moment from 'moment';
    import axios from 'axios';
    import https from 'https';
    export default {
        name: 'SellMerchandise',
        mixins: [ notificationMixin ],
        components: {
            Contact,
            Services,
            Payment,
            Found,
            SummaryPanel,
            Receipt,
            Status,
            ReturnPayment
        },
        data: () => ({
            step: 1,
            panel: [0],
            invoice: {},
            contact: {
                id: '',
                first: '',
                last: '',
                email: '',
                company:'',
                rent_as_business:false,
                Phones:[ {
                    type: 'Cell',
                    phone: '',
                    sms: true
                }],
                Addresses: [{
                    id: null,
                    type: 'Home',
                    address_id: '',
                    Address:{
                        address: '',
                        address2: '',
                        city: '',
                        neighborhood: '',
                        lat: '',
                        lng: '',
                        state: '',
                        zip: ''
                    }
                }]
            },
            property: {},
            services: {},
            valid: false,
            drawer: false,
            model: null,
            search: null,
            summary_panel: 'summary',
            unit_id:'',
            payment: {},
            found: [],
            disable_payment: false,
            resizeStepper: 0,
            guestUser: {},
            isGuestUser: true,
            isCalculating: false,
            isReturnLoading: false,
        }),

        props: ["showLeadIntake",  "selected_contact",  "unit", 'lease_id', 'property_id', 'activeTab'],
        filters: {
            truncateText(value) {
                if (!value) return '';
                return value.length > 150 ? value.slice(0, 150) + '...' : value;
            }
        },
        async created(){

            if(this.property_id && this.properties){
                this.property = this.properties.find(p => p.id === this.property_id);
            } else if(this.properties.length === 1){
                this.property = this.properties[0];
            }
            this.setPaymentSource({ paymentSource: "SERVICES" });
            if(this.selected_contact && this.selected_contact.id && this.merchandiseUserEmail == ''){
                this.selectContact(JSON.parse(JSON.stringify(this.selected_contact)));
                this.isGuestUser = false;
            } else {
              this.fetchGuestContact();
            }


          EventBus.$on('paymentReader', this.enablePayment);
          EventBus.$on('reset_buy_services_hb_model', this.close);
          EventBus.$on('HB-Merchandise:found-contact', this.showFoundOptions);
          EventBus.$on('HB-Merchandise:contact-info', this.selectContact);
        },
        mounted () {
          if(this.activeDrawerComponent.type === 'showSellMerchandise') {
            this.contact = this.activeDrawerComponent.contact || this.contact;
            this.invoice = this.activeDrawerComponent.invoice || this.invoice;
            this.services =  this.activeDrawerComponent.services || this.services;
            this.step = this.activeDrawerComponent.step || this.step;
            
            if(this.activeDrawerComponent.payment_id && this.activeDrawerComponent.step === 4) {
              this.payment = {
                id: this.activeDrawerComponent.payment_id
              }
              this.step = 4;
            }
          }

          if (this.services && Object.keys(this.services)?.length && Object.keys(this.services)?.filter(item => item !== 'skip')?.length) {
            this.calculateTotal();
          }
        },
        destroyed(){
            EventBus.$off('paymentReader', this.enablePayment);
            EventBus.$off('reset_buy_services_hb_model', this.close);
            EventBus.$off('HB-Merchandise:found-contact', this.showFoundOptions);
            EventBus.$off('HB-Merchandise:contact-info', this.setContactInfo);
            this.setPaymentConfigProperty({property: 'SHOW_SAVE_PAYMENT', propertyValue: false});
            this.setPaymentConfigProperty({property: 'SHOW_TENANT_NAME', propertyValue: true});
            this.setPaymentSource({ paymentSource: null });

        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
            canNavigate(){
                if(this.step > 1 && this.step < 4) return true;
                return false;
            },
            generatedServices(){
                let s = [];
                for(let key in this.services){
                    if(this.services[key].product_id){
                        this.services[key].cost = this.services[key].price;
                        s.push(this.services[key]);
                    }
                }
                return s;
            },
            showSummaryPanel() {
              let tempServices = this.services;
              let hasSkip = false;
              hasSkip = Object.keys(tempServices).length === 1 && tempServices.hasOwnProperty('skip') ? true : false;
              return this.summary_panel === 'summary' && Object.keys(tempServices).length && !hasSkip && (this.step === 1 || this.step === 2)
            },
            canProgress(){
                for(let s in this.services){
                  if(this.services[s] && this.services[s].qty && this.services[s].price  ) return true;
                }
                return false;
            },
            isGuestContact() {
              return this.contact.email === 'posguestuser@tenantinc.com' && this.isGuestUser;
            },
            isBuyingBack(){
                return this.activeDrawerComponent.is_buying_back ? true : false
            },
            isReturning(){
                return this.activeDrawerComponent.is_returning ? true : false
            },
            bladeTitle(){
              if (this.isBuyingBack) {
                return 'Buyback Merchandise'
              } else if(this.isReturning){
                return 'Return Merchandise'
              }
              return  'Sell ' + (this.activeTab === 'fees' ? 'Services' : 'Merchandise')
            },
            merchandiseUserEmail(){
              if (this.isBuyingBack || this.isReturning) {
                return 'buyback@tenantinc.com'
              }  else if(this.isGuestUser) {
                return 'posguestuser@tenantinc.com';
              }
              return '';
            }
        },
        methods:{
          ...mapActions({
            setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
            resetContactEvent: 'paymentsStore/resetContactEvent',
            setActiveMerchandiseTab: 'navigationStore/SET_ACTIVE_MERCHANDISE_TAB'
          }),
          ...mapMutations({
            setPaymentConfigProperty: 'paymentsStore/setPaymentConfigProperty',
            setPaymentSource: 'paymentsStore/setPaymentSource'
        }),
          async enablePayment(response) {
            this.disable_payment = false;
            if(response && response.error) {
              this.showMessageNotification({ list: [response.error] });
            } else if(response && response.data && response.data.msg) {
              this.showMessageNotification({ type: 'success', description: response.data.msg.text });
              this.step = 4;
              this.setActiveDrawerComponent({ ...this.activeDrawerComponent, step: 4, payment_id:response.data.payment.id})
            }
          },
            async calculateTotal(){
                try {
                  this.isCalculating = true;
                  let r = await api.post(this, api.PROPERTIES + this.property.id + '/invoices', { lease_id: this.lease_id, InvoiceLines: this.generatedServices, dryrun : true});
                  this.invoice = r.invoice;
                } catch (error) {
                  this.showMessageNotification({ description: error });
                } finally {
                  this.isCalculating = false;
                }
            },
            async fetchPropertyConnections(device_id) {
              let r = await api.get(this.$app, api.PROPERTIES + this.property.id + "/connections/address?device_id=" + device_id);
              this.IPaddress = r.IPaddress;
              this.lan = r.lan;
            },

            async processPayment(payment_data){
              let response = {};
              let payloadResponse;
              let lanResponseData;
              let payment_id;
              let responseData;
              try {
                this.disable_payment = true;
                let uri = api.PROPERTIES + this.property.id + `/invoices${this.isGuestUser ? '?user_type=guest' : ''}`
                //Fetch Device IP and Port for Lan Connection
                if(payment_data?.paymentMethod?.device_id)
                  await this.fetchPropertyConnections(payment_data.paymentMethod.device_id);
                if(payment_data.paymentMethod && payment_data.paymentMethod.device_id && this.lan == 1){

                // Create an instance of the HTTPS agent with certificate verification enabled.
                      const certData = ``;

                      const httpsAgent = new https.Agent({
                      ca: [certData],
                      });

                      // Configure Axios to use the custom HTTPS agent.
                      const instance = axios.create({
                      httpsAgent
                      });
                      const endpoint = 'https://' + this.IPaddress + '?';
                      //Get PAX Request Payload from HB Backend server
                      payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",payment_data);
                      payment_id = payloadResponse.payment.id;
                      payment_data.payment.id = payment_id;
                      // Make the GET request using the custom Axios instance.
                      lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
                      payment_data.payment.PAXResponse = lanResponseData;
                      //console.log("lanResponseData :::",lanResponseData);
                      responseData = await api.post(this, uri, {
                            lease_id: this.lease_id,
                            contact: this.contact,
                            payment: payment_data.payment,
                            paymentMethod: payment_data.paymentMethod,
                            InvoiceLines: this.generatedServices,
                            use_credits: payment_data.use_credits,
                            Invoices: payment_data.Invoices,
                        });
                      } else {
                        responseData = await api.post(this, uri, {
                            lease_id: this.lease_id,
                            contact: this.contact,
                            payment: payment_data.payment,
                            paymentMethod: payment_data.paymentMethod,
                            InvoiceLines: this.generatedServices,
                            use_credits: payment_data.use_credits,
                            Invoices: payment_data.Invoices,
                        });
                      }
                        this.payment.id = responseData.payment.id || responseData.payment_id;
                          response = {
                            data: {
                              msg: {
                                id: this.$options.name,
                                text: 'Your Payments have been processed successfully',
                              },
                              payment: responseData.payment
                            }
                          };
                  } catch(err){
                  response = {
                    error: err,
                    contact_id: payment_data.contact_id
                  };
                  if(payment_data.paymentMethod && payment_data.paymentMethod.device_id && this.lan == 1)
                  {
                    responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
                  }
                } finally {
                  this.enablePayment(response);
                }
            },

            selectContact(contact){                
                this.contact = contact;
                if(this.property.id){
                  this.summary_panel = 'summary';
                }
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  contact: this.contact,
                  step: this.step,
                  property_id: this.property.id
                })
            },
            showFoundOptions(found){
              console.log('showFoundOptions :>> ', found);
                if(found.length){
                    this.found = found;
                    this.summary_panel = 'found';
                }
            },

            resetContact() {
              this.contact = {
                  id: '',
                  first: '',
                  last: '',
                  email: '',
                  company:'',
                  rent_as_business:false,
                  Phones:[ {
                      type: 'Cell',
                      phone: '',
                      sms: true
                  }],
                  Addresses: [{
                      id: null,
                      type: 'Home',
                      address_id: '',
                      Address:{
                          address: '',
                          address2: '',
                          city: '',
                          neighborhood: '',
                          lat: '',
                          lng: '',
                          state: '',
                          zip: ''
                      }
                  }]
              };
              this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  contact: this.contact
              });
              this.resetContactEvent();
            },

            setContactInfo(data){
                // if(this.property_id && this.properties){
                //     this.property = this.properties.find(p => p.id === this.property_id);
                // }

                this.summary_panel = 'summary';
                // this.step = 2;
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  contact: this.contact,
                  step: this.step,
                  property_id: this.property.id
                })

                // this.setPropertyDetails(data.property_id)
            },
            async setServices(){
                await this.calculateTotal();
                if(this.services.skip === true){
                  
                  if(this.invoice.InvoiceLines.length && (this.invoice.InvoiceLines.reduce((inital, line) => inital + line.cost, 0) > 0)){
                    try {
                      let data = {
                        dryrun: false,
                        lease_id: this.invoice.lease_id,
                        Contact: this.contact,
                        date: this.invoice.date,
                        due: this.invoice.due,
                        period_start: this.invoice.period_start,
                        period_end: this.invoice.period_end,
                        InvoiceLines: this.invoice.InvoiceLines,
                        Property: this.property,
                      }
                      if(this.contact.NationalAccount.id){
                        const nextMonthLastDate = moment(this.invoice.due).add(1, 'month').endOf('month');
                        data.due = nextMonthLastDate.format('YYYY-MM-DD') 
                      }
                      data.InvoiceLines.property_id=this.Property
                      let r = await api.post(this, api.LEASES + this.invoice.lease_id + '/create-invoice',data );

                      this.showMessageNotification({ type: 'success', description: 'A new invoice has been created.' });
                      EventBus.$emit('payment_saved');
                      await EventBus.$emit('refetch_overview_payments');
                      this.$emit('close');
                      return;
                    } catch(err) {
                      this.showMessageNotification({ list: [{ msg: err }] });
                    }
                  }else{
                      this.showEmptyInvoiceLineWarning =  true
                  
                }
                this.show_invoice = true;
              } else {
                this.step = 2;
              }
              this.setActiveDrawerComponent({
                ...this.activeDrawerComponent,
                invoice: this.invoice,
                services: this.services,
                step: this.step
              })
            },

            close(){
                this.setActiveMerchandiseTab('merchandise');
                this.$emit('close');
            },

            goBack(){
                this.step--;
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  step: this.step
                })
            },
            async getPropertyInfo(){
                let result = await api.get(this, api.PROPERTIES + this.property.id);
                this.property_detail = result.property;
            },
            async removeService(params) {
              if (params) {
                let temp = JSON.parse(JSON.stringify(this.services))
                delete temp[params];
                this.services = temp;
                let hasService = this.services && Object.keys(this.services).length && Object.keys(this.services).filter(item => item !== 'skip').length ? true : false;
                if (hasService) {
                  await this.calculateTotal();
                } else {
                  this.step = 1;
                  this.invoice = {};
                }
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  invoice: this.invoice,
                  services: this.services
                })
              }
            },
            async fetchGuestContact() {
              try {
                let r = await api.get(this, api.CONTACTS + 'check', { contact_id: this.contact.id, email: this.merchandiseUserEmail });
                if (r.contacts?.length) {
                  let contactInfo = r.contacts[0];
                  contactInfo.first = 'Guest';
                  contactInfo.last = 'Checkout';
                  this.selectContact(contactInfo);
                }
              } catch (error) {
                console.error('error :>> ', error);
              } finally {
                this.setPaymentConfigProperty({property: 'SHOW_SAVE_PAYMENT', propertyValue: false});
                this.setPaymentConfigProperty({property: 'SHOW_TENANT_NAME', propertyValue: false});
              }
            },
            async isGuestUserEvent() {
              this.isGuestUser =! this.isGuestUser;
              if (this.isGuestUser) {
                await this.fetchGuestContact();
              } else {
                await this.resetContact(false);
                this.setPaymentConfigProperty({property: 'SHOW_SAVE_PAYMENT', propertyValue: true});
                this.setPaymentConfigProperty({property: 'SHOW_TENANT_NAME', propertyValue: true});
              }
            },
            async handlePropertyChangeEvent() {
              this.invoice = {};
              this.services = {};
              this.setActiveDrawerComponent({
                ...this.activeDrawerComponent,
                invoice: this.invoice,
                services: this.services
              })
              await this.$refs.services.fetchProducts();
            },
            async saveInvoice() {
                this.services.skip = true
                 this.setServices();
            },
            async returnPaymentEvent() {
              try {
                const status = await this.$refs.ReturnPayment.validateData(); 
                if (!status) return;
                this.isReturnLoading = true;
                let returnPaymentData = await this.$refs.ReturnPayment.getReturnPaymentData();
                let returnPaymentType = await this.$refs.ReturnPayment.getReturnPaymentType();
                let paymentDefault = {
                  "payment_method_id": null,
                  "payment_method_type": returnPaymentType
                }
                let contactData = null;
                if(returnPaymentType == 'directdeposit') {
                  contactData =  {
                    id: '',
                    first: returnPaymentData?.firstName,
                    last: returnPaymentData?.lastName,
                    email: returnPaymentData?.email,
                    company_id: localStorage.getItem('company'),
                    Phones:[ {
                      phone: returnPaymentData?.phone
                    }]
                  }
                }
                let payment_details = {...paymentDefault, ...returnPaymentData};
                let payload = {
                  "property_id": this.property.id,
                  "type": this.isReturning ? "return" : "buyback",
                  "contact_id": this.contact.id,
                  "total_price": this.invoice.sub_total,
                  "total_tax": this.invoice.total_tax,
                  "grant_total_amount": this.invoice.total_due,
                  payment_details,
                  "contact": contactData,
                  "products": this.invoice.InvoiceLines.map(item => {
                    let tax = parseFloat(item.totalTax/item.qty);
                    tax = isNaN(tax) ? 0 : tax;
                    return {
                      "product_id": item.product_id,
                      "quantity": item.qty,
                      "price": item.cost,
                      "tax": tax,
                      "cost_per_item": item.cost + tax,
                      "reason": "Buying back"
                    }
                  })
                };
                await api.post(this, api.MERCHANDISE + 'buyback-merchandise', payload).then(r => {
                  this.showMessageNotification({type: 'success', description: 'Return was successful.'});
                });
              } catch (error) {
                console.log('Merchandise --> index --> returnPaymentEvent :>> ', error);
              } finally {
                this.isReturnLoading = false;
              }
          }
        },
        watch:{
            "property.id"(value){
                // console.log("value", value);
                this.property = JSON.parse(JSON.stringify(this.properties.find(p => p.id === value)));
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  property_id: this.property.id
                })
                // this.getPropertyInfo();
            },
            summary_panel(){
                this.resizeStepper++;
            }
        }
    }

</script>

<style scoped>
  .new-tenant-walkthrough-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .new-tenant-walkthrough-content-section{
    align-items:stretch;
    display: flex;
    overflow: hidden;
    height: 100%;

  }
  .new-tenant-walkthrough{
    background: #F9FAFB;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .new-tenant-info-panel{
    flex: 0 0 510px;
    height: 100%;
    background-color: #F9FAFB;
    overflow: scroll;
    padding-bottom: 60px;
    /* border-left: 1px solid #E1E6EA; */
  }
  .walkthrough-body{
    padding: 0px;
    flex: 1;
  }

  .nav-drawer-header{
    flex: 0 1 auto
  }
  .onboarding-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    flex: 1;
  }

  .facility-text{
    font-size:14px;
  }
</style>



<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>