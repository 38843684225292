<template>
  <div class="px-2">
    <v-row class="mx-0">
        <div class="v-card__title pa-0">
            <span class="hb-font-body-medium d-flex align-center">
                <!-- <hb-icon v-if="isEdit" @click="clearSelected()" color="#101318" class="ml-n2 hand-pointer">mdi-chevron-left</hb-icon> -->
                <!-- <hb-icon v-else  @click="$emit('hideAction')" color="#101318" class="ml-n2 hand-pointer">mdi-chevron-left</hb-icon> -->
                Add Rent Change Notification
            </span>
        </div>
    </v-row>

    <!-- <v-col md="12" class="px-0 mt-n2 pt-0 pb-0">
        <v-select
            :items="actionItems"
            label="Select Action"
            v-model="selected"
            item-text="text"
            item-value="key"
            return-object
            id="actions"
            name="actions"
            hide-details
            single-line
            flat
            class="input-font-size"
        ></v-select>
    </v-col> -->

    <v-row class="pt-0 mx-0">
        <v-select
            :items="documents"
            v-model="Template"
            v-validate="'required'"
            item-text="name"
            item-value="id"
            label="Select Document"
            id="name"
            name="name"
            data-vv-as="Document"
            :no-data-text="documentText"
            data-vv-scope="generate_document"
            :hide-details="!errors.collect('generate_document.name').length"
            :error-messages="errors.collect('generate_document.name')"
            single-line
            return-object
            flat
            class="input-font-size"
        >
            <template v-slot:append-item>
                <div v-intersect="getDocumentTemplates" class="d-flex justify-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        v-if="loadingDocumentTemplates"
                    ></v-progress-circular>
                </div>
            </template>
        </v-select>
    </v-row>

    <v-row class="pt-4 mx-0" v-if="selectedDelivery.active">
        <v-col cols="5" class="pa-0 pt-1"><span class="font-weight-medium">Delivery Method:</span></v-col>
        <v-col cols="6" class="pa-0 px-1">
        <v-select
            :items="deliveryMethods"
            v-model="selectedDelivery.id"
            v-on:change="changeDeliveryMethod"
            v-validate="'required'"
            item-text="name"
            item-value="id"
            id="delivery_method"
            name="delivery_method"
            data-vv-as="Delivery Method"
            :no-data-text="deliveryMethodText"
            data-vv-scope="generate_document"
            :hide-details="!errors.collect('generate_document.delivery_method').length"
            :error-messages="errors.collect('generate_document.delivery_method')"
            single-line
            flat
            class="input-font-size mt-0 pt-0"
        >
        </v-select>
        </v-col>
        <v-col class="pa-0 pt-1" cols="1" @click="deleteDeliveryMethod"><hb-icon>mdi-close</hb-icon></v-col>
    </v-row>

    <v-row class="pt-4" v-if="showAddDeliveryMethod">
        <hb-link @click="addDeliveryMethod" color="primary">+ Add Delivery Method</hb-link>
    </v-row>

    <template v-if="selectedDelivery.active">


    <v-row class="pt-0 pb-3 mx-0" v-if="selectedDelivery.method && (selectedDelivery.method  == 'standard_email' || selectedDelivery.method  == 'registered_email')">
        <v-text-field
            label="Subject Line"
            v-model="selectedDelivery.subject"
            v-validate="'required|max:78'"
            id="subject"
            name="subject"
            data-vv-as="Subject"
            data-vv-scope="generate_document"
            :hide-details="!errors.collect('generate_document.subject').length"
            :error-messages="errors.collect('generate_document.subject')"
            single-line
            flat
            class="input-font-size"
        ></v-text-field>
    </v-row>

    <v-row class="mx-0 px-0" v-if="selectedDelivery.method && (selectedDelivery.method  == 'standard_email' || selectedDelivery.method  == 'registered_email')">
        <rich-text-editor 
            v-model="selectedDelivery.message"
        />
    </v-row>

    <v-row class="mx-0" v-if="selectedDelivery.method && (selectedDelivery.method == 'standard_email' || selectedDelivery.method == 'standard_sms' || selectedDelivery.method  == 'registered_email')">
        <hb-link
            :ripple="false"
            color="primary"
            text
            class="pa-0 pt-2 merge-fields"
        >
            <span
                v-if="!showMergeFields"
                @click="showMergeFields = true"
            >Show Available Merge Fields</span>
            <span
                v-if="showMergeFields"
                @click="showMergeFields = false"
            >Hide Available Merge Fields</span>
        </hb-link>
        <span style="margin: 7px 0 0 2px">
            <hb-tooltip>
                <template v-slot:body>
                    Use Merge Fields in your message to insert
                    <br />data about your tenants. By using Merge
                    <br />Fields, you can send automated messages
                    <br />to your tenants without having to manually
                    <br />enter their information every time. Merge
                    <br />Fields appear in brackets.  
                    <br /><br />
                    In order to use a merge field simply copy
                    <br />and paste the text in brackets and add into
                    <br />your message.
                    <br /><br />
                    Example: Hello [Tenant.FirstName] [Tenant.LastName],
                    <br />you owe [Tenant.TotalPastDue]. Please pay
                    <br />by [Tenant.RentDueDate]. 
                </template>
            </hb-tooltip>
        </span>
    </v-row>

    <div v-if="showMergeFields && (selectedDelivery.method == 'standard_email' || selectedDelivery.method == 'standard_sms' || selectedDelivery.method  == 'registered_email')" class="merge-fields-list">
        <merge-fields  v-if="showMergeFields" />
    </div>

    </template>
    <!-- <v-row class="mx-0 mt-1">
        
        <v-col cols="3" class="px-0 pt-6" v-if="selected && (selected.key == 'send_email')">
             <span class="d-inline-block top-n7px">
                <v-icon color="primary" >mdi-paperclip</v-icon>
            </span>
        </v-col>
        

        <v-col :cols="(selected && (selected.key == 'send_email')) ? '9': '12'" class="px-0">
        <span>
            <hb-btn color="primary" @click="save()">{{ isEdit ? 'Update Action' : 'Add Action' }}</hb-btn>
        </span>
        <a class="ml-2" v-if="isEdit" @click="clearSelected()">Cancel</a>
        <a class="ml-2" v-else @click="$emit('hideAction')">Cancel</a>
        </v-col>
    </v-row> -->

  </div>
</template>

<script>

import api from "../../assets/api.js";
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import MergeFields from "../includes/MergeFields.vue";
import RichTextEditor from '../assets/RichTextEditor.vue';

export default {
    name: "AddAction",
    props: ['actionSelected','documents', 'actionList', 'property_id', 'autoSave'],
    mixins: [ notificationMixin ],
    data() {
        return {
            isEdit: false,
            showDeliveryMethod: false,
            showAddDeliveryMethod: true,
            actionItems : [],
            selected: {
                key: 'generate_document'
            },
            deliveryMethods: [],
            delivery_method: {},
            selectedDelivery: {
                id: '',
                method: '',
                subject: '',
                message: "[Tenant.FirstName] [Tenant.LastName],",
                active: 0
            },
            showMergeFields: false,
            // documents: [],
            Template: null,
            Email: {
                subject: null,
                message: "[FIRSTNAME] [LASTNAME],",
            },
            Text: {
                content: null
            },
            loadingDocumentTemplates: false,
            allowScrollRequest: true,
            documentText: 'Loading documents...',
            deliveryMethodText: 'No Delivery Methods for this Property'
        }
    },
    async created() {
        await this.setDocuments([]);
        await this.setDeliveryMethods([]);
        this.populateMenuSelections();
        //EventBus.$on("save_action", this.save);
    },
    components:{
        MergeFields,
        'rich-text-editor': RichTextEditor
    },
    mounted() {
        // if (this.actionSelected) {
        //     this.isEdit = true;
        //     //this.selected = JSON.parse(JSON.stringify(Object.assign({}, this.actionSelected)));
        //     if(this.selectedDelivery.method === 'send_email') {
        //         this.selectedDelivery.subject = 
        //         this.Email = {
        //             subject: this.selected.subject,
        //             message: this.selected.message
        //         } 
        //     }else {
        //         let document = this.documents.find(doc => { return doc.id == this.selected.document_id});
        //         this.Template = {
        //             id: this.selected.document_id,
        //             name: document.name
        //         }
        //     }
            
        // }
    },
    // watch: {
    //     actionSelected(value){
    //         this.isEdit = true;
    //         this.selected = JSON.parse(JSON.stringify(Object.assign({}, value)));
    //     }
    // },
    computed:{
        ...mapGetters({
            getDocuments: 'documentsStore/getDocuments',
            getDeliveryMethods: 'documentsStore/getDeliveryMethods',
            getDeliveryMethod: 'documentsStore/getDeliveryMethod'
        }),
    },
    methods: {
        ...mapActions({
            fetchDocumentsData: 'documentsStore/fetchDocuments',
            fetchDeliveryMethods: 'documentsStore/fetchDeliveryMethods'
        }),
        ...mapMutations({
            setDocuments: 'documentsStore/setDocuments',
            setDeliveryMethods: 'documentsStore/setDeliveryMethods'
        }),
        async fetchDocuments() {
            this.loadingDocumentTemplates = true;
            let limit = 200;
            let offset = this.getDocuments.length == 0 ? 1 : Math.ceil(this.getDocuments.length / limit) + 1 ;
            let path = (this.property_id) ? `&property_ids[]=${this.property_id}` : '';
            let data = {
                component: this,
                params: api.DOCUMENT + `?type[]=rent-change&limit=${limit}&offset=${offset}` + path
            }
            try {
                const currentDocuments = await this.fetchDocumentsData(data);
                if(currentDocuments.length === 0){
                  this.allowScrollRequest = false;
                }
                if(this.getDocuments.length === 0){
                    this.documentText = 'No data found.'
                }
                this.$emit('update:documents', this.getDocuments);
                this.loadingDocumentTemplates = false;
            } catch(err) {
                this.showMessageNotification({ description: err });
                this.loadingDocumentTemplates = false;
            }
        },
        async addDeliveryMethod(){
            this.selectedDelivery.active = 1;
            this.showAddDeliveryMethod = false;
            //85B73ubBGy
            await this.fetchDeliveryMethods(this.property_id);
            this.deliveryMethods = await this.getDeliveryMethods;
        },
        deleteDeliveryMethod(){
            //this.selectedDelivery.active = 0;
            //this.selectedDelivery.name = '';
            this.selectedDelivery = {
                id: '',
                method: '',
                subject: '',
                message: "[Tenant.FirstName] [Tenant.LastName],",
                active: 0
            }
            this.showAddDeliveryMethod = true;
            //this.selectedDelivery.id = null;
        },
        async changeDeliveryMethod(){
            await this.$store.dispatch('documentsStore/getDeliveryMethodById', this.selectedDelivery.id);
            
            if(this.getDeliveryMethod.gds_key){
                this.selectedDelivery.method = this.getDeliveryMethod.gds_key;
            }else{
                if(this.getDeliveryMethod.delivery_type == 'email') this.selectedDelivery.method = 'standard_email' 
                else if(this.getDeliveryMethod.delivery_type == 'sms') this.selectedDelivery.method = 'standard_sms'
            }
        },
        clearSelected(){
            this.isEdit = false;
            this.selected = {};
            this.$emit('hideAction');
        },
        async save(){
            let status = await this.$validator.validateAll(
                this.selected.key
            );
            if (!this.selected.key || !status) {
                return;
            }
            if(this.selected.key === 'generate_document') {
                this.$emit('fetchData', {
                    type: this.selected.key,
                    document_id: this.Template.id,
                    name: 'Generate Document > ' + this.Template.name,
                    isEdit: this.isEdit,
                    deliveryMethod: this.selectedDelivery
                });
            } 
            // else {
            //     this.Email.action_type = this.selected.key
            //     this.$emit('fetchData', {
            //         ...this.Email,
            //         type: this.selected.key,
            //         name: 'Send Email',
            //         isEdit: this.isEdit
            //     });
            // }
            this.$emit('hideAction');
        },
        getDocumentTemplates(entries, observer, isIntersecting){
            if (isIntersecting && this.allowScrollRequest) {
                this.loadingDocumentTemplates = true;
                this.fetchDocuments();
            }
        },
        populateMenuSelections(){
            var generateDocumentAlreadySelected = false;
            var sendEmailAlreadySelected = false;

            if(this.actionList){
                for(var i = 0; i < this.actionList.length; i++) {
                    if( this.actionList[ i ].type === 'generate_document' ) {
                        var generateDocumentAlreadySelected = true;
                    }
                    if( this.actionList[ i ].type === 'send_email' ) {
                        var sendEmailAlreadySelected = true;
                    }
                }
            }

            if(!generateDocumentAlreadySelected || (this.actionSelected && this.actionSelected.key && this.actionSelected.key === 'generate_document')){
                this.actionItems.push({
                    key: 'generate_document',
                    text: 'Generate Document'
                },)
            }

            if(!sendEmailAlreadySelected || (this.actionSelected && this.actionSelected.key && this.actionSelected.key === 'send_email')){
                this.actionItems.push({
                    key: 'send_email',
                    text: 'Send Email'
                },)
            }
        },
    },
    watch: {
        actionList(){
            this.populateMenuSelections();
        },
        property_id: {
            async handler(newVal, oldVal) {
                this.allowScrollRequest = true;
                await this.setDocuments([]);
                await this.setDeliveryMethods([]);
                this.$emit('update:documents', this.getDocuments);
                await this.fetchDeliveryMethods(this.property_id);
                this.deliveryMethods = await this.getDeliveryMethods;
            },
            immediate: true
        }
    },
}
</script>

<style scoped>
    .top-n7px{
        position: relative;
        top: -7px;
    }
</style>
