<template>
    <div id="mini-charm-notifications">
        <div>
            <charm-call ref="charmCallRef" :class="getInSnappedZone ? 'pb-4' : 'pb-0'" v-if="hasPermission('mini_charm') && hasCharmSubscribedPropertySelected"></charm-call>
            <div ref="scrollWrapper" class="success-error-notifications" :style="{ 'max-height': scrollerHeight }">
                <charm-notifications class="pb-4" v-if="hasPermission('mini_charm') && isCharmNotificationToastOpen"></charm-notifications>
                <div v-for="(item, index) in getAllNotifications">
                    <GlobalNotification :class="index == getAllNotifications.length - 1 && 'lastNotification'" v-model="item.value" :type="item.type"
                        :description="item.description" :list="item.list && item.list.length ? item.list : []"
                        :icon="item.successErrorIcon" :complete="item.complete"
                        :status="item.status" :notification="item" @close="close(item.id)" @notificationClick="handleNotificationClick(item)">
                        <span v-if="item.successErrorContent" v-html="item.successErrorContent"></span>
                        <template v-slot:footer>
                            <v-card-actions v-if="item.action" :style="{ 'background-color': item.footerBackgroundColor }">
                                <v-spacer></v-spacer>
                                <hb-btn small color="secondary" @click="handleButtonClick(item)">
                                    {{ item.action.text }}
                                </hb-btn>
                            </v-card-actions>
                        </template>
                        <template v-if="item.progress" v-slot:progress>
                            <v-progress-linear :value="Math.min(item.percentage, 95)" color="primary" height="8" class="mt-2 mb-1" >
                            </v-progress-linear>
                        </template>
                        <template v-if="item.note && item.note.length" v-slot:note>
                            <div>{{item.note}}</div>
                        </template>
                    </GlobalNotification>
                </div>
                <div v-show="hasScroll" ref="scrollButtonWrapper" class="scroll-button-wrapper">
                    <hb-btn color="secondary" :prepend-icon="scrollButtonIcon" @click="handleScrollButtonClick">{{ scrollButtonText }}</hb-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../../EventBus.js";
import CharmCall from "../charm/CharmCall.vue";
import CharmNotifications from "../charm/CharmNotifications.vue";
import GlobalNotification from "./GlobalNotification.vue";
import { notificationMixin } from "../../mixins/notificationMixin.js";
import { downloadFile } from "../../utils/common.js";

export default {
    data() {
        return {
            hasScroll: false,
            scrollPosition: 0,
            charmCallHeight: 0,
            navbarHeight: 64
        };
    },
    mixins: [notificationMixin],
    computed: {
        ...mapGetters({
            getAllNotifications: "globalNotificationsStore/getAllNotifications",
            getInSnappedZone: "globalNotificationsStore/getInSnappedZone",
            isCharmNotificationToastOpen: "charmNotificationStore/isCharmNotificationToastOpen",
            hasPermission: "authenticationStore/rolePermission",
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            hasCharmSubscribedPropertySelected: 'charmSubscriptionStore/hasCharmSubscribedPropertySelected',
            isCharmCallToastOpen: "charmCallStore/isCharmCallToastOpen",
        }),
        scrollerHeight() {
            let scrollerHeight = 0;
            let adjustBottom = 12;
            if(this.isCharmCallToastOpen && this.getInSnappedZone){
                scrollerHeight = `calc(100vh - ${this.charmCallHeight + this.navbarHeight + adjustBottom}px);calc(100svh - ${this.charmCallHeight + this.navbarHeight + adjustBottom}px);`;
            } else {
                scrollerHeight = `calc(100vh - ${this.navbarHeight + adjustBottom}px);calc(100svh - ${this.navbarHeight + adjustBottom}px);`;
            }
            return scrollerHeight;
        },
        scrollButtonIcon() {
            return this.scrollPosition <= 50 ? 'mdi-chevron-down' : 'mdi-chevron-up';
        },
        scrollButtonText() {
            return this.scrollPosition <= 50 ? 'Scroll Down' : 'Scroll Up';
        }
    },
    components: {
        CharmCall,
        CharmNotifications,
        GlobalNotification
    },
    mounted(){
       this.$refs.scrollWrapper.addEventListener('scroll', this.moveScroller);
    },
    updated() {
        this.$nextTick(() => {
            if (this.$refs.scrollWrapper.length > 0) {
                const el = this.$refs.scrollWrapper;
                this.hasScroll = el.scrollHeight > el.clientHeight;
                this.charmCallHeight = this.$refs.charmCallRef.$el.offsetHeight
            }
        })
    },
    methods: {
        ...mapActions({
            removeGlobalNotificationById: "globalNotificationsStore/removeGlobalNotificationById",
            removeSmsAndEmailNotifications: "globalNotificationsStore/removeSmsAndEmailNotifications",
        }),
        close(id) {
            this.removeGlobalNotificationById(id)
        },
        handleNotificationClick(notification) {
            switch(notification.type){
                case 'sms':
                case 'email':
                    let div_collection = document.getElementsByClassName('v-overlay v-overlay--active') || [];
                    this.close(notification.id);
                    this.removeSmsAndEmailNotifications();
                    if(!this.activeDrawerComponent.type && !this.activeDrawerComponent.rent_raise_drawer && div_collection.length === 0) {
                        EventBus.$emit('HB-Navigation:CommunicationCenter', notification);
                    }
                default:
                break;
            }
        },
        handleButtonClick(notification){
            switch(notification.action.name){
                case 'download_report':
                    const { content_type, file, filename } = notification;
                    downloadFile({
                        content_type,
                        file,
                        filename,
                        url: file.url,
                    });
                    break;
                default:
                    break;
            }
        },
        handleScrollButtonClick() {
            const el = this.$refs.scrollWrapper;
            if (el) {
                if (this.scrollPosition <= 50) {
                    el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
                } else {
                    el.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }
        },
        moveScroller({ target: { scrollTop, scrollHeight, clientHeight } }) {
            this.scrollPosition = (scrollTop / (scrollHeight - clientHeight)) * 100;
            this.updateScrollButtonPosition();
        },
        updateScrollButtonPosition(){
            this.$nextTick(() => {
                if (this.scrollPosition <= 50) {
                    this.$refs.scrollButtonWrapper.style.bottom = '10px';
                    this.$refs.scrollButtonWrapper.style.top = 'unset';
                } else if (this.scrollPosition > 50) {
                    let buttonOffsetHeight = 58;
                    this.$refs.scrollButtonWrapper.style.bottom = (this.isCharmCallToastOpen && this.getInSnappedZone) ? `calc(100vh - ${this.charmCallHeight + this.navbarHeight + buttonOffsetHeight}px);calc(100svh - ${this.charmCallHeight + this.navbarHeight + buttonOffsetHeight}px)` : `calc(100vh - ${this.navbarHeight + buttonOffsetHeight}px);calc(100svh - ${this.navbarHeight + buttonOffsetHeight}px)`;
                    this.$refs.scrollButtonWrapper.style.top = 'unset';
                } else {
                    this.scrollPosition = 0
                    this.$refs.scrollButtonWrapper.style.bottom = '10px';
                    this.$refs.scrollButtonWrapper.style.top = 'unset';
                }
            });
        },
    },
    watch: {
        isCharmNotificationToastOpen(nv) {
            this.$nextTick(() => {
                this.hasScroll = this.$refs.scrollWrapper.scrollHeight > this.$refs.scrollWrapper.clientHeight;
            })
        },
        isCharmCallToastOpen(nv) {
            this.$nextTick(() => {
                const el = this.$refs.scrollWrapper;
                this.scrollPosition = (el.scrollTop / (el.scrollHeight - el.clientHeight)) * 100;
                this.updateScrollButtonPosition();
            })
        }
    },
};
</script>

<style lang="scss" scoped>
#mini-charm-notifications {
    position: fixed;
    top: 70px;
    right: 22px;
    height: min-content;
    max-height: 100.1%;
    z-index: 1000;

    .success-error-notifications {
        overflow-y: auto;

        &::-webkit-scrollbar {
            background-color: transparent !important;
            width: 8px;
        }

        /* background of the scrollbar except button or resizer */
        &::-webkit-scrollbar-track {
            background-color: transparent !important;
        }

        /* scrollbar itself */
        &::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 16px;
        }

        /* set button(top and bottom of the scrollbar) */
        &::-webkit-scrollbar-button {
            display: none;
        }
    }

    .scroll-button-wrapper {
        position: absolute;
        width: max-content;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1001;
        bottom: 10px;
    }
}
</style>
<style>
.success-error-notifications .success-error-wrapper .v-snack:not(.v-snack--absolute) {
    position: relative;
    height: min-content;
    margin-bottom: 0px;
    padding-top: 0px !important;
    justify-content: flex-start;
}

.success-error-notifications .success-error-wrapper .v-snack__wrapper {
    margin: 0px 0px 16px 0px;
    width: 100% !important;
}

.success-error-notifications .success-error-wrapper .v-snack__content {
    padding: 0 !important;
}

.success-error-notifications .lastNotification .v-snack__wrapper {
    margin: 0px;
}
</style>