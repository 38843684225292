<template>
  <div>
    <div class="send-quote-body-scroll mx-2 mb-2" :style="{ height: reportHeight + 'px' }" v-resize="setHeight">
      <v-expansion-panels elevation="0" class="hb-expansion-panel send-quote-hb-panel-div">
        <div v-for="(property, index) in selectedPropertyUnits" :key="'div_' + property.property.id"
          class="send-quote-hb-panel-div-insdie">
          <div style="width: 94%;">       
              <hb-expansion-panel :key="property.property.id" :left-cols="12" :right-cols="2" style="background: linear-gradient(#FFFFFF 0px, #FFFFFF 52px, #F9FAFB 52px, #F9FAFB 100%);">
                <template v-slot:title>
                  <HbIcon mdi-code="mdi-facility-custom-2" color="#101318" />
                  <span class="mt-1 ml-1 send-quote-property-title">
                    {{ property?.property?.number }} -
                    {{ property?.property?.name }} -
                    {{ property?.property?.Address?.state }}
                  </span>
                  <span class="unites-counter" v-if="property?.units?.length">{{ property?.units?.length }}</span>
                </template>
                <template v-slot:content>
                  <div class="mx-2 mb-2 pa-2">
                    <v-expansion-panels elevation="0" class="hb-expansion-panel">
                      <hb-expansion-panel v-for="unit in property.units" :key="unit.unit_id" :left-cols="10"
                        :right-cols="2">
                        <template v-slot:title>
                          <div class="pa-1">
                            <span class="send-quote-unit-title  ">
                              <HbSpaceIcon :type="unit.unit_type" color="#101318" />
                              <span class="send-quote-unit-header">
                                {{ unit.unit_type }} - {{ unit.unit_size }}
                              </span>
                            </span>
                            <div>
                              <span class="unit-amenities" style="text-align: left !important;">
                                {{ getFirstTwoAmenities(unit?.unit_amenities) }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <template v-slot:actions>
                          <div class="mr-2">
                            <div>
                              <span class="unit-currency-display">
                                <span class="ma-0">{{ formattedUnitPrice("USD", unit.unit_price) }}</span>
                              </span>
                            </div>
                            <div class="pt-2">
                              <hb-link @click="removeUnit(property.property.id, unit.unit_id)">
                                Remove
                              </hb-link>
                            </div>
                          </div>

                        </template>
                        <template v-slot:content>
                          <div class="pa-4">
                            <div class="rent-details">
                              <span class="hb-font-header-3-medium">
                                Rent
                              </span>
                              <span class="hb-font-body">{{ calculateRentPeriod() }}</span>
                              <span class="hb-font-body amount hb-font-header-3-medium" style="text-align: right;">{{
                                formattedUnitPrice("USD", unit.unit_price) }}</span>
                            </div>
                            <div class="mt-3 mb-2" v-if="!(unit?.table_rate_insurance)">
                              <hb-notification :v-model="!unit?.unitLevelInsurances?.length > 0" type="caution" :icon-off="false"
                                :notDismissable="true" v-if="!unit?.unitLevelInsurances?.length > 0">
                                No coverage are available.
                              </hb-notification>
                              <hb-select :v-model="true" :items="unit?.unitLevelInsurances" :clearable="true" full
                                v-if="unit?.unitLevelInsurances?.length > 0" return-object item-text="name"
                                item-value="id" placeholder="Select Coverage" single-line
                                @change="(e) => { onCoverageDiscountSelection(e, property.property.id, unit.unit_id, 'coverage') }"
                                background-color="white">
                                <template v-slot:selection="{ item }">
                                  {{ item.name }} - &nbsp;<span style="color:#101318;">{{ `${item.premium_type
                                    }${item.premium_value}` }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  {{ item.name }}
                                </template>
                              </hb-select>
                            </div>
                            <div class="mt-2" v-if="unit?.table_rate_insurance"><span class="hb-font-header-3-medium">Select Coverage</span></div>
                            <div class="mt-3" v-if="unit?.table_rate_insurance">
                              <table-rate-insurance 
                              :showRadioButton="false"
                              :insurances="unit?.unitLevelInsurances" 
                              :isSelected="true"
                              :coverageOptionType="'send_quote'"
                              :fullWidth="true"
                              @updateCoverageValue="(coverage_value)=>{onCoverageDiscountSelection(coverage_value, property.property.id, unit.unit_id, 'table_rate_coverage')}" />
                            </div>
                            <div v-if="unit?.selectedFees?.length">
                              <fees :feesServices="unit?.selectedFees" :unit_Id="unit?.unit_id"
                                :property_Id="property?.property?.id" :allPropertyUnits="selectedPropertyUnits"
                                type="fees">
                              </fees>
                            </div>
                            <div :class="(unit?.selectedFees?.length)?'mt-0':'mt-3'">
                              <hb-btn
                                @click="(!property?.servicesByProperty?.feesProducts || (property?.servicesByProperty?.feesProducts?.length == unit?.selectedFees?.length)) ? onShowWarning('fee') :showDialogEvent(property?.property?.id, unit?.unit_id, 'fees', property?.servicesByProperty?.feesProducts, unit?.selectedFees)"
                                color="secondary" small>+ Add Fees</hb-btn>
                            </div>
                            <!-- <v-divider v-if="unit?.selectedFees?.length"></v-divider> -->
                            <div v-if="unit?.selectedMerchandise?.length">
                              <merchandise :productServices="unit?.selectedMerchandise" :unit_Id="unit?.unit_id"
                                :property_Id="property?.property?.id" :allPropertyUnits="selectedPropertyUnits">
                              </merchandise>
                            </div>
                            <div :class="(unit?.selectedMerchandise?.length)?'mt-0':'mt-3'">
                              <hb-btn
                                @click="(!property?.servicesByProperty?.merchandiseProducts || (property?.servicesByProperty?.merchandiseProducts?.length == unit?.selectedMerchandise?.length)) ? onShowWarning('merchandise', unit?.selectedDiscount) :showDialogEvent(property?.property?.id, unit?.unit_id, 'merchandise', property?.servicesByProperty?.merchandiseProducts, unit?.selectedMerchandise)"
                                color="secondary" small>+ Add Merchandise</hb-btn>
                            </div>
                            <!-- <v-divider v-if="unit?.selectedMerchandise?.length"></v-divider> -->

                            <div class="mt-3 title-price-align-div">
                              <span class="hb-font-header-3-medium">Subtotal:</span>
                              <span class="hb-font-header-3-medium general-price">{{ calculateUnitSubtotal(unit)
                              }}</span>
                            </div>
                            <v-divider></v-divider>
                            <div :class="(unit?.selectedDiscount) ? 'mt-0' : 'mt-3'" v-if="!unit.isSelectDiscount">
                              <hb-btn
                                @click="(e) => { (unit?.selectedDiscount || !(property?.discounts?.length>0)) ? onShowWarning('discount', unit?.selectedDiscount) : onCoverageDiscountSelection(e, property.property.id, unit.unit_id, 'show_discount') }"
                                color="secondary" small>{{ (unit?.selectedDiscount || !(property?.discounts?.length>0)) ? "Change Discount":"+ Add Discount" }}</hb-btn>
                            </div>
                            <div class="mt-4" v-if="unit.isSelectDiscount">
                              <hb-select :v-model="true" :items="property?.discounts" :clearable="true" full
                                return-object item-text="name" item-value="id" placeholder="Select Discount" single-line
                                @change="(e) => { onCoverageDiscountSelection(e, property.property.id, unit.unit_id, 'discount') }" background-color="white">
                                <template v-slot:selection="{ item }">
                                  {{ item.name }} - &nbsp;<span style="color:#101318;">{{
                                    (item.type == 'percent') ? `${item.value}%` : `$${item.value}` }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span class="hb-font-body-medium hb-text-night">{{ item?.name }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <span class="hb-font-caption hb-text-night-light">{{ item?.description }}</span>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </hb-select>
                            </div>
                            <!-- <v-divider v-if="unit?.selectedPromotions?.length" class="mt-3"></v-divider> -->
                            <div v-if="unit?.selectedPromotions?.length" class="mt-2">
                              <fees :feesServices="unit?.selectedPromotions" :unit_Id="unit?.unit_id"
                                :property_Id="property?.property?.id" :allPropertyUnits="selectedPropertyUnits"
                                type="promotion">
                              </fees>
                            </div>
                            <div class="mt-4" v-if="unit.isSelectPromo">
                              <hb-select :v-model="true" :items="unit?.filteredPromotions" :clearable="true" full
                                return-object item-text="name" item-value="id" placeholder="Select Promotion"
                                single-line
                                @change="(e) => { onCoverageDiscountSelection(e, property.property.id, unit.unit_id, 'promotion') }"
                                background-color="white">
                                <template v-slot:selection="{ item }">
                                  {{ item.name }} - &nbsp;<span style="color:#101318;">{{
                                    (item.type == 'percent') ? `${item.value}%` : `$${item.value}` }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span class="hb-font-body-medium hb-text-night">{{ item?.name }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <span class="hb-font-caption hb-text-night-light">{{ item?.description }}</span>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </hb-select>
                            </div>
                            <div :class="(unit?.selectedPromotions?.length) ? 'mt-0' : 'mt-3'" v-if="!unit.isSelectPromo">
                              <hb-btn
                                @click="(!unit?.unitLevelPromotions || (unit?.unitLevelPromotions?.length == unit?.selectedPromotions?.length)) ? onShowWarning('promotion', unit?.selectedDiscount) :onCoverageDiscountSelection(null, property.property.id, unit.unit_id, 'show_promotion')"
                                color="secondary" small>+ Add Promotion</hb-btn>
                            </div>
                            <div class="mt-3 title-price-align-div">
                              <span class="hb-font-header-3">Tax:</span>
                              <span class="hb-font-header-3 general-price">{{ taxUnitCalculation(unit,
                                property.propertyTax)
                              }}</span>
                            </div>
                            <div class="mt-3 title-price-align-div">
                              <span class="hb-font-header-3-medium">Total Charges:</span>
                              <span class="hb-font-header-3-medium general-price">{{ totalUnitCalculation(unit,
                                property.propertyTax) }}</span>
                            </div>
                          </div>
                        </template>

                      </hb-expansion-panel>
                    </v-expansion-panels>

                  </div>
                </template>
              </hb-expansion-panel>
            </div>
            <div  class="pt-4" style="width: 1%;">
              <HbBtn class="mt-4 mr-0 ml-0" icon tooltip="Close" mdi-code="mdi-close-circle"
                @click="removeProperty(property.property.id)" />
            </div>
        </div>
      </v-expansion-panels>
    </div>
    <div class="send-quote-total-cal pl-5 pr-4 pt-1 pb-1">
      <div class="title-price-align-div">
        <span class="hb-font-header-3-medium">Est. Total Balance Due</span>
        <span class="hb-font-header-3-medium general-price">{{ calculateGrandTotal(selectedPropertyUnits) }}</span>
      </div>
      <div class="title-price-align-div">
        <span class="hb-font-body-medium">Est. Monthly Rent</span>
        <span class="hb-font-body-medium general-price">{{ calculateTotalRent(selectedPropertyUnits) }}</span>
      </div>
    </div>
    <add-service :products-list="productsList" :services="existingProductsList"
      @addSelectedServiceProd="addServiceProductListToUnit" :dialog-type="dialogType" v-model="productsDialog" />
  </div>
</template>

<script type="text/babel">
import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js'
import AddService from './SendQuoteAddServiceModal.vue'
import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import Merchandise from './SendQuoteMerchandise.vue';
import Fees from './SendQuoteFeesPromoAndDiscount.vue';
import TableRateInsurance from '../../table_rate_insurance/Index.vue';

export default {
  name: 'SendQuoteSummary',
  components: {
    AddService,
    Merchandise,
    Fees,
    TableRateInsurance
  },
  data: () => ({
    key_setup: 0,
    setupPropertyModal: [],
    productsDialog: false,
    reportHeight: 0,
    dialogType: '',
    productsList: [],
    existingProductsList: [],
    tempUnitId: '',
    tempPropId: ''
  }),
  mixins: [notificationMixin],
  async created() {
    this.setHeight();
  },
  computed: {
    ...mapGetters({

    }),
  },
  filters: {

  },
  props: ['search', 'condition', 'allSpaces', 'selectedPropertyUnits', 'isSendQuoteLoading'],
  methods: {
    getFirstTwoAmenities(amenities) {
      if (!amenities) return '';
      return amenities.split(',').slice(0, 2).join(' | ');
    },
    formattedUnitPrice(currency, unit_price) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(unit_price).replace(currency, `${currency}`);
    },
    calculateRentPeriod() {
      const options = { month: 'short', day: 'numeric' };

      // Get current date
      const currentDate = new Date();

      // Add 30 days to the current date
      const endDate = new Date();
      endDate.setDate(currentDate.getDate() + 30);

      // Format dates without the year
      const formattedStart = currentDate.toLocaleDateString('en-US', options);
      const formattedEnd = endDate.toLocaleDateString('en-US', options);

      // Return formatted string
      return `(${formattedStart} - ${formattedEnd})`;
    },
    setHeight() {
      let header = 72;
      let stepper = 100;
      let tabs = 48;
      let footer = 80;
      let heading = 122;
      let padding = 20;
      this.reportHeight = window.innerHeight - header - stepper - tabs - heading - footer - padding;
    },
    removeUnit(propId, unitId) {
      EventBus.$emit('remove_selected_unit', propId, unitId);
    },
    removeProperty(propId) {
      EventBus.$emit('remove_selected_property', propId);
    },
    roundToTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    },
    roundAmount(amount, roundingType) {
      switch (roundingType) {
        case "nearest_full":
          return parseFloat(Math.round(amount)).toFixed(2);
        case "nearest_half":
          return parseFloat(Math.round(amount * 2) / 2).toFixed(2);
        case "up_full":
          return parseFloat(Math.ceil(amount)).toFixed(2);
        case "up_half":
          return parseFloat(Math.ceil(amount * 2) / 2).toFixed(2);
        case "down_full":
          return parseFloat(Math.floor(amount)).toFixed(2);
        case "down_half":
          return parseFloat(Math.floor(amount * 2) / 2).toFixed(2);
        case null: // No Rounding
        default:
          return amount?parseFloat(amount).toFixed(2):0;
      }
    },
    async onShowWarning(type, data) {
      let description = '';
      if (type == 'discount' && data) {
        description = 'Only one discount can be applied at a time. To apply a new discount, please remove the existing one.';
      } else if (type == 'discount') {
        description = 'No fee are available.'
      } else if (type == 'fee') {
        description = 'No fee are available.'
      } else if (type == 'merchandise') {
        description = 'No merchandise are available.'
      } else if (type == 'promotion') {
        description = 'No promotion are available.'
      }
      this.showMessageNotification({ type: 'error', description: description });
    },
  async calculatePremium(coverageNeeded, coverageData) {
    let total = 0;
    for (let item of coverageData) {
      if (coverageNeeded >= item.coverage_limit_start) {
        let coverageInThisTier = Math.min(coverageNeeded, item.coverage_limit) - item.coverage_limit_start;
        coverageInThisTier +=1000;
        if (coverageInThisTier > 0) {
          total += (coverageInThisTier / item.coverage_unit) * item.premium_rate;
        }
      }
    }
    return total;
  },
  async onCoverageDiscountSelection(e, propId, unitId, type, promId) {
      // Create a deep copy of selectedPropertyUnits to avoid direct mutation
      let parsedData = JSON.parse(JSON.stringify(this.selectedPropertyUnits));

      // Iterate over the parsedData to find the matching property and unit
      for (let pData of parsedData) {
        if (pData?.property?.id == propId) {
          for (let uData of pData.units) {
            if (uData?.unit_id == unitId) {
              
              if (type == "show_promotion") {
                uData.isSelectPromo = true;
              } else if (type == "promotion") {
                let allPromotion = [...(uData?.unitLevelPromotions || [])];
                if (e) {
                  let unitPrice = parseFloat(uData?.unit_price || 0);
                  let promoPer = parseFloat(e?.value || 0);
                  e.price = (e.type == "percent") ? Number(this.roundAmount(unitPrice * (promoPer / 100), e.rounding)): Number(this.roundAmount(promoPer, e.rounding));
                  uData.selectedPromotions.push(e);
                } else {
                  uData.selectedPromotions = uData.selectedPromotions.filter(promoData => promoData.id !== promId);}
                uData.filteredPromotions = allPromotion.filter(item => !uData.selectedPromotions.some(subItem => subItem.id == item.id)
                );
                uData.isSelectPromo = false;
              } else if (type == "show_discount") {
                uData.isSelectDiscount = true;
              } else if (type == "discount") {
                if (e) {
                  let unitPrice = parseFloat(uData?.unit_price || 0);
                  let discountPer = parseFloat(e?.value || 0);
                  e.price = (e.type == "percent") ? Number(this.roundAmount(unitPrice * (discountPer / 100), e.rounding)) :  Number(this.roundAmount(discountPer, e.rounding));
                }
                uData.selectedDiscount = e;
                // uData.isSelectDiscount = false;
              } else if (type == "coverage") {
                uData.selectedInsurance = e;
              }else if (type == "table_rate_coverage") {
                let tableRateInsurance = null;
                if (uData?.unitLevelInsurances?.length > 0) {
                  let coverageTiers = uData.unitLevelInsurances[0].TableRateTiers;
                  let premium_rate = await this.calculatePremium(e, coverageTiers);
                  let matchingTier = coverageTiers.find(tier => 
                    e >= tier.coverage_limit_start && e <= tier.coverage_limit
                  );

                  if (matchingTier) {
                    tableRateInsurance = {
                      name: `Coverage (${uData.unitLevelInsurances[0].name})`,
                      premium_value: premium_rate,
                      coverage_limit: matchingTier.coverage_limit,
                      coverage_limit_start: matchingTier.coverage_limit_start,
                    };
                  }
                }
                uData.selectedInsurance = tableRateInsurance;
              }
            }
        }
        }}

      // Dispatch the updated data to the store
      await this.$store.dispatch('sendQuoteStore/getPropertyUnitsInfo', parsedData);
  },
    calculateUnitSubtotal(unit, type) {
      const unitPrice = parseFloat(unit?.unit_price || 0);
      const coveragePrice = parseFloat(unit?.selectedInsurance?.premium_value || 0);
      let merchandisePrice = 0;
      let feesPrice = 0;
      if (unit?.selectedMerchandise?.length) {
        unit?.selectedMerchandise.forEach((merchandiseData) => {
          let calMerAmount = parseFloat(merchandiseData.price) * parseFloat(merchandiseData.qty);
          merchandisePrice += calMerAmount
        })
      }
      if (unit?.selectedFees?.length) {
        unit?.selectedFees.forEach((feeData) => {
          let calFessAmount = parseFloat(feeData.price) * parseFloat(feeData.qty);
          feesPrice += calFessAmount
        })
      }
      const subTotal = unitPrice + coveragePrice + merchandisePrice + feesPrice;
      // Return the subtotal with a dollar sign prefix
      if (type == "total") {
        return this.roundToTwoDecimals(subTotal);
      }
      return `$${this.roundToTwoDecimals(subTotal)}`;
    },
    taxUnitCalculation(unit, taxTempalte, type) {
      const unitPrice = parseFloat(unit?.unit_price || 0);
      const coveragePrice = parseFloat(unit?.selectedInsurance?.premium_value || 0);
      let merchandisePrice = 0;
      let feesPrice = 0;
      let unitTaxPer = 0;
      let coverageTaxPer = 0;
      let merchandiseTaxPer = 0;
      let feesTaxPer = 0;
      if (unit?.selectedMerchandise?.length) {
        unit?.selectedMerchandise.forEach((merchandiseData) => {
          let calMerAmount = parseFloat(merchandiseData.price) * parseFloat(merchandiseData.qty);
          merchandisePrice += calMerAmount
        })
      }
      if (unit?.selectedFees?.length) {
        unit?.selectedFees.forEach((feeData) => {
          let calFessAmount = parseFloat(feeData.price) * parseFloat(feeData.qty);
          feesPrice += calFessAmount
        })
      }
      if (taxTempalte?.length > 0) {
        let findUnitTax = taxTempalte.find((tax) => {
          if (tax?.unit_type_id == unit.unit_type_id) {
            return tax
          }
          return false;
        })
        if (findUnitTax) {
          unitTaxPer = parseFloat(findUnitTax?.tax_rate);
        }
        let finsCoverageTax = taxTempalte.find((tax) => {
          if (tax?.type == "insurance") {
            return tax
          }
          return false;
        })
        if (finsCoverageTax) {
          coverageTaxPer = parseFloat(finsCoverageTax?.tax_rate);
        }
        let finsMerchandiseTax = taxTempalte.find((tax) => {
          if (tax?.type == "merchandise") {
            return tax
          }
          return false;
        })
        if (finsMerchandiseTax) {
          merchandiseTaxPer = parseFloat(finsMerchandiseTax?.tax_rate);
        }
        let finsFeeTax = taxTempalte.find((tax) => {
          if (tax?.type == "fee") {
            return tax
          }
          return false;
        })
        if (finsFeeTax) {
          feesTaxPer = parseFloat(finsFeeTax?.tax_rate);
        }
      }
      unitTaxPer = unitTaxPer / 100;
      coverageTaxPer = coverageTaxPer / 100;
      merchandiseTaxPer = merchandiseTaxPer / 100;
      feesTaxPer = feesTaxPer / 100;
      let unitTax = this.roundToTwoDecimals(unitPrice * unitTaxPer);
      let coverageTax = this.roundToTwoDecimals(coveragePrice * coverageTaxPer);
      let merchandiseTax = this.roundToTwoDecimals(merchandisePrice * merchandiseTaxPer);
      let feeTax = this.roundToTwoDecimals(feesPrice * feesTaxPer);
      const totalSubtotalTax = unitTax + coverageTax + merchandiseTax + feeTax;
      if (type == "total") {
        return this.roundToTwoDecimals(totalSubtotalTax);
      }
      return `$${this.roundToTwoDecimals(totalSubtotalTax)}`;
    },
    totalUnitCalculation(unit, taxTempalte, returnType) {
      let type = "total"
      const unitSubTotalTax = this.calculateUnitSubtotal(unit, type);
      const totalUnitTax = this.taxUnitCalculation(unit, taxTempalte, type);
      let unitPrice = parseFloat(unit?.unit_price || 0);
      let discountPer = parseFloat(unit?.selectedDiscount?.value || 0);
      const discountPrice = (unit?.selectedDiscount?.type == 'percent') ? this.roundToTwoDecimals(unitPrice * (discountPer / 100)) : discountPer;
      let promoDiscount = 0;
      unit.selectedPromotions.forEach((promo) => {
        promoDiscount += promo?.price || 0;
      });
      if (returnType == "number") {
        return this.roundToTwoDecimals(unitSubTotalTax + totalUnitTax - discountPrice - promoDiscount);
      }
      return `$${this.roundToTwoDecimals(unitSubTotalTax + totalUnitTax - discountPrice - promoDiscount)}`;
    },
    calculateUnitsTotal(units, propertyTax) {
      let unirGrandTotal = 0;
      units.forEach((unit) => {
        unirGrandTotal = unirGrandTotal + this.totalUnitCalculation(unit, propertyTax, "number")
      })
      return unirGrandTotal;
    },
    calculateUnitsRent(units) {
      let totalUnitRent = 0;
      units.forEach((unit) => {
        totalUnitRent = totalUnitRent + parseFloat(unit?.unit_price || 0)
      })
      return totalUnitRent;
    },
    calculateGrandTotal(allPropertyUnits) {
      var grandTotal = 0;
      if (allPropertyUnits?.length) {
        allPropertyUnits.forEach((propData) => {
          if (propData?.units?.length) {
            grandTotal = grandTotal + this.calculateUnitsTotal(propData.units, propData.propertyTax);
          }
        })
      }
      return `$${this.roundToTwoDecimals(grandTotal).toFixed(2)}`;
    },
    calculateTotalRent(allPropertyUnits) {
      var totalRent = 0;
      if (allPropertyUnits?.length) {
        allPropertyUnits.forEach((propData) => {
          if (propData?.units?.length) {
            totalRent = totalRent + this.calculateUnitsRent(propData.units);
          }
        })
      }
      return `$${this.roundToTwoDecimals(totalRent).toFixed(2)}`;
    },
    showDialogEvent(prop_id, unit_id, params, serviceProducts, existingProductsList) {
      this.tempPropId = prop_id;
      this.tempUnitId = unit_id;
      this.productsList = (serviceProducts?.length) ? serviceProducts : [];
      this.existingProductsList = (existingProductsList?.length) ? existingProductsList : [];
      this.dialogType = params;
      this.productsDialog = true;
    },
    async addServiceProductListToUnit({ productType, selectedProductList }) {
      await this.setSelectedServiceProduct(productType, selectedProductList)
      this.productsList = [];
      this.existingProductsList = [];
      this.dialogType = "";
      this.productsDialog = false;
    },
    async setSelectedServiceProduct(productType, selectedProd) {
      // Create a deep copy of the selectedPropertyUnits to avoid direct mutation
      let parsedData = JSON.parse(JSON.stringify(this.selectedPropertyUnits));

      // Map over the parsedData to find the matching property and unit, and update the selected service product
      const updatedData = parsedData.map(pData => {
        if (pData?.property?.id == this.tempPropId) {
          // Iterate over units in the matching property
          pData.units = pData.units.map(uData => {
            if (uData?.unit_id == this.tempUnitId) {
              // Update the selected service product
              if (productType == "merchandise") {
                let tempProd = (uData?.selectedMerchandise?.length) ? uData.selectedMerchandise : [];
                uData.selectedMerchandise = tempProd.concat(selectedProd).reduce((acc, current) => {
                let service_price = (current?.amount_type == "percentage")? Number(this.roundAmount(uData.unit_price * (current.percentage / 100), null)):current.price;
                current.price = service_price;
                  // If the id does not exist in the accumulator, add it
                  if (!acc.some(item => item.product_id == current.product_id)) {
                    acc.push(current);
                  }
                  return acc;
                }, []);
              } else {
                let tempProd = (uData?.selectedFees?.length) ? uData.selectedFees : [];
                uData.selectedFees = tempProd.concat(selectedProd).reduce((acc, current) => {
                  let service_price = (current?.amount_type == "percentage")? Number(this.roundAmount(uData.unit_price * (current.percentage / 100), null)):current.price;
                  current.price = service_price;
                  // If the id does not exist in the accumulator, add it
                  if (!acc.some(item => item.product_id == current.product_id)) {
                    acc.push(current);
                  }
                  return acc;
                }, []);
              }
            }
            return uData;
          });
        }
        return pData;
      });

      // Dispatch the updated data to the store
      await this.$store.dispatch('sendQuoteStore/getPropertyUnitsInfo', updatedData);
    }
  },
  watch: {
  }
}

</script>

<style scoped>
.send-quote-body-scroll {
  /* Adjust this value as needed */
  overflow-y: auto;
  /* Enables vertical scrolling when content exceeds max height */
}

.unites-counter {
    width: 18px;
    height: 18px;
    border-radius: 20px;
    padding-right: 4px;
    padding-left: 3px;
    background: #F26500;
    align-content: center;
    align-self: center;
    color: white;
    margin-left: 10px;
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0% !important;
    text-align: center;
}

.unit-amenities-container {
  display: block !important;
}

.send-quote-unit-header {
  text-transform: capitalize;
}

.unit-amenities {
  /* display: block; */
  text-align: left !important;
  /* font-size: 14px; */
  color: #637381;
}

.unit-currency-display {
  display: inline-flex;
  /* Use flexbox for alignment */
  align-items: center;
  /* Align icon and text vertically */
  color: #101318;
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
  /* Add gap between icon and text */
}

.send-quote-unit-title {
  align-items: center;
  display: flex;
  align-content: center;
  text-align: center;
  gap: 5px;
  border-bottom: 0px !important;
}

.send-quote-property-title {
  gap: 5px;
}

.send-quote-total-cal {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 56%;
  box-shadow: 0px -2px 4px 2px rgba(71, 84, 116, 0.1);
  background: rgba(224, 245, 245, 1);
  z-index: 1000;
}

.send-quote-hb-panel-div {
  display: inline-block;
  flex-wrap: nowrap;
  width: 100%;
}

.send-quote-hb-panel-div-insdie {
  display: flex;
  width: 100%;
}
.rent-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rent-details .amount {
  margin-left: auto;
  text-align: right;
}

.title-price-align-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.general-price {
  text-align: right;
}
</style>
