<template>
    <div class="mt-0">
        <v-row class="mb-n5">
            <v-col md="12" class="pr-0 my-0"><strong>Refund Recurring Charges:</strong> 
                <span class="mt-n5 hb-font-body"> {{ amt_to_refund - securityDeposit | formatMoney }} </span>
                <HbBtn class="mt-n5" icon tooltip="Down" :mdi-code="showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                    v-if="moveOutCalc?.paidInvoices?.length" text @click="showDetails = !showDetails" />
            </v-col>
        </v-row>
        <div v-if="moveOutCalc?.paidInvoices?.length && showDetails">
            <div class="mb-1 hb-font-body">Refund due by:</div>
            <div class="hb-font-caption hb-text-light" v-for="(total, source) in paymentSourceTotals" :key="source">
                {{ source }} - {{ total | formatMoney }}
            </div>
        </div>
        <v-row class="mb-n5">
            <v-col md="12" class="pr-0 my-1">
                <strong>Security Deposit Refund:</strong>
                <span class="mt-n5 hb-font-body"> {{ securityDeposit | formatMoney }}</span>
            </v-col>
        </v-row>
        <!-- <v-row class="mb-n4 mt-2" v-if="securityDeposit > 0">
            <v-col md="12" class="pr-0 my-0">
                <div style="display: flex; align-items: center; gap: 8px;">
                    <strong>Security Deposit to Refund:</strong>
                    <hb-text-field v-model="securityDepositRefund" v-validate="'required|max:45'"
                        data-vv-scope="default" name="securityDepositRefund"
                        :error="errors.has('default.text-field-condensed-box-medium-prepend-inner-$')"
                        style="width: 150px">
                        <template v-slot:prepend-inner>
                            $
                        </template>
                    </hb-text-field>
                </div>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>

import Status from '../../../includes/Messages.vue'
import Loader from '../../../assets/CircleSpinner.vue'
import moment from 'moment';
import api from '../../../../assets/api.js';
import { mapGetters, mapMutations } from 'vuex';
import { notificationMixin } from '../../../../mixins/notificationMixin.js';


export default {
    name: "RefundView",
    mixins: [notificationMixin],
    props: ['move_out_date', 'current_refund', 'lease_id', 'contact_id', 'amt_to_refund', 'overpayments', 'account_balance'],
    components: { Status, Loader },
    data() {
        return {
            refund: 0,
            processingRefund: false,
            showDetails: false,
            securityDeposit:0,
            securityDepositRefund:0,
            refundOpt: {
                manager: '',
                district: '',
                owner: ''
            },
            refundObj: {}
        }
    },
    async created() {
        this.refund = this.amt_to_refund;
        await this.fetchSecurityDeposit()
        // this.refundObj = this.current_refund ? JSON.parse(JSON.stringify(Object.assign({}, this.current_refund))) : {};
        // if (this.refundObj.balnce && this.refundObj.balnce > 0) {
        //     this.refundObj.credit = this.refundObj.balnce;
        // }
        //this.populateRefundAmount();
    },
    computed: {
        ...mapGetters({
            moveOutCalc: 'paymentsStore/getMoveOutCalc',
        }),
        ...mapMutations({
            setMoveOutCalc: 'paymentsStore/setMoveOutCalc',
        }),
        paymentSourceTotals() {
            if (!this.moveOutCalc?.paidInvoices) return {};

            return this.moveOutCalc.paidInvoices.reduce((totals, invoice) => {
                if (!invoice.payment_credits) return totals;

                invoice.payment_credits.forEach(credit => {
                    const source = credit.Payment?.payment_source || 'Unknown';
                    totals[source] = (totals[source] || 0) + (credit.refund_amount || 0);
                });

                return totals;
            }, {});
        }
    },
    methods: {
        async refundSecurityDepositAmount() {
            console.log("Security Deposit: ", this.securityDepositRefund)
            let data = {
                amount: this.securityDepositRefund,
            }

            await api.post(this, api.LEASES + this.lease_id + '/security-deposit/refund', data).then(r => {
                this.showMessageNotification({ type: 'success', description: "Security Deposit Refund Processed Successfully" });
                this.$emit('securityRefunded', true);
                this.securityDepositRefund = 0;
            });
            await this.fetchSecurityDeposit();
        },

        async fetchSecurityDeposit() {
            await api.get(this, api.LEASES + this.lease_id + '/security-deposit').then(r => {
                this.securityDeposit = r.lines.reduce((a, b) => a += b.amount, 0);
                this.securityDepositRefund = this.securityDeposit;
            });
        },
        
        // populateRefundAmount() {
        //     if(this.refundObj.Payments){
        //         let refundCredit = ~this.refundObj.credit;
        //         this.refundObj.Payments.map(p => {
        //             if(refundCredit > 0) {
        //                 if(refundCredit < p.amount) {
        //                     p.credit = refundCredit;
        //                     refundCredit = 0;  
        //                 }
        //                 else {
        //                     refundCredit = refundCredit - p.amount;
        //                     p.credit = refundCredit > 0 ? p.amount : 0;
        //                 }
        //             }
        //         });
        //     }
        // },
        async processOverpayment() {
            try {

                let status = await this.$validator.validateAll();
                if (!status) throw "error";

                let data = {
                    moved_out: moment(this.move_out_date).startOf('day').format('YYYY-MM-DD'),
                    confirm: true
                }
                this.processingRefund = true;
                let r = await api.get(this, api.LEASES + this.lease_id + '/over-payments', data);
                this.showMessageNotification({ type: 'success', description: 'Refund Processed Successfully' });
                this.$emit('fetchPayments');
            } catch (err) {
                this.showMessageNotification({ type: 'error', description: err });
            } finally {
                this.processingRefund = false;
            }

        },
    },
    watch: {
        "current_refund": async function () {
            this.refundObj = this.current_refund ? JSON.parse(JSON.stringify(Object.assign({}, this.current_refund))) : {};
        },
    },
}
</script>

<style scoped>
.font-big {
    font-size: 15px;
    font-weight: 500;
}

.mrl--24px {
    margin-left: -24px;
    margin-right: -24px;
}

.btn-update-req {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border: 1px solid #C4CDD5;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #101318;
}

.approve-ref-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #919EAB;
    background: #F9FAFB;
    border: 1px solid #DFE3E8;
    box-sizing: border-box;
    border-radius: 4px;
}

.font-15px {
    font-size: 15px;
}

.width-25px {
    width: 25px;
}
</style>