<template>
    <div>
        <div v-if="loading">
          <v-row justify="center" align="center">
            <v-col class="text-center ma-12">
              <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
            </v-col>
          </v-row>
        </div>

        <div class="services-section scrollable-content" v-else>
            <v-container class="ma-0 pa-0 mb-3">
                <v-row no-gutters>
                    <v-col md="4" class="ma-0 pa-0 mt-n1">
                        <span class="hb-font-header-2-medium">Finalize Lease</span>
                    </v-col>
                </v-row>
            </v-container>             
            <DocumentsSigner :is-multi-space-lease="is_multi_space_lease" @documentsSigned="checkStatus" :allow-move-in-checklist="true"></DocumentsSigner>
            <template v-if="payLater ? !(invoices?.length && invoices[0]?.id):!(invoices?.length && invoices[0].Payments?.length && invoices[0].Payments[0].payment_id)">
            <payments ref="paymentsComponentReference"  @retire_lead="retireLead"  @close="$emit('close', true)"  @skipPay="skipPay"
            />
          </template>
        </div>
        <v-dialog v-model="saved" width="500">
          <v-card>
            <v-card-title class="headline">Lease Saved</v-card-title>
            <v-card-text class="text-center">
              This lease has been saved successfully!
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="$emit('close', true)">
                Close Window
              </v-btn>
              <v-btn color="primary" @click="goToLease">Go To Lease</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="new-tenant-walkthrough-footer" v-if="payLater ? (invoices?.length && invoices[0].id):(invoices?.length && invoices[0].Payments?.length && invoices[0].Payments[0].payment_id)">
          <hb-bottom-action-bar
              @close="$emit('close')"
              :top-border="false"
          >
              <template v-slot:left-actions>
                <!-- <help-juice /> -->
              </template>
              <template v-slot:right-actions>

                  <hb-btn
                      :disabled="isLoading($options.name) || !documentSigned"
                      :loading="finalizeInProgress"
                      @click="openInvocieGeneration"
                  >
                      Finalize
                  </hb-btn>

              </template>
          </hb-bottom-action-bar>
        </div>
        <hb-modal 
          size="medium" 
          title="Invoice Generation" 
          v-model="invoiceGeneration"
          v-if="invoiceGeneration"
          show-help-link
          confirmation> 
          <template v-slot:content> 
            <div class="py-4 px-6"> 
              The move-in date for this rental is outside the invoice threshold period ({{invoice_send_day}} Days). The invoice for the next billing period will be generated after clicking continue. This invoice will be visible in the tenant’s transaction history.  
            </div> 
            </template> 
            <template v-slot:actions>
                <hb-btn class="mr-2" color="primary" :disabled="finalizeInProgress" :loading="finalizeInProgress" @click="finalize">Continue</hb-btn>
            </template>
        </hb-modal>
        <hb-modal 
        size="medium" 
        title="Finalize Lease" 
        v-model="showFinalize"
        v-if="showFinalize"
        confirmation> 
        <template v-slot:content> 
          <div class="py-4 px-6"> 
            Are you sure you want to finalize the lease?
          </div> 
          </template> 
          <template v-slot:actions>
            <hb-btn
                      :disabled="isLoading($options.name) || !documentSigned"
                      :loading="finalizeInProgress"
                      @click="openInvocieGeneration"
                  >
                      Finalize
                  </hb-btn>
          </template>
      </hb-modal>
    </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment'; 
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../../EventBus.js'; 
    import { notificationMixin } from  '@/mixins/notificationMixin.js';
    import DocumentsSigner from '../DocumentsSigner.vue';
    import { settingsMixin } from "@/mixins/settingsMixin.js";
    import SETTINGS from "@/constants/settings.js";
    import payments from "./Payments.vue";

    export default {
        name: "Checklist",
        mixins: [notificationMixin,settingsMixin],
        data() {
            return {
                saved: false, 
                panels: [0,1,2],   
                invoiceGeneration: false,
                currentDate: '',
                invoiceGenerationDifference: '',
                threshold: false,
                finalizeInProgress: false,
                documentSigned: false,
                payLater:true,
                showFinalize:false
            }
        },
        components:{
            Status,
            Loader,
            DocumentsSigner,
            payments
        },
        props: ['loading', 'accessCode'],
        async created (){
            this.SETTINGS = SETTINGS;
            this.$emit('update:loading', false)
            this.currentDate = moment().format('YYYY-MM-DD');
        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease',
                template: 'onBoardingStore/template',
                payment: 'onBoardingStore/payment',
                paymentMethod: 'onBoardingStore/paymentMethod',
                billed_months: 'onBoardingStore/billed_months',
                items: 'onBoardingStore/items',
                contact: 'onBoardingStore/contact',
                getFollowUpStatus:     'onBoardingStore/getFollowUpStatus',
                singleSpaceRentalExists: "onBoardingStore/getSingleSpaceRental",
                invoices: 'onBoardingStore/invoices',
            }),
            invoice_send_day() { 
              let {invoice_send_day = 0 } = this.lease;
              return invoice_send_day;
            },
            is_multi_space_lease() { 
              if(!Object.keys(this.currentTemplate).length) return false; 
              if(!this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL)) return false;
              if (!this.currentTemplate?.multi_space_rental || this.singleSpaceRentalExists?.flag) return false;  
              return true;
            },
            currentTemplate(){
                return this.template[this.lease?.unit_id] || {};
            },
        },
        methods: {
            ...mapActions({
              resetPropertiesInfo: 'propertiesStore/resetPropertiesInfo',
              fetchPropertyGroups: 'propertiesStore/fetchPropertyGroups'
            }),
            checkStatus(status){
              this.documentSigned = status;
            },

            closeInvoiceGeneration(){
                this.invoiceGeneration=false;
            },

            async finalize(){

                this.finalizeInProgress = true;

                let data = {
                    billed_months: this.billed_months
                };
                data.payment = this.payment;
                if(!data.payment.payment_method_id && this.paymentMethod.type){
                    data.paymentMethod = this.paymentMethod;
                }

                data.total = this.totalSum;

                data.payment = {
                    id: this.payment.payment_id,
                    payment_method_id: this.payment.payment_method_id,
                    type: this.payment.type,
                    number: this.payment.number,
                    ref_name: this.payment.ref_name,
                    source: this.payment.source,
                    amount: this.payment.amount,
                    date: this.payment.date,
                    contact_id: this.payment.contact_id,
                    property_id: this.payment.property_id,
                    authorization: this.payment.authorization
                };
                data.threshold = this.threshold;
                data.follow_up = this.getFollowUpStatus
                data.is_msr = this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) && this.currentTemplate?.multi_space_rental;
                data.single_space_exists = this.singleSpaceRentalExists?.flag;
                try {
                  
                  let r = await api.post(this, api.LEASES + this.lease.id + '/finalize', data)
                  data.payment.id = r.payment_id;
                  data.payment.transaction_id = r.transaction_id;
                  data.payment.amount = r.amount;
                  this.$store.commit('onBoardingStore/setPayment', data.payment);
                  this.saved = true;
                  EventBus.$emit('unit_available_changed');
                  this.$emit('saved');
                  this.finalizeInProgress = false;
                  await this.resetPropertiesInfo();
                  this.fetchPropertyGroups({hardRefresh: true});

                } catch (error) {
                  this.finalizeInProgress = false;
                  this.showMessageNotification({ type: 'error', description: error });
                }
            },

            openInvocieGeneration(){
              // let [key = {}] = Object.keys(this.template);
              let { add_next_month = 0, add_next_month_days } = this.currentTemplate;
              let { bill_day = 0, start_date, invoice_send_day = 0 } = this.lease;
              let date = moment(start_date).format('DD');
              let bill_date = moment().set("date", bill_day);
              let difference = bill_date.diff(this.currentDate, 'days');
              if(bill_day === 1 && add_next_month && add_next_month_days < date){
                this.finalize();
              } else if((difference <= invoice_send_day) && difference > 0){
                this.invoiceGenerationDifference = difference;
                this.threshold = true;
                this.invoiceGeneration = true;
              } else {
                bill_date.add(1, 'M');
                let differenceNextMonth = bill_date.diff(this.currentDate, 'days');
                if(differenceNextMonth <= invoice_send_day){
                  this.invoiceGenerationDifference = differenceNextMonth;
                  this.threshold = true;
                  this.invoiceGeneration = true;
                } else {
                  this.finalize();
                }
              }
            },

            goToLease(){

                this.$router.push('/contacts/' + this.contact.id);
                this.$emit('close', true);
            },

            retireLead(){
              this.$emit('retire_lead');
            },

            skipPay(){
              this.payLater=false;
            },

            finalizeModal(){
              if(payLater){
              if(this.invoices?.length && this.invoices[0]?.id && this.documentSigned){
                 this.showFinalize = true;
              }
            }
            else{
              if(this.invoices?.length && this.invoices[0]?.id && this.documentSigned && invoices[0].Payments?.length && invoices[0].Payments[0]?.payment_id){
                this.showFinalize = true;
              }
            } 
            }
        },
        watch:{
          invoices(){
            this.finalizeModal();
          },
          documentSigned(){
            this.finalizeModal(); 
          }
        }
    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #C4CDD5;
        background: white;
        padding: 30px;
        border-radius: 4px;
        margin-bottom: 30px;
    }
    .policy-container{
        padding-top: 0;
        padding-bottom: 0;
    }
    .form-label{
        background: #F4F6F8;
        border-radius: 4px;
    }
    .insurance-option-row{
        border-bottom: 1px solid #C4CDD5;
        padding: 10px 20px;
    }
    .insurance-section-label{
        padding: 30px;
        font-weight: bold;
    }
    .insurance-container{
        padding: 30px 50px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }

    .new-tenant-walkthrough-footer{
        /* padding: 3px 0 3px 0; */
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 20px 24px 100px 24px
    }

    .policy-container  .v-input.v-input--selection-controls.v-input--checkbox {
        margin-top: 0;
    }

    .signatures .v-input--selection-controls .v-input__control{
        flex-grow: 1;
    }

    .send-block{
        margin-top: 10px;
    }




</style>
