<template>
    <div>
        <hb-modal
            v-if="isRetireBulk"
            size="large"
            :title="is_retired_reservation == 1 ? 'Cancel Reservation' : 'Retire This Lead'"
            v-model="dialog"
            show-help-link
        >
            <template v-slot:subheader>
                <hb-notification
                    v-if="is_retired_reservation && retireReservationsInfo" 
                    type="guidance"
                    @close="retireReservationsInfo = false"
                >
                Cancelling the reservation will remove this lead interaction.
                </hb-notification>
                Would you like to {{ is_retired_reservation == 1 ? 'cancel this reservation?': 'retire this lead?' }}
    
            </template>
    
            <template v-slot:content>
                <div>
                    <hb-form label="Reason" required>
                        <hb-select
                            v-model="lead.reason"
                            :items="reasons"
                            placeholder="Choose Reason"
                            v-validate="'required'"
                            id="lead_reason"
                            name="lead_reason"
                            data-vv-as="Reason"
                            :clearable="false"
                            :error="errors.has('lead_reason')"
                        >
                        </hb-select>
                    </hb-form>
        
                    <hb-form label="Email">
                        {{contact.email}}
                    </hb-form>
        
                    <hb-form label="Phone">
                        <div v-if="contact.Phones && contact.Phones.length">
                          {{contact.Phones[0].phone | formatPhone}}
                        </div>
                    </hb-form>
        
                    <hb-form label="Name">
                        {{contact.first}} {{contact.last}}
                    </hb-form>
        
                    <hb-form label="Opt-Out">
                        <hb-checkbox
                            label="Remove tenant from all future communication."
                            v-model="lead.opt_out"
                            id="lead_OptOut"
                            name="lead_OptOut"
                            data-vv-as="opt"
                        ></hb-checkbox>
                    </hb-form>
        
                    <hb-form :label="is_retired_reservation ? 'Notes for Cancelling Reservation' : 'Notes for Retiring Lead'" full last required>
                        <hb-textarea
                            v-model="lead.notes"
                            v-validate="'max:1000|required'"
                            :label="is_retired_reservation ? 'Why are you cancelling this reservation?' : 'Why are you retiring this lead?'"
                            id="lead_notes"
                            name="lead_notes"
                            data-vv-as="Notes"
                            :error="errors.has('lead_notes')"
                        >
                        </hb-textarea>
                    </hb-form>
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn v-if="hasPermission('retire_lead')" color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)" @click="save">{{ is_retired_reservation == 0 ? retireCancelLeadReservation[0] : retireCancelLeadReservation[1] }}</hb-btn>
            </template>
        </hb-modal>
    
        <hb-modal
        v-if="!isRetireBulk"
        size="large"
            :title="'Retire All Leads'"
            v-model="dialog"
            show-help-link
        >
            <template v-slot:subheader>
                    <hb-notification 
                        type="guidance"
                        @close="retireReservationsInfo = false"
                    >
                    Select Multiple Leads to Retire.
                    </hb-notification>
                    
        
                </template>
            <!-- Reason Field -->
            <template v-slot:content>
            <hb-form label="Reason" required>
                    <v-select
                        hide-details
                        :items="reasons"
                        label="Choose Reason"
                        single-line
                        v-validate="'required'"
                        id="lead_reason"
                        name="lead_reason"
                        data-vv-as="Reason"
                        data-vv-scope="retire_lead_interactions"
                        v-model="lead.reason"
                        @blur="handleBlur"
                    ></v-select>
                </hb-form>
        
                <!-- Lead Interactions -->
                <hb-form label="Lead Interactions" required>
                    <v-combobox
                        hide-details
                        label="Select lead interactions"
                        single-line
                        :items="interactions"
                        item-value="id"
                        item-text="label"
                        v-validate="'required'"
                        id="retire_lead_interactions"
                        name="retire_lead_interactions"
                        data-vv-as="Lead Interactions"
                        multiple
                        clearable
                        clear-icon="mdi-close-circle"
                        v-model="dynamicSelect"
                        :menu-props="{ contentClass: 'hb-elevation-large reports-property-selector-combobox-menu'}"
                        :filter="filterInteractions"
                        :hide-no-data="!searchInteraction"
                        :search-input.sync="searchInteraction"
                        data-vv-scope="retire_lead_interactions"
                        @blur="handleBlur"
                    >
                        <template v-slot:selection="{ item, parent }">
                            <hb-chip
                                @click="parent.selectItem(item)"
                                editable
                            >
                                <span v-if="item.unit_no">{{ item.unit_no }} - </span>
                                <span v-if="item.property_name">{{ item.property_name }}</span>
                            </hb-chip>
                        </template>
        
                        <template v-slot:prepend-item>
                            <v-list-item @click="toggleSelectAll" class="select-all-item">
                                <v-list-item-action>
                                    <v-icon :color="icon == 'check_box' || dynamicSelect.length > 0 ? '#00848E' : '#dfe3e8'">{{ selectAllIcon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-combobox>
                </hb-form>
        
                <!-- Opt-Out Checkbox -->
                <hb-form label="Opt-Out">
                    <v-checkbox
                        label="Remove tenant from all future communication."
                        solo
                        flat
                        hide-details
                        single-line
                        v-model="lead.opt_out"
                        id="lead_OptOut"
                        name="lead_OptOut"
                        data-vv-as="opt"
                        class="pa-0 mt-0"
                    ></v-checkbox>
                </hb-form>
        
                <!-- Notes for Retiring -->
                <hb-form label="Notes for Retiring Leads" required>
                    <v-textarea
                        hide-details
                        auto-grow
                        flat
                        rows="6"
                        label="Why are you retiring these leads?"
                        single-line
                        v-model="lead.notes"
                        v-validate="'required'"
                        id="retire_reason"
                        name="retire_reason"
                        data-vv-as="Notes"
                        class="pa-0 mt-0"
                        data-vv-scope="retire_lead_interactions"
                        @blur="handleBlur"
                    ></v-textarea>
                </hb-form>
            </template>
            <template v-slot:actions>
                <hb-btn v-if="hasPermission('retire_lead')" color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)" @click="retireLead">Retire All Leads</hb-btn>
            </template>
        </hb-modal>
    

    </div>
</template>

<script>
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
	import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import { mapGetters } from 'vuex';
    import LEASE from '@/constants/lease.js'; 

    export default {
        name: "RetireLead",
		mixins: [ notificationMixin ],
        data () {
            return {
                showDialog: true,
                reasons: [],
                lead : {
                    reason : "No longer required",
                    opt_out: false,
                    notes  : ''
                },
                retireCancelLeadReservation:['Retire This Lead', 'Cancel Reservation'],
                retireReservationsInfo:true,
                interactions: [],
                leads:[],
                searchInteraction: '',
                dynamicSelect: [],
                selectAllIcon: 'mdi-checkbox-blank-outline',

            }
        },
        props:['contact', 'value', 'lead_id', 'is_retired_reservation','isRetireBulk'],
        computed:{
            dialog: {
                get () {
                    return this.value
                }, 
                set (value) {
                    this.$emit('input', value)
                },
            },
            icon() {
                return this.dynamicSelect.length > 0 ? 'check_box' : 'check_box_outline_blank';
            },
            ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission',          
            }),
        },
        methods: {
            async save(){

                let status = await this.validate(this);
                if(!status){
					this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                } 

                try {
                    await api.put(this, api.LEADS + this.lead_id + '/retire', this.lead); 
                    EventBus.$emit('lead_saved');
                    EventBus.$emit('reset_profile_tasks');
                   this.$emit('retired');
                    this.retire = {
                        reason: "No longer required",
                        notes: '',
                        opt_out: false
                    }
                    let message = this.is_retired_reservation ? 'Successfully cancelled reservation' : 'Lead interaction retired successfully'
                    this.showMessageNotification({ type:'success',description: message});
                    
                    
                } catch (error) {
                    this.showMessageNotification({ type:'error', description: error});
                    
                }

            },
            closeWindow(){
                this.showDialog = false;
                setTimeout(() => {
                    this.$emit('close');
                }, 200);
            },
            getRetireReasons(){
                api.get(this, api.LEADS + 'retire-reasons').then(r => {
                    // this.retireReasons = r;
                    this.reasons = r
                });
            },
            async retireLead() {
                let retire_lead_interaction_status = await this.$validator.validateAll('retire_lead_interactions');

                if (!retire_lead_interaction_status) {
                    this.showMessageNotification({ description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                }

                let selectedInteractionIds = this.dynamicSelect.map(item => item.id);
                let ids = selectedInteractionIds.map(i=>i)

                this.lead.lead_ids = ids;

                try {

                    await api.post(this, api.LEADS + 'retire-lead-interactions', this.lead);

                    EventBus.$emit('lead_saved');
                    EventBus.$emit('reset_profile_tasks');
                    this.$emit('retired');
                    let message = 'Leads retired successfully';
                    this.showMessageNotification({ type: 'success', description: message });
                } catch (error) {
                    this.showMessageNotification({ type: 'error', description: error.message || 'An error occurred while retiring the lead interactions.' });
                }
            },
            async getLeads() {
                let leads_data = await api.get(this, api.CONTACTS + this.contact.id + '/leads');
                this.leads = leads_data.leads.filter(lead => lead.status === 'active');

                // this.cancelRetireInteractions();   

                this.interactions = this.leads.map(lead => ({
                    id: lead.id,
                    type: lead.lead_type,
                    unit_no: lead.Unit.number,
                    property_name: lead.Property.name,
                    label: `${lead.lead_type || 'Lead'} ${lead.Unit?.number ? `| #${lead.Unit.number}` : ''} | ${lead.Property.name}`
                }));
            },
            handleBlur(event) {
                this.$validator.reset();
            },
            filterInteractions(item, queryText, itemText) {
                const name = item.label.toLowerCase();
                const searchText = queryText.toLowerCase();
                return name.indexOf(searchText) > -1;
            },
            toggleSelectAll() {
                if (this.dynamicSelect.length === this.interactions.length) {
                    this.dynamicSelect = [];
                } else {
                    this.dynamicSelect = this.interactions.map(item => item);
                }
            },

        },
        async created() {
             this.getRetireReasons()
             if(!this.isRetireBulk){
                await this.getLeads()
             }
            //  change the yex based on the condition of reservation
            // if(this.contact?.Leads.some(item => item?.id == lead_id) && this.contact.Reservations.length >= 1){
            //     this.retireCancelLeadReservation = "Cancel Reservation"
            // }else{
            //     this.retireCancelLeadReservation = "Retire Lead"
                
            // }
        },
        
    }
</script>

<style scoped>
    .mrl--25px{
        margin-left: -25px;
        margin-right: -24px;
    }
    .key-label{
      background: #F4F6F8;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
</style>
