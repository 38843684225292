<template>
    <div>
        <div class="payment-details">
            <div class="col text-right">
                Total Payment: <strong>{{ totalPayment | formatMoney }}</strong>
            </div>
        </div>

        <hb-card :boxShadow="false" class="mt-4">
            <div class="d-flex">
                <div class="radio-options pa-5">
                    <hb-radio-group
                        v-model="paymentType"
                        column
                        hide-details
                    >
                        <hb-radio
                        label="EFTPOS"
                        value="eftpos"
                        />
                        <hb-radio
                        label="Direct Refund"
                        value="directdeposit"
                        />
                    </hb-radio-group>
                </div>
                <eftpos :returnData.sync="returnData" v-if="paymentType === 'eftpos'" :total-payment="totalPayment" ref="eftpos" />
                <direct-refund :returnData.sync="returnData" v-if="paymentType === 'directdeposit'" ref="directRefund" />
            </div>
        </hb-card>
    </div>
</template>

<script>
export default {
    name: "ReturnPayment",
    components: {
        'eftpos': () => import('./EFTPOS.vue'),
        'direct-refund': () => import('./DirectRefund.vue')
    },
    props: {
        returnType: {
            type: String,
            default: ''
        },
        totalPayment: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            paymentType: 'eftpos',
            returnData: {}
        }
    },
    watch: {
        paymentType(params) {
            this.returnData = {};
        }
    },
    methods: {
        async validateData () {
            let status = false;
            if (this.paymentType === 'eftpos') {
                status = await this.$refs.eftpos.$validator.validateAll('default'); 
            } else {
                status = await this.$refs.directRefund.$validator.validateAll('default'); 
            }
            return status;
        },
        getReturnPaymentData() {
            return this.returnData;
        },
        getReturnPaymentType() {
            return this.paymentType;
        }
    },
}
</script>

<style scoped lang="scss">
    .payment-details {
        border-radius: 4px;
        border: 1px solid #DFE3E8;
        opacity: 0px;
        background-color: #fff;
    }

    .radio-options {
        min-width: 240px;
    }
    .main-section {
        width: 100%;
        min-height: 425px;
        border-left: 1px solid #ccc;
    }
</style>