<template>
    <div>
        <div class="services-section scrollable-content scroll-products pr-4" style="overflow: auto;"> 
          <div class="checkout-options" v-if="quick_action">
            <div class="hb-font-header-2-medium mt-1 mb-3">Checkout</div>
            <hb-radio-group row v-model="isGuestUser">
                <hb-radio label="Checkout as guest" :value="true"></hb-radio>
                <hb-radio label="Checkout as tenant" :value="false"></hb-radio>
              </hb-radio-group>

          </div>
          
          <div style="position: relative;" class="pb-5 pt-2" v-if="!isGuestUser">
            <span v-if="!contact.id">
              <hb-text-field 
                  box 
                  :loading="isGettingContacts"
                  search
                  @input="searchTenantEvent"
                  width="100%"
                  :clearable="false"
                  placeholder="Search"
                  style="max-width: 511px;" />
              <div class="search-result" v-if="foundContacts.length" @scroll="handleScroll" ref="scrollContainer">
                <div v-for="(item, i) in foundContacts" :key="item.id + i" class="hand" @click="setContactEvent(item)">
                  <mini-profile-view
                        class="pa-4 pb-2"                 
                        :contact-data="item"
                        :use-contact="true"
                        :disable-space-access="true"
                        style="pointer-events: none;"
                      />
                    <v-divider></v-divider>
                </div>
              </div>
              <div class="search-result pa-4" v-else-if="showNoRecord">
                   <strong>No contact found!</strong>
              </div>
            </span>
            <div style="position: relative;" v-if="contact.id">
              <mini-profile-view             
                :contact-data="contact"
                :use-contact="true"
                :disable-space-access="true"
              />
              <span v-if="quick_action" class="hb-link hand" @click="changeTenantEvent" style="position: absolute; right: 0; bottom: -6px;">Change Tenant</span>
            </div>
          </div>
          <payment-process 
            :contactID="contact.id" 
            :propertyID="property.id"
            :contact_prop.sync="contact"  
            :invoice="invoice"
            :guest-merchant="isGuestUser"
            paymentSource="SERVICES"
            :checkPaymentsErrors="checkPaymentsErrors"
            :is-merchandise-source="true"
            :isCalculating="isCalculating"
            :disable_payment="disable_payment"
            ref="paymentProcess">
          </payment-process>
        </div>

        <div class="new-tenant-walkthrough-footer" v-if="!quick_action || (quick_action && !isGuestUser)">
          <hb-bottom-action-bar
            @close="$emit('close')"
            :top-border="false"
          >
            <template v-slot:left-actions>
              <help-juice />
            </template>
            <template v-slot:right-actions>
              <hb-btn color="secondary" v-if="contact.national_account_id && !quick_action" :disabled="isLoading($options.name) || !canProgress" @click="$emit('saveInvoice')">Skip Payment</hb-btn>
              <hb-btn color="primary" :loading="disable_payment || isCalculating" :disabled="isLoading($options.name) || disable_payment || isCalculating" @click="checkPaymentsErrors">Process Payment</hb-btn>
            </template>
          </hb-bottom-action-bar>
        </div>
    </div>

</template>


<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import {charges} from '../../../mixins/leaseCharges.js'
    import moment from 'moment';
    import { EventBus } from '../../../EventBus.js';
    import PaymentProcess from '../PaymentProcess/Index.vue';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import MiniProfileView from '../LeadIntake/MiniProfileView.vue';


    export default {
        name: "Payments",
        data() {
            return {
                collect_payments: 0,
                panel: 0,
                payment_methods: [],
                auto_charge:false,
                prepay: null,
                show_charge: null,
                lease:{},
                payment_method: {
                    id: '',
                    type: '',
                    first: '',
                    last: '',
                    name_on_card: '',
                    card_number: '',
                    cvv2: '',
                    exp_mo: '',
                    exp_yr: '',
                    save_to_account: 1,
                    account_type: '',
                    routing_number: '',
                    account_number: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                },
                payment: {
                    id: '',
                    property_id: '',
                    contact_id: '',
                    payment_method_id: '',
                    type: '',
                    credit_type:'',
                    number:'',
                    ref_name: '',
                    source: '',
                    amount: '',
                    date: '',
                    transaction_id: '',
                    amt_remaining: ''
                },
                isGuestUser: true,
                foundContacts: [],
                isGettingContacts: false,
                showNoRecord: false,
                isInitLoad: false,
                searchText: '',
                allFetched: false
            }
        },
        props: ['contact',  'property', 'services', 'invoice', 'disable_payment','canProgress','quick_action', 'isCalculating'],
        components:{
            Status,
            PaymentProcess,
            MiniProfileView
        },
        created (){

            this.payment.property_id = this.property.id;
            this.payment.contact_id = this.contact.id;
            if (!this.$parent.isGuestUser) {
              this.isInitLoad = true;
              this.isGuestUser = false;
            }
            //this.calculateTotal();
        },
        computed: {
          ...mapGetters({
            getErrors: 'paymentsStore/getErrors',
            activeMerchandiseTab: 'navigationStore/activeMerchandiseTab'
          })
        },
        destroyed(){
          this.closeMessageNotification();
        },
        filters:{
        },
        methods: {
            ...mapActions({
                getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
            }),
            ...mapMutations({
              setCheckErrors: 'paymentsStore/setCheckErrors',
              checkOnlyCredit: 'paymentsStore/checkOnlyCredit'
            }),
            checkPaymentsErrors() {
              //this.setCheckErrors({ value: true });
              //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
              if (!this.contact?.id && !this.isGuestUser) {
                this.showMessageNotification({type: 'error', description: "Please select a tenant first"});
                return;
              }
              this.checkOnlyCredit();
            },
            async payNow(){

              const paymentInfo = await this.getTransformedPaymentObject({
                id: this.$options.name,
              });

              if(paymentInfo.errors && paymentInfo.errors.length) {
                this.showMessageNotification({ list: paymentInfo.errors });
                return;
              }

              this.$emit('processPayment', paymentInfo);
            },
            showCharges(product_id){
                this.show_charge = this.show_charge === product_id ? null : product_id;
            },
            searchTenantEvent: _.debounce(async function(params, isScroll = false, force) {
            if(!params || !params.trim()) {
              this.foundContacts = [];
              return;
            }
            params = params.trim().split(/\s+/).join(' ');
            this.searchText = params;
            if (!isScroll) {
              this.foundContacts = [];
              this.allFetched = false;
            }
            this.showNoRecord = false;
            this.isGettingContacts = true;
            let response = await api.get(this, api.CONTACTS + 'check', { search: params, offset: this.foundContacts.length } );
            if(response.contacts && response.contacts.length) {
                this.foundContacts = [...this.foundContacts, ...response.contacts.map(item =>  {
                  return {
                    ...item,
                    fullName: item.first.trim() + ' ' + item.last.trim()
                  }
                })];
            } else {
              if (this.foundContacts.length) {
                this.allFetched = true;
              } else {
                this.showNoRecord = true;
              }
            }
            this.isGettingContacts = false;
          }, 1000),
          setContactEvent(params) {
            this.$emit('selected', params)
          },
          changeTenantEvent() {
            this.foundContacts = [];
            this.showNoRecord = false;
            this.$emit('resetContact');
          },
          handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container.scrollTop + container.clientHeight === container.scrollHeight && !this.allFetched) {
              this.searchTenantEvent(this.searchText, true); // Fetch when scrolled to bottom
            }
          }
        },
        mixins:[charges, notificationMixin],
        watch: {
          getErrors(value) {
            if(value === false) {
              this.payNow();
              this.setCheckErrors({ value: null });

            }
          },
          isGuestUser(params) {
            this.foundContacts = [];
            this.showNoRecord = false;
            if (this.isInitLoad) this.isInitLoad = false;
            else this.$emit('isGuestUserEvent')
          }
        }
    }
</script>

<style scoped>

    .new-tenant-walkthrough-footer{
        padding: 3px 0 3px 30px;
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 5px 0px 24px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }
    .row {
        margin-bottom: 10px;
    }

    .payments-container{
        border: 1px solid #C4CDD5;
        background: white;
        border-radius: 4px;
        padding: 30px 50px;
    }
    .v-expansion-panel-content__wrap,
    .v-expansion-panel-content.no-padding .v-expansion-panel-content__wrap{
        padding: 0;
    }
    .v-expansion-panel-content__wrap {
        padding: 0px;
    }

    .invoice_display{
        padding: 20px;
        font-size: 14px;
    }
    .breakdown{
        display:inline-block;
        flex:0 0 100%;
        padding: 10px;
        width: 100%;
        background:#f2f9fd;
        border: 1px solid #dce8ef;
    }
    .new-tenant-form-data{
      border: 1px solid #DFE3E8;
    }

    .v-expansion-panel-content .form-label{
      background: #F4F6F8;
      padding: 20px;
      font-weight: bold;
    }

    .invoice_display{
      padding:  10px 0px;
      font-size: 14px;
    }

    .invoice-line-row{
      margin-bottom: 0;
      padding: 0px 20px;
    }
    .invoice-line-row .col {
      padding: 0;
    }

    .invoice-total-row >.col{
      padding: 10px;
    }

    .invoice-row .caption{
      color: #637381
    }

    .invoice-row > .col{
      padding-bottom: 0;
    }

    .invoice-line-row.total{
      border-top: 1px solid #C4CDD5;
      margin-top: 20px;
    }
    .invoice-line-row.total > .col{
      padding-top: 15px;
    }


    .payment-data-header .v-input--checkbox,
    .form-label .v-input--checkbox{
      margin-top: 0;
      padding-top: 0;
    }

    .payment-data-header{
      border-bottom: 1px solid #DFE3E8;
      padding: 15px 20px;
      margin-bottom: 0;
    }

    .container.payment-data-container {
      padding: 0 12px;
    }

    .card-options{
      border-right: 1px solid #C4CDD5;
      padding: 10px;
      font-size: 14px;
    }

    .payment-method-holder{
      padding: 10px;
    }

    .scroll-products {
        height: calc(100vh - 375px);
        height: calc(100svh - 375px);
    }
    .search-result {
      position: absolute;
      box-shadow: 0px 8px 12px rgba(71, 84, 116, 0.2);
      max-height: 304px;
      max-width: 511px;
      width: 100%;
      border-radius: 5px;
      background-color: #FFFFFF;
      z-index: 9;
      overflow-y: auto;
      top: 47px;
    }

    .search-result {
      overflow-y: scroll;  /* Ensures the content can still scroll vertically */
      scrollbar-width: none; /* Firefox */
    }

    .search-result::-webkit-scrollbar {
      display: none; /* Chrome, Safari, and Opera */
    }

</style>
