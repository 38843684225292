<template>
    <div v-if="isProperties" id="facility-search" :class="{'isActive': query || isActive }" class="ml-4 mb-0">
        <div class="facility-search--inner" >
            <FacilitySearchBar
                :query.sync="query"
                :activeTab.sync="activeTab"
                :selectedPropertyGroup="selectedPropertyGroup"
                :hasFocus.sync="isActive"
                :properties.sync="properties"
                :filtered="filtered"
                :selected.sync="selected"
            />
            <div v-if="$vuetify.breakpoint.xs">
                <v-bottom-sheet
                    v-model="isActive"
                    content-class="custom-bottom-sheet property-bottom-sheet"
                    min-height="80vh"
                    height="80vh"
                >   
                    <FacilitySearchResults
                        v-if="isActive"
                        :query.sync="query"
                        :activeTab.sync="activeTab"
                        :properties.sync="properties"
                        :filtered="filtered"
                        :groupMode.sync="groupMode"
                        :propertyGroups="propertyGroups"
                        :view.sync="view"
                        :selected.sync="selected"
                        :selectedGroup="selectedPropertyGroup"
                        :hasFocus.sync="isActive"
                        :property.sync="property"
                        :display = display
                        @close="close"
                    />
                </v-bottom-sheet>
            </div>
            <div v-else>
                <FacilitySearchResults
                    v-if="isActive"
                    :query.sync="query"
                    :activeTab.sync="activeTab"
                    :properties.sync="properties"
                    :filtered="filtered"
                    :groupMode.sync="groupMode"
                    :propertyGroups="propertyGroups"
                    :view.sync="view"
                    :selected.sync="selected"
                    :selectedGroup="selectedPropertyGroup"
                    :hasFocus.sync="isActive"
                    :property.sync="property"
                    :display = display
                    @close="close"
                />
            </div>
        </div>
        <v-overlay
            :value="isActive"
            opacity=".50"
            :z-index="!$vuetify.breakpoint.xs ? '-1' : '9'"
            color="#3C3B3B"
            class="hb-overlay"
            v-if="!$vuetify.breakpoint.xs"
        ></v-overlay>
    </div>
    <div v-else id="facility-search-skeleton" class="ml-4 mb-0">
        <div class="facility-search--inner" >
            <FacilitySearchBarSkeleton/>
        </div>
    </div>
</template>
<script>
import FacilitySearchBar from './FacilitySearchBar';
import FacilitySearchResults from './FacilitySearchResults';
import FacilityGroupCRUD from './FacilityGroupCRUD';
import FacilityPropertyCRUD from './FacilityPropertyCRUD';
import api from '@/assets/api.js';
import FacilitySearchBarSkeleton from './FacilitySearchBarSkeleton.vue';
import { EventBus } from '../../../EventBus';
import { mapGetters } from 'vuex';

export default {
    name: 'FacilitySearchIndex',
    data() {
        return {
            query: null,
            isActive: false,
            groupMode: 'create',
            activeTab: 'My Property Groups',
            view: 'FacilitySearchProperty',
            canSelectMultiple: false,
            selected: [],
            display: true
        }
    },
    created(){
    },
    mounted(){
      this.selected = this.filtered;
    },
    methods:{

        close(){
            this.selected = this.filtered;
            this.isActive = false;
            this.hasFocus = false;
        },
        setActive(){
            this.display = false;
            this.isActive = true;
        }
    },
    watch:{
        filtered(vals){
            this.selected = this.filtered;
        }
    },
    computed:{
        ...mapGetters({
            properties: 'propertiesStore/primaryRoleProperties',
            property: 'propertiesStore/property',
            filter: 'propertiesStore/filter',
            filtered: 'propertiesStore/filtered', 
            isPropertyLoaded: 'propertiesStore/isPropertyLoaded', 
            propertyGroups: 'propertiesStore/propertyGroups',
            selectedPropertyGroup: 'propertiesStore/selectedPropertyGroup'
        }),
        isProperties(){
            return this.properties.length >= 0 && this.isPropertyLoaded ? true : false;
        }
    },

    components: {
        FacilitySearchResults,
        FacilitySearchBar,
        FacilityGroupCRUD,
        FacilityPropertyCRUD,
        FacilitySearchBarSkeleton
    },
    directives: {


    },
    destroyed(){
    }
}
</script>
<style lang="scss">
.custom-bottom-sheet {
    background-color: white !important; /* Set background color to white */
    height: 100vh;
    height: 100svh;
}
  //TODO Adjusted max height for layout
#facility-search {
    display: flex;
    flex-direction: column;
    
    /*max-height: 1px;*/
    .hb-overlay{
        height: 100vh;
        height: 100svh;
        position: fixed;
    }
    max-height: 63px;
    max-width: 760px;
    .facility-search--inner {
        position: relative;
    }
    .full-table-head-row {
        border: none;
        background:rgba(150,150,150,0.15);
        border-bottom: 1px solid rgba(150,150,150,0.3);
        padding: 10px;
    }
    .full-table-row {
        border-top: none;
        border-bottom: none;
        padding: 5px 0px;
    }
    .full-table-body-row {
        border-radius: 10px;
        margin:0 10px;
        padding: 10px 0;
        &:hover {
            background: rgba(200,200,200,0.2)
        }
    }
    .full-table-head {
        border-top: none;
    }
    .hb-card {

        padding: 10px;
        border-radius: 15px;
    }
    .hb-button {
        border: 1px solid rgb(200, 200, 200);
        border-radius: 3px;
        padding: 5px 10px;
        &:hover {
            border: 1px solid #00b2ce;
            background: #00b2ce;
            color: #fff;
        }
        &:focus {
            outline: none;
            border: 1px solid inherit;
        }
    }
    .hb-button__primary {
        color: #fff;
        background: #6EBFBE;
        border: 1px solid #3C828C;
    }
    input[type="checkbox"] {
        -webkit-appearance: checkbox;
    }
}

#facility-search-skeleton {

width:760px;

.facility-search--input {
    transition: all 300ms ease-in-out;
    display: flex;
    flex-wrap: nowrap;
    padding: 8px 20px;
    border-radius: 40px;

    input {
        flex: 1 auto;
        padding: 1px 5px;
        &::placeholder {
            transition: all 300ms ease-in-out;
            color: rgba(255,255,255,0.5);
            background: none;
            border: none;
            outline: none;
        }
    }
    input::placeholder{
        font-weight:500;
        opacity: 1;
    }
}

.searchbar-margin-top{
    margin-top: 8.5px;
    margin-bottom: 10.5px;
}

}
@media (max-width: 600px) {
    .property-bottom-sheet {
        max-height: 93.3% !important;
    }
}
</style>
