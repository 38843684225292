<template>
    <div class="pt-n4" v-if="setLoading">
        <v-row justify="center" align="center">
            <v-col class="text-center ma-12">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
    </div>
    <div class="pt-n4" v-else>
        <collect-payment :property_id="property_id" :contact_id="contactId" ref="collectPayment"
            :paymentSource="'MOVE_OUT'"></collect-payment>

    </div>
</template>

<script>

import CollectPayment from '../../CollectPayment.vue'
import Status from '../../../includes/Messages.vue';
import api from '../../../../assets/api.js';
import moment from 'moment'
import { EventBus } from '../../../../EventBus.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { notificationMixin } from '../../../../mixins/notificationMixin.js';

export default {
    name: "Payments",
    mixins: [notificationMixin],
    components: { CollectPayment, Status, CollectPayment },
    props: ['property_id', 'payment_amount', 'contactId', 'lease_id', 'move_out_date',],
    data() {
        return {
            transformedLeases: [],
            writeOffModal: false,
            paymentProcessing: false,
            setLoading: false
        }
    },
    computed: {
        ...mapGetters({
            leases: 'paymentsStore/getLeases',
            payment_information: 'paymentsStore/getPaymentInformation',
            payment: 'paymentsStore/getPayment',
            payment_method: 'paymentsStore/getPaymentMethod',
            moveOutCalc: 'paymentsStore/getMoveOutCalc',
        }),
        formattedPaymentMethod() {
            switch (this.payment_method.type) {
                case 'card':
                    return {
                        type: this.payment_method.type,
                        name_on_card: this.payment_method.name_on_card,
                        card_number: this.payment_method.card_number,
                        exp_mo: this.payment_method.exp_mo,
                        exp_yr: this.payment_method.exp_yr,
                        cvv2: this.payment_method.cvv2,
                        save_to_account: this.payment_method.save_to_account,
                        auto_charge: this.payment_method.auto_charge,
                        address: this.payment_method.address,
                        address2: this.payment_method.address2,
                        city: this.payment_method.city,
                        state: this.payment_method.state,
                        zip: this.payment_method.zip,
                        country: this.payment_method.country,
                    }
                case 'ach':
                    return {
                        type: this.payment_method.type,
                        first: this.first,
                        last: this.last,
                        account_number: this.payment_method.account_number,
                        routing_number: this.payment_method.routing_number,
                        account_type: this.payment_method.account_type,
                        save_to_account: !!this.payment_method.save_to_account,
                        auto_charge: !!this.payment_method.auto_charge,
                        address: this.payment_method.address,
                        address2: this.payment_method.address2,
                        city: this.payment_method.city,
                        state: this.payment_method.state,
                        zip: this.payment_method.zip,
                        country: this.payment_method.country,
                    }
                case 'cash':
                    break;
                case 'check':
                    break;
                case 'directdebit':
                    return this.payment_method;
                case 'directdeposit':
                    return this.payment_method;
                case 'Eftpos': 
                    return this.payment_method;
                default:
                    return this.payment_method;
            }
            return null;
        },

    },
    mounted() {

    },
    async created() {
        this.setLoading = true;
        await this.fetchPropertyConnections({ propertyID: this.property_id });
        await this.fetchContactDetails({ contactID: this.contactId, setTransformedContact: true });
        await this.fetchContactPaymentMethods({ contactID: this.contactId, propertyID: this.property_id });
        await this.setContactInvoices();
        this.setProperty({ property: { id: this.property_id } });
        this.setPaymentInformationObject({ paymentInformation: { ...this.payment_information, ...{ amountTendered: this.moveOutCalc?.message?.amount_due || this.payment_amount, totalNewPaymentPaying: this.moveOutCalc?.message?.amount_due || this.payment_amount } } });
        this.setLoading = false;
    },
    destroyed() {
        this.closeMessageNotification();
        this.resetPayments();
    },

    methods: {
        ...mapActions({
            fetchContactPaymentMethods: 'paymentsStore/fetchContactPaymentMethods',
            fetchContactDetails: 'paymentsStore/fetchContactDetails',
            fetchPropertyConnections: 'paymentsStore/fetchPropertyConnections'
        }),
        ...mapMutations({
            setPaymentObject: 'paymentsStore/setPaymentObject',
            setPaymentInformationObject: 'paymentsStore/setPaymentInformationObject',
            setProperty: 'paymentsStore/setProperty',
            setLeases: 'paymentsStore/setLeases',
            resetPayments: 'paymentsStore/resetPayments',
            setMoveOutCalc: 'paymentsStore/setMoveOutCalc',
        }),
        async setContactInvoices() {
            let r = await api.get(this, api.CONTACTS + this.contactId + '/leases', { property_id: this.property_id, active_date: moment.utc().format('YYYY-MM-DD') });
            this.setLeases({ leases: r.leases.filter(x => x.id === this.lease_id) });
        },
        async validatePayment(){
            console.log('validatePayment');
            let status = await this.$refs.collectPayment.validateDataFields();
            if (!status) {
                return;
            }
            let payment_method = this.formattedPaymentMethod;
            return payment_method;
        },
        async processPayment() {
            this.paymentProcessing = true;

            let status = await this.$refs.collectPayment.validateDataFields();
            if (!status) {
                this.paymentProcessing = false;
                return;
            }

            if (this.payment_information.totalNewPaymentPaying > this.payment_information.amountTendered) {
                this.showMessageNotification({ description: `Amount Tendered cannot be less then ${this.payment_information.totalNewPaymentPaying}` });
                this.paymentProcessing = false;
                return;
            }

            let payment_method = this.formattedPaymentMethod;
            this.setPaymentObject({ payment: { ...this.payment, ...{ amount: this.moveOutCalc?.message?.amount_due || this.payment_amount, contact_id: this.contactId, property_id: this.property_id, amount_tendered: this.payment_information.amountTendered } } })
            let payment = this.payment;

            let remaining = this.apply_payment(this.payment_information.totalNewPaymentPaying);

            if (remaining > 0) {
                this.showMessageNotification({ description: `You are trying to apply more than is due.` });
                this.paymentProcessing = false;
                return;
            }


            let payment_info = {
                payment: payment,
                paymentMethod: payment_method,
                contact_id: this.contactId,
                property_id: this.property_id
            }

            this.$emit('paymentProcessorReady', payment_info);

            try {
                console.log("payment_info:", JSON.stringify(payment_info, null, 2));
                // let r = await api.post(this, api.SAVE_PAYMENT + 'bulk', payment_info);
                this.showMessageNotification({ type: 'success', description: `Payment Successful!` });
                this.$emit('openBalance');
            } catch (err) {
                this.showMessageNotification({ description: err });
            } finally {
                this.paymentProcessing = false;
            }
        },
        apply_payment(amount) {
            this.transformedLeases = this.leases.map(l => l);
            for (let i = 0; i < this.leases.length; i++) {
                let lease = this.transformedLeases[i];
                if (!lease.OpenInvoices.length) continue;

                // Create separate payments for each lease
                lease.amount = 0;
                lease.Invoices = [];

                //TODO: Validate payment method is available on lease.
                for (let j = 0; j < lease.OpenInvoices.length; j++) {
                    let invoice = lease.OpenInvoices[j];
                    let inv = {};

                    if (amount >= invoice.balance) {
                        inv.id = invoice.id;
                        inv.amount = invoice.balance;
                        invoice.payment = invoice.balance;
                        lease.amount = Math.round((lease.amount + invoice.balance) * 1e2) / 1e2;
                        amount = Math.round((amount - invoice.balance) * 1e2) / 1e2;
                    } else {
                        inv.id = invoice.id;
                        inv.amount = amount;
                        invoice.payment = amount;
                        lease.amount = Math.round((lease.amount + amount) * 1e2) / 1e2;
                        amount = 0;
                    }
                    lease.Invoices.push(inv);
                }
            }

            return amount;
        },

        validatePaymentMethod() {
            let errors = [];
            let response = {
                status: true,
                msg: '',
            }
            if (!this.payment.type) {
                this.errorSet(this.$options.name, 'Please select payment method.')
                return;
            }

            switch (this.payment.type.toLowerCase()) {
                case 'card':
                    if (!this.payment_method.card_number) errors.push("Card number is invalid");
                    if (!this.payment_method.exp_mo || !this.payment_method.exp_yr) errors.push("Expiration date is invalid");
                    if (!this.payment_method.cvv2) errors.push("Cvv2 is invalid");
                    if (!this.payment_method.address) errors.push("Address is invalid");
                    if (!this.payment_method.city) errors.push("City is invalid");
                    if (!this.payment_method.zip) errors.push("Zip is invalid");
                    break;
                case 'ach':
                    if (!this.payment_method.account_number) errors.push("Account number is invalid");
                    if (!this.payment_method.routing_number) errors.push("Routing number is invalid");
                    if (!this.payment_method.account_type) errors.push("ACH account type is invalid");
                    break;
                case 'check':
                    if (!this.payment.number) errors.push("Check number is invalid");
                    if (!this.payment.ref_name) errors.push("Reference name is invalid");
                    break;
                case 'cash':
                    if (!this.payment.ref_name) errors.push("Reference name is invalid");
                    break;
                    break;
                default:
                // response = {
                //     status: false,
                //     msg: 'Payment method not supported',
                // }
            }

            if (errors.length) {
                response.status = false;
                response.msg = errors.join(", ");

            }
            return response;

        },
        writeOff() {
            var invoice_ids = [];

            for (var i = 0; i < this.leases.length; i++) {
                let lease = this.leases[i]
                let open_invoices = lease.OpenInvoices.filter(oi => oi.due <= this.move_out_date);
                for (var j = 0; j < open_invoices.length; j++) {
                    var obj = {};
                    obj['invoice_id'] = open_invoices[j].id;
                    invoice_ids.push(obj);
                }
            }

            var data = {
                amount: this.moveOutCalc?.message?.amount_due || this.payment_amount,
                notes: '',
                invoices: invoice_ids,
                contact_id: this.$props.contactId
            }

            return api.post(this, api.LEASES + this.lease_id + '/invoices/write-off', data).then(r => {
                this.$emit('openBalance');
                this.writeOffModal = false
            }).catch(err => {
                this.showMessageNotification({ description: err });
            });
        }
    },
}
</script>

<style scoped>
.btn-payment {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border: 1px solid #C4CDD5;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #101318;
}
</style>