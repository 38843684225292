<template>
    <div id="facility-search--results" :class="{'isActive': hasFocus && display }" class="facility-search--results hb-card v-card">
        <div class="facility-search--results-header mt-1">
            <v-col cols="12" sm="6" class="ma-0 facility-search-header-box pa-0">
                <hb-tabs v-model="view" background-color="#FFFFFF" class="ml-3">
                    <v-tab
                        v-for="(m, i) in menuOptions"
                        :key="'menu' + i"
                        :ripple="false"
                    >
                        {{ m.label || toLowerCase}}
                    </v-tab>
                </hb-tabs>
            </v-col>
            <v-col cols="12" sm="6" class="ma-0 pa-0 pr-1 mt-n1">
                <v-row v-if="view == 0 && !$vuetify.breakpoint.xs" class="ma-0 pa-0 mt-2 mr-3 subtitle-1" align="center" justify="end">
                    Select Multiple Properties
                    <HbCheckbox v-model="canSelectMultiple" class="ml-2 mr-n3" />
                </v-row>
                <v-row v-if="$vuetify.breakpoint.xs" class="ma-0 pa-0 mt-2 mr-3 subtitle-1" align="center" :justify="$vuetify.breakpoint.xs ? 'start' : 'end'">
                    <div v-show="view==0" class="align-center" :class="view==0 ? 'd-flex' :''"><HbCheckbox v-model="canSelectMultiple" class="ml-2 mr-n3 pr-2 py-2" />
                    Select Multiple Properties</div>
                </v-row>
            </v-col>
        </div>
        <div class="facility-search--results-inner">
<!--            <FacilitySearchCompany-->
<!--                v-if="view === 'FacilitySearchCompany'"-->
<!--                :canSelectMultiple.sync="canSelectMultiple"-->
<!--                :query="query"-->
<!--                :results="results"-->
<!--                @input="selectedOptions"-->
<!--            />-->
            <FacilitySearchGroups v-if="view == 1"
                :propertyGroups="groupsFilteredByQuery"
                :properties="properties"
                :selectedGroup="selectedGroup"
                @handleGroupSelection="updatePropertiesSelection"
            />
            <FacilitySearchProperty v-if="view == 0"
                :canSelectMultiple.sync="canSelectMultiple"
                :selected="selected"
                :allProperties.sync="allProperties"
                :query="query"
                :properties="propertiesFilteredByQuery"
                :property="property"
            />
        </div>
        <div class="facility-group--create-footer pt-4 pb-2 pr-4 d-flex align-center" v-if="view == 0 && canSelectMultiple" >
            <v-spacer />
            <hb-link class="mr-3" @click="cancel">Cancel</hb-link>
            <hb-btn class="mr-3" color="secondary" @click="toggle()" v-show="allProperties">Show Selected Properties</hb-btn>
            <hb-btn class="mr-3" color="secondary" @click="toggle()" v-show="!allProperties">Show All Properties</hb-btn>
            <hb-btn color="primary" @click="updateLeaseWithMultipleProperties">Go</hb-btn>
        </div>
    </div>
</template>
<script>
import tabs from '../../assets/Tabs';
import api from '../../../assets/api.js';
import FacilitySearchCompany from './FacilitySearchCompany'
import FacilitySearchGroups from './FacilitySearchGroups'
import FacilitySearchProperty from './FacilitySearchProperty'
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '../../../EventBus.js';
export default {
    name: 'FacilitySearchResults',
    data() {
        return {
            canSelectMultiple: false,
            allProperties: true,
            lastSelected: [],
            view: 0
        }
    },
    props: [
        'query',
        'activeTab',
        'properties',
        'propertyGroups',
        'selected',
        'selectedGroup',
        'hasFocus',
        'property',
        'display'
    ],
    components: {
        tabs,
        FacilitySearchCompany,
        FacilitySearchGroups,
        FacilitySearchProperty
    },
    mounted() {
        // If we have more than one filtered, then show those that are filtered by default
        if(this.selected.length > 1 &&  this.selected.length !== this.properties.length){
          this.canSelectMultiple = true;
          this.allProperties = false;
        }
    },
    created(){
        document.addEventListener('click', this.outsideClick);
        EventBus.$off('reset_gps_bulk_property');
        EventBus.$on('reset_gps_bulk_property', this.resetGPSbulk);
    },
    computed: {
        propertiesFilteredByQuery() {
            let properties = this.allProperties ? this.properties : this.selected;

            return this.query ? properties.filter((property) => {
                return property.description && property.description.toLowerCase().includes(this.query.toLowerCase()) ||
                    property.number && property.number.toLowerCase().includes(this.query.toLowerCase()) ||
                    property.name && property.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    property.Address && property.Address.address && property.Address.address.toLowerCase().includes(this.query.toLowerCase()) ||
                    property.Address && property.Address.city && property.Address.city.toLowerCase().includes(this.query.toLowerCase()) ||
                    property.Address && property.Address.state && property.Address.state.toLowerCase().includes(this.query.toLowerCase()) ||
                    property.Address && property.Address.country && property.Address.country.toLowerCase().includes(this.query.toLowerCase()) ||
                    property.Address && property.Address.zip && property.Address.zip.toLowerCase().includes(this.query.toLowerCase())
            }) : properties;

        },
        groupsFilteredByQuery() {
            return this.query ? this.propertyGroups.filter((group) => group.name && group.name.toLowerCase().includes(this.query.toLowerCase())) : this.propertyGroups;
        },
        results() {
            return this.properties;
        },
        canGroup() {
            return this.$props.selected ? this.$props.selected.length > 0: false;
        },
        ...mapGetters({
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            configuration_unit: 'onBoardingStore/configuration_unit',
            associatedProperty:'onBoardingStore/property',
            hasPermission: 'authenticationStore/rolePermission',
            loggedInUser: "authenticationStore/getUserData",
            charmSubscribedAndFilteredProperties:'charmSubscriptionStore/charmSubscribedAndFilteredProperties',
            charmSubscribedNotSelectedProperties:'charmSubscriptionStore/charmSubscribedNotSelectedProperties'
        }),
        menuOptions() {
            let menu = [
                {
                    key: 'FacilitySearchProperty',
                    label: 'My Properties'
                }
            ]

            if(this.propertyGroups.length) {
                menu.push({
                    key: 'FacilitySearchGroups',
                    label: 'My Property Groups'
                })
            }

            return menu;
        }
    },
    watch: {
        /*
        view: function(tab){
            this.updateActiveTab(tab);
        },
        */
        hasFocus(newVal, oldVal) {
            if(newVal){
                this.lastSelected = this.selected;
            }
        }
    },
    methods:{
        ...mapActions({
            getPropertiesCharm:'charmSubscriptionStore/getProperties',
            setFilteredProperties:'charmSubscriptionStore/setFilteredProperties',
            exchangeToken: 'propertiesStore/exchangeToken',
            setSelectedProperties:'navigationStore/setSelectedProperties',
            resetSelectedPropertyGroup: 'propertiesStore/resetSelectedPropertyGroup',
            setFilteredPropertyGroups: 'propertiesStore/setFilteredPropertyGroups',
            defaultProperty: 'propertiesStore/setDefaultProperty'
        }),
        toggle(){
            this.allProperties = !this.allProperties;
        },
        selectedOptions(e){
            this.$emit('update:property', e)

        },
        cancel(){
            this.$emit('close');
        },
        async updateLeaseWithMultipleProperties(payload = {}) {
            let {resetPropertyGroup = true} = payload;

            this.$nextTick(() => { this.$emit('update:query', '') });
            this.$nextTick(() => {this.$emit('close');})

            const data = {
                property_ids:[]
            };
            let properties = [];
            properties = this.selected.length ? this.selected : this.properties;
            properties.forEach(property => {
                data.property_ids.push(property.id);
            });

            let is_prop_exist_move_in = !!(this.associatedProperty?.id && this.selected.find(prop => prop.id === this.associatedProperty.id));
            let is_move_in = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_lead_intake');
            let is_prop_exist = !!(this.activeDrawerComponent && this.selected.find(prop => prop.id === this.activeDrawerComponent?.property_id));
            let is_take_a_payment = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showMakePayment');
            let is_move_out = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showMoveOut');  
            let is_buy_services = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showSellMerchandise');
            let is_transfer = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showTransfer');
            let is_omni_search = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_omni_Search');
            let is_task_center = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_task_center');
            let is_review_rent_change = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'review_rent_change');
            let is_lead_follow_up = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_lead_follow_up');
            
            if(!is_prop_exist && is_take_a_payment) EventBus.$emit('reset_take_a_payment_hb_model');
            if(!is_prop_exist && is_move_out) EventBus.$emit('reset_move_out_hb_model');
            if(!is_prop_exist && is_buy_services) EventBus.$emit('reset_buy_services_hb_model');
            if(!is_prop_exist && is_transfer) EventBus.$emit('reset_transfer_hb_model');
            if(is_omni_search || is_task_center || is_review_rent_change) EventBus.$emit('hide_drawer');
            if(!is_prop_exist && is_lead_follow_up) this.$emit('close');
            this.setFilteredProperties( this.selected.map(obj => obj.id))
            if (resetPropertyGroup) this.resetSelectedPropertyGroup();
            if (resetPropertyGroup) this.resetSelectedPropertyGroup();

            this.getPropertiesCharm({primaryRoleProperties:this.selected.map(obj => obj.id),refresh:true,userId:this.loggedInUser.id})
            this.setFilteredProperties( this.selected.map(obj => obj.id))
            if(!is_prop_exist_move_in && is_move_in) {
                this.setSelectedProperties(this.selected);
                EventBus.$emit('reset_lease_hb_model',data);
            }else{
                if(data.property_ids.length > 1) {
                    this.defaultProperty(null);
                }
                await this.exchangeToken(data);
                this.setFilteredPropertyGroups();
                await this.viewGroup(data);
            }
        },
        async viewGroup(params){
            this.$parent.$emit('update:query', '');

            //BCT: CHR-515 Charm
            if(this.hasPermission('mini_charm')) {
                let payload = {
                    agent_id: this.loggedInUser.id,
                    // availability: 'available',
                    logged_in: true,
                    // property_ids: params.property_ids,
                    // availabile_property_ids: params.property_ids,
                    // unavailabile_property_ids: this.properties?.filter(p => !params.property_ids.includes(p.id))?.map(m => m.id),
                    facilities: {
                        available: this.charmSubscribedAndFilteredProperties.filter(p => p.mini_charm_enabled)?.map(m => m.gds_id),
                        unavailable: this.charmSubscribedNotSelectedProperties?.filter(p => p.mini_charm_enabled)?.map(m => m.gds_id)
                    },
                    from: 'multiple-properties-selection'
                }
                if(payload.facilities?.available.length || payload.facilities?.unavailable.length){
                    this.$store.dispatch('charmSubscriptionStore/setAvailableAgent', payload);
                }
                
            }

            this.$store.dispatch('reportStore/clearReportStructure');
            this.$store.dispatch('filterStore/clearSizes');

            const {name,meta} = this.$router.currentRoute;
            const parent_route = this.$route.matched[0].name;

            if(name === 'Dashboard' || parent_route === 'Dashboard') {
                this.$store.dispatch('dashboardsStore/getDashboards');
            }

            if(name === 'RentManagement' || name === 'RateManagement'){
                this.$store.dispatch('revManStore/setProperty',null);
            }

            if(parent_route === 'TenantLeaseDetails') EventBus.$emit('refetch_contact_leases')
            if(name === 'DynamicReports') this.$router.push('/reports-library').catch(err => {});

            if ("re_fetch" in meta && meta.re_fetch){
                EventBus.$emit('refetch_data')
            }
        },
        updateActiveTab(tab) {
            let view = this.menuOptions.filter(option => option.key === tab);
            console.log(view);
            // this.$emit('update:activeTab', view[0].label);
        },
        createGroup() {
            let grouped = this.selected;
            let properties = grouped.length;
            let unit_count = grouped.reduce(function(total, record) {
                return total + record.unit_count;
            }, 0);
            let lease_count = grouped.reduce(function(total, record) {
                return total + record.lease_count;
            }, 0);

            let group = {
                id: Date.now(),
                properties: grouped,
                name: null,
                property_count: properties,
                unit_count: unit_count,
                lease_count: lease_count
            };

            if (properties) {
                this.$emit('update:group', group);
            }
        },
        outsideClick(e){
            if (e.target.className != "v-badge__badge error" && e.target.id != "svg1" && e.target.id != "svg2" && e.target.id != "search-box" && e.target.id != "magnify-icon" && e.target.id != "faclility-search" && e.target.id != "facility-search" && ! this.$el.contains(e.target) ){
                this.$emit('close');
            }
        },
        async updatePropertiesSelection(properties) {
            this.selected = properties;
            await this.updateLeaseWithMultipleProperties({resetPropertyGroup: false});
        },
        async resetGPSbulk(payload){
            if(payload){
                await this.exchangeToken(payload);
                await this.viewGroup(payload);
            }
        }
    },
    beforeDestroy(){
        document.removeEventListener('click', this.outsideClick);
    },
    watch: {
        canSelectMultiple(val){
            if(!val) this.allProperties = true;
        }
    }
}
</script>
<style lang="scss">
#facility-search {

    &.isActive {
        opacity: 1;
        pointer-events: initial;
        top: 15px;
    }
    #facility-search--results {
        .hb-button--text {
            &:focus {
                outline: none;
                border: none;
            }
        }
        cursor:pointer;
        pointer-events: none;
        opacity: 0;
        transition: opacity 250ms ease-in-out, top 250ms ease-in-out;
        position: relative;
        top: 0px;
        background: #fff;
        height: 1px;
        overflow: hidden;
        &.isActive {
            opacity: 1;
            pointer-events: initial;
            height: auto;
            overflow: initial;
        }
        .facility-search--results-header {
            display: flex;
            padding: 0 10px;
        }
        .facility-search--results-header,
        .facility-search--results-inner {
            margin: 0 -10px;
        }
        .tab-list {
            border: none;
            display: initial;
        }
        .tab-list-option {
            background: none;
            text-transform: none;
            border-bottom: none;
            padding: 10px 20px;
            &.active {
                border-bottom: 2px solid #00b2ce;
                color: #101318;
                font-weight: bold;
            }
        }
        .tabs-holder {
            flex: 1 auto;
        }
        .facility-group--create-footer {
            display: flex;
            border-top: 1px solid #dedede;
            padding: 5px;
            margin: 0 -10px;
        }
        .hb-button__primary {
            background: #47C0BF;
        }
    }

    .v-tab {
        text-transform: none !important;
    }

    .main-facility-selector-checkbox.v-input--checkbox {
        .v-icon:not(.primary--text) {
            color: #101318 !important;
        }
    }
    .top-1 {
        top: 1px
    }
}
@media (max-width: 600px) {
    .v-application .facility-search-header-box {
        border-bottom: 1px solid #E0E3E8;
        padding-bottom: 1px !important;
    }
}
</style>
